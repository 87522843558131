import crudMixin from 'services/crudMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  mixins: [crudMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    value: { type: Boolean },
    id: {},
  },
  data () {
    return {
      model: null,
    };
  },
  computed: {
    valueProxy: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isCreate () {
      return this.id == null;
    },
  },
  watch: {
    value (show) {
      if (show) {
        this.reload();
      }
    },
    id () {
      if (this.value) {
        this.reload();
      }
    },
  },
  methods: {
    reload () {
      // placeholder to prevent null reference
    },
    doClose () {
      this.valueProxy = false;
    },
    async promptUpdate (patch = this.model, id = this.id) {
      if (!patch && !await this.$refs.form.validate()) {
        return;
      }
      try {
        const x = await this.doUpdate(patch, id);
        this.showToast(this.$t('p.updated'));
        this.$emit('update', this.model);
        return x;
      } catch (err) {
        this.handleError(err);
      }
    },
    async promptDelete (id = this.id) {
      try {
        const x = await this.doDelete(...arguments);
        this.showToast(this.$t('p.deleted'));
        this.$emit('delete', this.model);
        return x;
      } catch (err) {
        this.handleError(err);
      }
    },
  },
};
