<template>
  <VbDemo>
    <VbCard dashed no-padding>
      <Signup style="width: 500px"/>
    </VbCard>
  </VbDemo>
</template>

<script>
import Signup from 'src/components/auth/signup/Signup.vue'

export default {
  components: {
    Signup,
  },
}
</script>
