var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('va-modal',{attrs:{"title":_vm.title,"hideDefaultActions":"","size":"large"},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('template',{slot:"actions"},[_c('va-button',{attrs:{"flat":"","color":"gray"},on:{"click":function($event){return _vm.doClose()}}},[_vm._v(_vm._s(_vm.$t('a.cancel')))]),(!_vm.confirmed)?_c('va-button',{on:{"click":function($event){return _vm.doConfirm()}}},[_vm._v(_vm._s(_vm.$t('a.next')))]):_c('va-button',{on:{"click":function($event){_vm.confirmed = false}}},[_vm._v(_vm._s(_vm.$t('a.back')))])],1),_c('ValidationObserver',{ref:"form",staticClass:"va-layout gutter--md",attrs:{"tag":"div"}},[(!_vm.confirmed)?[_vm._t("default",[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs12"},[_c('ValidationProvider',{attrs:{"vid":"amount","name":_vm.$t('f.coinsAmount'),"mode":"lazy","rules":"required|min_value:0.01|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.coinsAmount'),"error":valid === false,"error-messages":errors},model:{value:(_vm.model.amount),callback:function ($$v) {_vm.$set(_vm.model, "amount", $$v)},expression:"model.amount"}})]}}],null,false,963656302)})],1)])])]:[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs12"},[_c('p',{staticClass:"display-5"},[_vm._v(_vm._s(_vm.$t('f.totalAmount')))]),_c('p',[_vm._v(_vm._s(_vm._f("currency")(_vm.model.amount,'HKD ')))])])]),_c('hr'),_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs12"},[_c('PaymentForm',{attrs:{"model":_vm.model,"createPayment":_vm.createPayment},on:{"success":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
_vm.emit.apply(void 0, [ 'success' ].concat( argsArray )); _vm.doClose()},"cancel":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.emit.apply(void 0, [ 'cancel' ].concat( argsArray ))},"error":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.emit.apply(void 0, [ 'error' ].concat( argsArray ))}}})],1)])]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }