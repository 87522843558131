// Polyfills
import 'es6-promise/auto';
import 'babel-polyfill';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import moment from 'moment';
import 'moment-duration-format';
import { get } from 'lodash';
import Cookies from 'js-cookie';
import Vue from 'vue';
import VeeValidate, {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import App from './App';
import { ColorThemePlugin } from '../services/ColorThemePlugin';
import store from '../store/index';
import router from '../router/index';
import VuesticPlugin from '../vuestic-theme/vuestic-plugin';
import { Table, TableColumn, Button, Pagination } from 'element-ui';
import '../i18n/index';
import YmapPlugin from 'vue-yandex-maps';
import VueClipboard from 'vue-clipboard2';
import VueHead from 'vue-head';
import '../api';
import * as filters from '../services/filters';
import mixins from './mixins';

Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Button.name, Button);
Vue.component(Pagination.name, Pagination);
Vue.use(VuesticPlugin);
Vue.use(YmapPlugin);
Vue.use(VueClipboard);
Vue.use(VueHead);
Vue.use(mixins);

Vue.use(ColorThemePlugin,
  {
    // Add or change theme colors here
    themes: {
      // primary: '#f06595',
      // blurple: '#7289DA',
    },
  });

// NOTE: workaround for VeeValidate + vuetable-2
Vue.use(VeeValidate, { fieldsBagName: 'formFields' });
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

for (let [k, v] of Object.entries(filters)) {
  Vue.filter(k, v);
}

router.beforeEach((to, from, next) => {
  store.commit('setLoading', true);
  next();
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.auth) {
    if (!store.getters.user) {
      return next({ name: 'login' });
      // return store.dispatch('redirectLogin');
    }
    if (Array.isArray(to.meta.auth)) {
      if (!to.meta.auth.includes(store.getters.role)) {
        return next('/');
      }
    }
  }
  next();
});

router.afterEach((to, from) => {
  store.commit('setLoading', false);
});

(async () => {
  try {
    await store.dispatch('checkLoginStatus');
    const locales = Cookies.get('locale');
    if (!locales) {
      const language = get(store.getters.user, 'preference.language');
      if (language) {
        Vue.i18n.set(language);
      }
    }
  } catch (e) {
    router.replace({ name: 'login' });
    // store.dispatch('redirectLogin');
    return;
  }

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    watch: {
      '$store.state.i18n.locale': {
        immediate: true,
        handler (v) {
          moment.locale([v, this.$store.state.i18n.fallback].map(
            x => x === 'zh' ? 'zh-hk' : x));
        },
      },
      '$store.state.i18n.fallback' (v) {
        moment.locale([this.$store.state.i18n.locale, v].map(
          x => x === 'zh' ? 'zh-hk' : x));
      },
    },
  });

  setInterval(async () => {
    try {
      await store.dispatch('checkLoginStatus');
    } catch (e) {
      if (e.status === 0) {
        return; // no response, ignore
      }
      alert(Vue.i18n.translate('msg.session_timeout'));
      router.push({ name: 'login' });
      // store.dispatch('redirectLogin');
    }
  }, 1000 * 60 * 10); // check every 10 min
})();
