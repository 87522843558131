<template>
  <va-modal
    v-model="valueProxy"
    :title="title"
    noOutsideDismiss
    hideDefaultActions
    size="large"
  >
    <template slot="actions">
      <va-button flat color="gray" @click="doClose()">{{$t('a.close')}}</va-button>
      <va-button color="primary" @click="promptCreate()" v-if="!id">{{$t('a.create')}}</va-button>
      <va-button color="primary" @click="promptUpdate()" v-if="id">{{$t('a.update')}}</va-button>
      <!--      <va-button color="danger" @click="doDelete()" v-if="id">{{$t('a.delete')}}</va-button>-->
    </template>
    <ValidationObserver tag="div" ref="form">
      <div v-if="model">
        <div v-if="!isCreate" class="va-row">
          <objectid :value="model.sid" tag="p"/>
        </div>
        <div class="va-row">
          <div class="flex md12">
            <va-input
              v-model="model.username"
              :label="$t('f.username')"
            />
            <va-input
              v-model="model.email"
              :label="$t('f.email')"
            />
            <ValidationProvider
              v-slot="{errors, valid}" rules="regex:^\+[0-9*# ]+"
              mode="eager"
            >
              <va-input
                v-model="model.phone"
                :label="$t('f.phone')"
                placeholder="e.g. +85212345678"
                :error="!valid"
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </va-modal>
</template>

<script>
  import Objectid from 'components/objectid';
  import editDialogMixin from 'src/services/editDialogMixin';
  import { create } from 'src/services/schema/User';

  export default {
    name: 'UserEditDialog',
    components: { Objectid },
    mixins: [editDialogMixin],
    data () {
      return {
        crudURL: 'user/{id}',
      };
    },
    computed: {
      title () {
        return this.$t('schema.m_user');
      },
    },
    methods: {
      async reload () {
        this.model = this.id ? (await this.$http.get(this.crudURL,
          { params: { id: this.id } })).data : create();
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      async promptCreate () {
        if (!await this.$refs.form.validate()) {
          return;
        }
        try {
          const model = (await this.$http.post(this.crudURL, this.model,
            { params: { id: null } })).data;
          this.showToast(this.$t('p.created'));
          this.$emit('create', model);
          return model;
        } catch (err) {
          this.handleError(err);
        }
      },
    },
  };
</script>
