<template>
  <div>
    <div class="va-row flex">
      <va-card :title="$t('schema.m_profile')">
        <template slot="actions">
          <div class="d-flex justify-content-center">
            <va-checkbox
              v-if="model"
              :label="$t('f.enabled')"
              :value="model.enabled"
              @input="toggleEnabled"
              class="d-inline-flex"
            />
            <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
            <va-button color="primary" @click="doEdit()">{{$t('a.edit')}}</va-button>
            <va-button color="danger" @click="promptDelete()">{{$t('a.delete')}}</va-button>
          </div>
        </template>
        <div class="va-layout fluid gutter--md">
          <div class="va-row" v-if="model">
            <div class="flex md4">
              <p class="display-5">{{$t('f.avatar')}}</p>
              <p>
                <a v-if="model.avatar" :href="attnDownloadHref(model.avatar)" target="_blank">
                  <ProfileAvatar :profile="model" size="80px"/>
                </a>
                <ProfileAvatar v-else :profile="model" size="80px"/>
              </p>
              <p class="display-5">{{$t('f.role')}}</p>
              <p>{{$t('roles.' + model.role)}}</p>
              <p class="display-5">{{$t('f.displayName')}}</p>
              <p>{{model.name || $t('d.empty')}}</p>
            </div>
            <div class="flex md4">
              <p class="display-5">{{$t('f.status')}}</p>
              <p>{{model.status | status}}</p>
            </div>
          </div>
        </div>
      </va-card>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="id"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </div>
</template>

<script>
  import ProfileAvatar from 'components/membership/ProfileAvatar';
  import ApiError from 'services/ApiError';
  import EditDialog from './ProfileEditDialog';
  import crudMixin from 'src/services/crudMixin';

  export default {
    name: 'Profile',
    mixins: [crudMixin],
    components: {
      ProfileAvatar,
      EditDialog,
    },
    props: {
      id: {
        type: String,
      },
      crudURL: {
        type: String,
        default: 'profile/{id}',
      },
    },
    data () {
      return {
        model: null,
        editDialog: false,
      };
    },
    computed: {},
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.model = (await this.$http.get(this.crudURL,
          { params: { id: this.id } })).data;
      },
      doEdit () {
        this.editDialog = true;
      },
      async doDelete (id) {
        let cascade;
        while (true) {
          try {
            await this.$http.delete(this.crudURL,
              { params: { id: id || this.id, cascade } });
            return true;
          } catch (err) {
            const ae = ApiError.wrap(err);
            if (ae.code === 'dangerous_operation') {
              if (confirm(this.$t('p.promptForceDeleteProfile'))) {
                cascade = 1;
                continue;
              }
            }
            throw ae;
          }
        }
      },
      async promptDelete () {
        if (await crudMixin.methods.promptDelete.call(this)) {
          this.$router.replace({ name: 'm_profiles' });
        }
      },
      async toggleEnabled (v) {
        if (!confirm(this.$t(v ? 'p.promptEnable' : 'p.promptDisable'))) {
          return;
        }
        if (await this.promptUpdate({ enabled: v })) {
          this.model.enabled = v;
        }
      },
    },
  };
</script>
