import store from 'src/store';

export default function (Vue) {
  Vue.prototype.$allow = function (roles) {
    if (roles == null) {
      return true;
    }
    // requires login only
    if (roles === true) {
      return this.$store.getters.user;
    }
    // requires role
    roles = [].concat(roles);
    return roles.includes(store.getters.role);
  };
  Vue.prototype.$redirectLogin = function (redirect = window.location.href) {
    const { state, getters } = store;
    let q = new URLSearchParams();
    q.append('role', state.auth.allowedRoles.join(','));
    q.append('redirect', redirect);
    window.location = getters.api.host + getters.api.sso + '?' + q.toString();
  };
  Vue.prototype.$redirectLogout = function (redirect = window.location.origin) {
    const { state, getters } = store;
    let q = new URLSearchParams();
    q.append('role', state.auth.allowedRoles.join(','));
    q.append('redirect', redirect);
    window.location = getters.api.host + getters.api.base + '/sso/logout?' + q.toString();
  };
  Vue.prototype.$redirectSwitchProfile = function (redirect = window.location.href) {
    const { state, getters } = store;
    let q = new URLSearchParams();
    q.append('switch', '1');
    q.append('role', state.auth.allowedRoles.join(','));
    q.append('redirect', redirect);
    window.location = getters.api.host + getters.api.sso + '?' + q.toString();
  };
}
