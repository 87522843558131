<template>
  <div class="va-file-upload"
       :class="{'va-file-upload--dropzone': dropzone}">
    <va-file-upload-container
      :type="type"
      :fileTypes="fileTypes"
      :dropzone="dropzone"
      @upload="uploadFile"
    >
      <va-file-upload-list
        v-if="files.length"
        :type="type"
        :files="files"
        @remove="removeFile"
        @remove-single="removeSingleFile"
      />
    </va-file-upload-container>
    <va-modal v-model="mediumModal" no-buttons>
      <div slot="title">{{ $t('fileUpload.modalTitle') }}</div>
      <div>
        {{ $t('fileUpload.modalText') }}
      </div>
    </va-modal>
  </div>
</template>

<script>
import VaFileUploadList from './VaFileUploadList'
import VaFileUploadContainer from './VaFileUploadContainer'

export default {
  name: 'va-file-upload',
  components: {
    VaFileUploadList,
    VaFileUploadContainer,
  },
  props: {
    type: {
      type: String,
      default: 'list',
      validator (value) {
        return ['list', 'gallery', 'single'].indexOf(value) !== -1
      },
    },
    fileTypes: {
      type: String,
      default: '',
    },
    dropzone: {
      type: Boolean,
      default: false,
    },
    value: {
      default: () => [],
    },
  },
  data () {
    return {
      mediumModal: false,
    }
  },
  methods: {
    uploadFile (e) {
      let files = e.target.files || e.dataTransfer.files

      // type validation
      if (this.fileTypes) {
        files = this.validateFileTypes(files)
      }
      if (files.length) {
        this.$emit('upload', [...files]);
      }
    },
    removeFile (index) {
      this.$emit('remove', [this.files[index]]);
    },
    removeSingleFile () {
      this.$emit('remove', [...this.files]);
    },
    validateFileTypes (files) {
      return [...files].filter(file => {
        const fileName = file.name
        const extn = fileName.substring(fileName.lastIndexOf('.') + 1)
          .toLowerCase()
        if (this.fileTypes.indexOf(extn) === -1) {
          this.mediumModal = true;
        }
        return this.fileTypes.indexOf(extn) !== -1
      })
    },
  },
  computed: {
    files () {
      return [...this.value].filter(x => x);
    },
  },
}
</script>

<style lang='scss'>
.va-file-upload {
  &--dropzone {
    background-color: $lighter-green;
    padding: 0 2rem;
    overflow: hidden;
  }
}

// Maybe we should create new component for text button
.btn-text {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;

  & + & {
    margin-left: 1.5rem;
  }

  &--primary {
    color: $vue-green;

    &:hover {
      opacity: 0.6;
    }
  }

  &--secondary {
    color: $white;
  }
}

@media (max-width: 576px) {
  .va-file-upload {
    &--dropzone {
      padding: 0 1rem;
    }
  }
}
</style>
