<template>
  <div>
    <div class="va-row flex">
      <va-card :title="$t('schema.ll_sharedlink')">
        <template slot="actions">
          <div class="d-flex justify-content-center">
            <va-checkbox
              v-if="model"
              :label="$t('f.enabled')"
              :value="model.enabled"
              @input="toggleEnabled"
              class="d-inline-flex"
            />
            <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
            <va-button color="primary" @click="doEdit()">{{$t('a.edit')}}</va-button>
            <va-button color="danger" @click="promptDelete()">{{$t('a.delete')}}</va-button>
          </div>
        </template>
        <div class="va-layout fluid gutter--md" v-if="model">
          <div class="va-row">
            <div class="flex md4">
              <p class="display-5">{{$t('schema.ll_sharedlink')}}</p>
              <a href="#"
                 :title="$t('p.clickTo$action', {action: $t('a.copy')})"
                 @click.prevent="copyText(model.href)"
              >{{model.href}}</a>
            </div>
            <div class="flex md4">
              <p class="display-5">{{$t('schema.ll_sourcelink')}}</p>
              <router-link :to="{name: 'll_sourcelink', params: {id: model.sourcelink._id}}">
                {{model.sourcelink._id | objectId}}
              </router-link>
              <p class="display-5">{{$t('f.targetLink')}}</p>
              <a :href="model.sourcelink.href" target="_blank">{{model.sourcelink.href}}</a>
            </div>
            <div class="flex md4">
              <p class="display-5">{{$t('f.remarks')}}</p>
              <p style="white-space: pre-line">{{model.remarks || '-'}}</p>
            </div>
          </div>
          <div class="va-row">
            <div class="flex md4">
              <p class="text-category">{{$t('f.categories.seo')}}</p>
              <hr/>
              <p class="display-5">{{$t('f.seoTitle')}}</p>
              <p>{{model.title || model.sourcelink.title}}</p>
              <p class="display-5">{{$t('f.seoDescription')}}</p>
              <p>{{model.description || model.sourcelink.description}}</p>
              <p class="display-5">{{$t('f.seoImage')}}</p>
              <p>
                <img v-if="model.image || model.sourcelink.image"
                     style="max-width: 256px;"
                     :src="attnThumbHref(model.image || model.sourcelink.image, 256)"/>
                <span v-else>-</span>
              </p>
            </div>
            <div class="flex md4" v-if="stats">
              <p class="text-category">{{$t('f.categories.statistics')}}</p>
              <hr/>
              <p class="display-5">{{$t('f.coinsEarned')}}</p>
              <p>{{model.coinsEarned | currency('', 2)}}</p>
              <p class="display-5">{{$t('f.clicks')}}</p>
              <p>{{stats.status.success || 0}}</p>
              <p class="display-5">{{$t('f.failedClicks')}}
                <va-icon
                  icon="ion ion-ios-information-circle-outline"
                  :title="$t('d.failedClicksDesc')"/>
              </p>
              <p>{{stats.status.quota_exceed || 0}}</p>
              <p class="display-5">{{$t('f.distinctSessions')}}</p>
              <p>{{stats.distinct}}</p>
              <p class="display-5">{{$t('f.robots')}}</p>
              <p>{{stats.status.peek || 0}}</p>
            </div>
          </div>
        </div>
      </va-card>
    </div>
    <div class="va-row">
      <div class="flex md6">
        <va-card :title="$t('f.referrer')">
          <va-data-table
            ref="table1"
            :apiMode="false"
            :tableData="byReferer"
            :tableFields="byRefererFields"
            :sortFunctions="byRefererSort"
            :dataModeFilterableFields="['_id']"
          />
        </va-card>
      </div>
    </div>
    <div class="va-row flex">
      <Clicks ref="table" :appendParams="clicksFilter"/>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="id"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </div>
</template>

<script>
  import EditDialog from './SharedLinkEditDialog';
  import Clicks from './Clicks';
  import crudMixin from 'src/services/crudMixin';

  export default {
    name: 'SharedLink',
    mixins: [crudMixin],
    components: {
      Clicks,
      EditDialog,
    },
    props: {
      id: { type: String },
    },
    data () {
      return {
        crudURL: 'll/sharedlink/{id}',
        model: null,
        stats: null,
        byReferer: { data: [] },
        editDialog: false,
      };
    },
    computed: {
      clicksFilter () {
        return { filter: { sharedlink: this.id } };
      },
      byRefererFields () {
        return [
          {
            name: '_id',
            title: this.$t('f.referrer'),
            sortField: '_id',
            formatter: x => x || 'Others',
          },
          {
            name: 'count',
            title: this.$t('f.count'),
            sortField: 'count',
          },
        ];
      },
      byRefererSort () {
        return {
          _id: this.comparator,
          count: this.comparator,
        };
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      comparator (a, b) {
        return a > b ? 1 : a < b ? -1 : 0;
      },
      async reload () {
        this.model = (await this.$http.get(this.crudURL,
          { params: { id: this.id, populate: 'sourcelink' } })).data;
        this.stats = (await this.$http.get(this.crudURL + '/stats',
          { params: { id: this.id } })).data;
        this.byReferer.data =
          (await this.$http.get('ll/click/stats/byReferer',
            {
              params: {
                filter: JSON.stringify({ sharedlink: this.id, status: 'success' }),
              },
            })).data;
        this.$refs.table.reload();
        this.$refs.table1.reload();
      },
      doEdit () {
        this.editDialog = true;
      },
      async promptDelete () {
        if (await crudMixin.methods.promptDelete.call(this)) {
          this.$router.replace({ name: 'll_sharedlinks' });
        }
      },
      async toggleEnabled (v) {
        if (!confirm(this.$t(v ? 'p.promptEnable' : 'p.promptDisable'))) {
          return;
        }
        if (await this.promptUpdate({ enabled: v })) {
          this.model.enabled = v;
        }
      },
      async copyText (t) {
        await this.$copyText(t);
        this.showToast(this.$t('p.copied'));
      },
    },
  };
</script>
