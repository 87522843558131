import store from '../store/index';
import AppLayout from '../components/admin/AppLayout';

export const routes = [
  {
    name: 'account',
    path: '/account',
    component: AppLayout,
    children: [
      {
        name: 'myself',
        path: 'myself',
        component: () => import('../components/membership/Myself.vue'),
        meta: {
          auth: true,
        },
      },
      {
        name: 'payments',
        path: 'payments',
        component: () => import('../components/membership/MyPaymentAndSubscriptions.vue'),
        meta: {
          auth: true,
        },
      },
      {
        name: 'transactions',
        path: 'transactions',
        component: () => import('../components/ecommerce/Transactions.vue'),
        props: r => ({
          appendParams: {
            filter: { owner: store.getters.profileId },
          },
        }),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
