<template>
  <va-modal
    v-model="valueProxy"
    :title="title"
    noOutsideDismiss
    hideDefaultActions
    size="large"
  >
    <template slot="actions">
      <va-button flat color="gray" @click="doClose()">{{$t('a.close')}}</va-button>
      <va-button color="primary" @click="promptUpdate()" v-if="!isCreate">
        {{$t('a.update')}}
      </va-button>
    </template>
    <ValidationObserver tag="div" ref="form">
      <div v-if="model" class="va-layout fluid gutter--md">
        <div v-if="!isCreate" class="va-row">
          <objectid :value="model._id" tag="p"/>
        </div>
        <div class="va-row">
          <div class="flex xs12">
            <p class="display-5">{{$t('f.created_by')}}</p>
            <p>{{get(model, 'created_by.name')}}</p>
            <p class="display-5">{{$t('f.coinsAmount')}}</p>
            <p>{{model.amount}}</p>
            <p class="display-5">{{$t('f.status')}}</p>
            <p>{{model.status | status}}</p>
          </div>
          <div class="flex xs12">
            <va-input
              v-model="model.remarks"
              :label="$t('f.adminRemarks')"
              type="textarea"
            />
          </div>
        </div>
        <div class="va-row" v-if="model.status === '0$pending'">
          <div class="flex xs12">
            <p class="display-5">{{$t('f.actions')}}</p>
            <p>
              <va-button color="success" @click="promptApprove()">
                {{$t('a.approve')}}
              </va-button>
              <va-button color="danger" @click="promptReject()">
                {{$t('a.reject')}}
              </va-button>
            </p>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </va-modal>
</template>

<script>
  import Objectid from 'components/objectid';
  import editDialogMixin from 'src/services/editDialogMixin';
  import { create } from 'src/services/schema/SourceLink';
  import { get } from 'lodash';
  import { status } from 'src/services/filters';

  export default {
    name: 'CashoutApplicationEditDialog',
    components: { Objectid },
    mixins: [editDialogMixin],
    data () {
      return {
        crudURL: 'll/cashoutApplication/{id}',
      };
    },
    computed: {
      title () {
        return this.$t('schema.ll_cashoutApplication');
      },
      get: () => get,
    },
    methods: {
      async reload () {
        this.model = this.id ? (await this.$http.get(this.crudURL,
          { params: { id: this.id, populate: 'created_by' } })).data : create();
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      statusFilter: status,
      async promptApprove () {
        if (!confirm(this.$t('p.promptApproveCashoutApplication'))) {
          return;
        }
        try {
          this.model = (await this.$http.patch(this.crudURL + '/approve', this.model,
            { params: { id: this.id } })).data;
          this.showToast(this.$t('p.updated'));
        } catch (err) {
          this.handleError(err);
        }
      },
      async promptReject () {
        if (!confirm(this.$t('p.promptRejectCashoutApplication'))) {
          return;
        }
        try {
          this.model = (await this.$http.patch(this.crudURL + '/reject', this.model,
            { params: { id: this.id } })).data;
          this.showToast(this.$t('p.updated'));
        } catch (err) {
          this.handleError(err);
        }
      },
    },
  };
</script>
