import { merge } from 'lodash';

export function create (init) {
  return merge({
    status: '0$requested',
    href: '',
    title: '',
    description: '',
    image: undefined,
    coins: null,
    costPerClick: null,
    tags: [],
    remarks: undefined,
  }, init);
}
