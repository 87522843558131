<template>
  <div>
    <div class="va-layout fluid gutter--md wrap">
      <div class="va-row">
        <div
          class="flex xs12 md6 xl4"
          v-for="model in data.docs"
          :key="model._id"
        >
          <va-card>
            <template slot="header">
              <h5 class="mt-0 mb-0">
                <va-icon class="mr-3" :icon="gatewayIcon(model.gateway)" color="dark"/>
                {{ gateways[model.gateway] && gateways[model.gateway].name }}
              </h5>
              <div class="va-card__header-actions">
                <va-button
                  icon="fa fa-pencil"
                  @click="doEdit(model._id)"
                  :title="$t('a.edit')"
                />
                <va-button
                  icon="fa fa-trash" color="danger"
                  @click="promptDelete(model._id)"
                  :title="$t('a.delete')"
                />
              </div>
            </template>
            <div class="va-row">
              <template v-if="model.gateway === 'paypal.me'">
                <div class="flex xs12 md6">
                  <p>
                    <span class="display-5">{{$t('f.paypalmeId')}}: </span>
                    <a
                      :href="`https://www.paypal.me/${parsePaypalmeId(model.paypalmeId)}`"
                      target="_blank">
                      paypal.me/{{parsePaypalmeId(model.paypalmeId)}}
                    </a>
                  </p>
                </div>
              </template>
            </div>
            <va-collapse customHeader customBody class="mt-1">
              <template v-slot:header="{show}">
                <a href="javascript:">{{$t(show ? 'a.showLess' : 'a.showMore')}}</a>
              </template>
              <template slot="body">
                <div class="va-row">
                  <div class="flex xs12">
                    <p>
                      <span class="display-5">{{$t('f.created_at')}}: </span>
                      {{model.created_at | datePrettify}}
                    </p>
                    <p class="display-5">{{$t('f.remarks')}}</p>
                    <p style="white-space: pre-line">{{model.description}}</p>
                  </div>
                </div>
              </template>
            </va-collapse>
          </va-card>
        </div>
        <div class="flex xs12 md6 xl4">
          <va-card>
            <div class="d-flex align--center justify--center" style="min-height: 10rem;">
              <va-button large icon="fa fa-plus" @click="doEdit(null)">{{$t('a.add')}}</va-button>
            </div>
          </va-card>
        </div>
      </div>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="editId"
      @create="editDialog = false; reload()"
      @update="editDialog = false; reload()"
      @delete="editDialog = false; reload()"
    />
  </div>
</template>

<script>
  import crudMixin from 'services/crudMixin';
  import { parsePaypalmeId } from 'services/utils';
  import EditDialog from './PaymentProfileEditDialog';
  import { keyBy } from 'lodash';

  export default {
    name: 'MyPaymentMethods',
    mixins: [crudMixin],
    components: {
      EditDialog,
    },
    data () {
      return {
        crudURL: 'user/me/ec/payment/profile',
        editDialog: false,
        editId: undefined,
        gateways: {},
        data: {
          total: 0,
          offset: 0,
          limit: 10,
          docs: [],
        },
      };
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.gateways = keyBy((await this.$http.get('ec/payment/gateway')).data, 'id');
        this.data = (await this.$http.get(this.crudURL, {
          params: {
            limit: 99,
            sort: JSON.stringify({ updated_at: -1 }),
          },
        })).data;
      },
      gatewayIcon (key) {
        return {
          paypal: 'fa fa-cc-paypal',
          stripe: 'fa fa-cc-stripe',
          'paypal.me': 'fa fa-paypal',
        }[key] || 'fa fa-credir-card';
      },
      doEdit (id) {
        this.editId = id;
        this.editDialog = true;
      },
      parsePaypalmeId,
    },
  };
</script>
