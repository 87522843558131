<template>
  <div class="content va-layout fluid gutter--md">
    <div class="header va-row wrap">
      <div class="title display-4 grow">{{title}}</div>
      <div class="actions">
        <va-checkbox
          v-if="model"
          :label="$t('f.enabled')"
          :value="model.enabled"
          @input="toggleEnabled"
          class="d-inline-flex"
        />
        <va-button small color="gray" flat @click="doClose()">{{$t('a.close')}}</va-button>
        <va-button small color="primary" @click="promptUpdate()">{{$t('a.update')}}</va-button>
      </div>
    </div>
    <hr/>
    <ValidationObserver tag="div" class="va-row" ref="form">
      <template v-if="model">
        <div class="flex md3">
          <va-input
            v-model="model.title"
            :label="$t('f.seoTitle')"
          />
        </div>
        <div class="flex md3">
          <va-input
            v-model="model.description"
            :label="$t('f.seoDescription')"
          />
        </div>
        <div class="flex md3">
          <p class="display-6">{{$t('f.seoImage')}}</p>
          <img v-if="model.image"
               :src="attnThumbHref(model.image, 128)"/>
          <span v-else>-</span>
        </div>
        <div class="flex md3">
          <va-input
            v-model="model.remarks"
            :label="$t('f.remarks')"
            type="textarea"
          />
        </div>
      </template>
    </ValidationObserver>
  </div>
</template>

<script>
  import detailRowMixin from 'src/services/detailRowMixin';

  export default {
    name: 'SharedLinkDetailRow',
    mixins: [detailRowMixin],
    data () {
      return {
        crudURL: 'll/sharedlink/{id}',
      };
    },
    computed: {
      title () {
        return this.$t('d.quickView');
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.model = (await this.$http.get(this.crudURL,
          { params: { id: this.id, populate: ['image'] } })).data;
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      async toggleEnabled (v) {
        if (!confirm(this.$t(v ? 'p.promptEnable' : 'p.promptDisable'))) {
          return;
        }
        if (await this.promptUpdate({ enabled: v })) {
          this.model.enabled = v;
        }
      },
      async uploadImage (files) {
        if (!files[0]) return;
        await this.attnDelete(this.model.image);
        const attn = await this.attnUpload(files[0]);
        this.$set(this.model, 'image', attn);
        this.doUpdate({ image: attn._id }, this.id);
      },
      async removeImage () {
        await this.attnDelete(this.model.image);
        this.$set(this.model, 'image', null);
        this.doUpdate({ image: null }, this.id);
      },
    },
  };
</script>
