export const toggleWithoutAnimation = state => state.app.sidebar.withoutAnimation;
export const config = state => state.app.config;
export const palette = state => state.app.config.palette;
export const isLoading = state => state.app.isLoading;

export const api = state => state.app.config.api;
export const user = state => state.auth.user;
export const profile = state => state.auth.profile;
export const profiles = state => state.auth.profiles;
export const userId = state => state.auth.user && state.auth.user._id;
export const profileId = state => state.auth.profile && state.auth.profile._id;
export const role = state => state.auth.profile && state.auth.profile.role;
export const isAdmin = (state, getters) => getters.role === 'admin';
