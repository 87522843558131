<template>
  <va-modal
    v-model="valueProxy"
    :title="title"
    hideDefaultActions
    size="large"
  >
    <template slot="actions">
      <va-button flat color="gray" @click="doClose()">{{$t('a.cancel')}}</va-button>
      <va-button v-if="!confirmed" @click="doConfirm()">{{$t('a.next')}}</va-button>
      <va-button v-else @click="confirmed = false">{{$t('a.back')}}</va-button>
    </template>
    <ValidationObserver ref="form" tag="div" class="va-layout gutter--md">
      <template v-if="!confirmed">
        <slot>
          <div class="va-row">
            <div class="flex xs12">
              <ValidationProvider
                vid="amount"
                v-slot="{errors, valid}" :name="$t('f.coinsAmount')" mode="lazy"
                rules="required|min_value:0.01|decimal:2"
              >
                <va-input
                  v-model="model.amount"
                  :label="$t('f.coinsAmount')"
                  :error="valid === false"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </div>
          </div>
        </slot>
      </template>
      <template v-else>
        <div class="va-row">
          <div class="flex xs12">
            <p class="display-5">{{$t('f.totalAmount')}}</p>
            <p>{{model.amount | currency('HKD ')}}</p>
          </div>
        </div>
        <hr/>
        <div class="va-row">
          <div class="flex xs12">
            <PaymentForm
              :model="model"
              :createPayment="createPayment"
              @success="emit('success', ...arguments); doClose()"
              @cancel="emit('cancel', ...arguments)"
              @error="emit('error', ...arguments)"
            />
          </div>
        </div>
      </template>
    </ValidationObserver>
  </va-modal>
</template>

<script>
  import PaymentForm from 'components/membership/PaymentForm';

  export default {
    name: 'TopUpPaymentDialog',
    components: { PaymentForm },
    props: {
      value: {},
      title: {
        type: String,
        default () {
          return this.$t('a.topUp');
        },
      },
      createPaymentApi: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        confirmed: false,
        model: {
          gateway: null,
          amount: '',
        },
      };
    },
    computed: {
      valueProxy: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        },
      },
    },
    watch: {
      value: {
        immediate: true,
        handler (v) {
          if (v) {
            this.reload();
          } else {
            this.$emit('close');
          }
        },
      },
    },
    methods: {
      doClose () {
        this.valueProxy = false;
      },
      async doConfirm () {
        if (!await this.$refs.form.validate()) {
          return;
        }
        this.confirmed = true;
      },
      async reload () {
        this.confirmed = false;
        this.model = {
          gateway: null,
          amount: 0,
        };
      },
      async createPayment (gw, model) {
        model.gateway = gw;
        return (await this.$http.post(this.createPaymentApi, model, {
          params: { commit: true },
        })).data;
      },
      emit () {
        return this.$emit(...arguments);
      },
    },
  };
</script>
