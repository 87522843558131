<template>
  <div class="linklink-icon">
    <img
      :src="require('assets/logo.png')"
      alt="Logo"
    />
    <span>LinkLink</span>
  </div>
</template>

<script>
  export default {
    name: 'linklink-icon',
  };
</script>

<style lang="scss">
  .linklink-icon {
    display: inline-flex;
    align-items: center;
    line-height: 100%;

    img {
      height: 1em;
    }

    span {
      font-size: 0.75em;
      font-weight: bold;
      color: #4f5561;
      margin-left: 0.2em;
      text-transform: uppercase;
    }
  }
</style>
