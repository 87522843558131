<template>
  <ValidationObserver tag="div" ref="form">
    <ValidationProvider
      vid="gateway"
      v-slot="{errors, valid}" :name="$t('f.gateway')" mode="lazy"
      rules="required"
    >
      <va-input-wrapper
        :error="valid === false"
        :error-messages="errors"
      >
        <va-simple-select
          v-model="gateway"
          :label="$t('d.selectPaymentGateway')"
          :options="Object.keys(gateways)"
          :clearable="false"
          :formatter="id => gateways[id] && gateways[id].name"
        />
      </va-input-wrapper>
    </ValidationProvider>
    <PayPalSmartPayment
      v-if="gateway === 'paypal'"
      :createOrder="paypalCreateOrder"
      :onApprove="paypalApprove"
      :onCancel="paypalCancel"
      :onError="paypalError"
    />
  </ValidationObserver>
</template>

<script>
  import PayPalSmartPayment from 'components/membership/PayPalSmartPayment';
  import { get, keyBy } from 'lodash';

  export default {
    name: 'PaymentForm',
    components: { PayPalSmartPayment },
    props: {
      model: {
        required: true,
      },
      createPayment: {
        type: Function,
      },
    },
    data () {
      return {
        gateways: {},
        gateway: null,
        payment: null,
      };
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        let list = (await this.$http.get('ec/payment/gateway')).data
          .filter(x => x.metadata.createPayment);
        this.gateways = keyBy(list, 'id');
        this.payment = null;
      },
      validate () {
        return this.$refs.form.validate(...arguments);
      },
      async paypalCreateOrder () {
        if (!await this.$refs.form.validate()) {
          throw new Error('Invalid Form');
        }
        const pay = this.payment = await this.createPayment(this.gateway, this.model);
        if (!pay) {
          throw new Error('cancelled');
        }
        const href = get((get(pay, 'gateway_result.links') || [])
          .find(x => x.rel === 'approval_url'), 'href');
        const m = /EC-\w+/.exec(href);
        if (m && m[0]) {
          return m[0];
        }
        throw new Error('cannot find token');
      },
      async paypalApprove (data, actions) {
        if (this.payment) {
          await this.$http.post('ec/payment/paypal/{id}/approve', null, {
            params: {
              id: this.payment._id,
              token: data.orderID,
              paymentID: data.paymentID,
              payerID: data.payerID,
            },
          });
        }
        this.$emit('success', this.payment);
        this.payment = null;
      },
      async paypalCancel (data, actions) {
        if (this.payment) {
          await this.$http.post('ec/payment/paypal/{id}/cancel', null, {
            params: {
              id: this.payment._id,
              paymentID: data.paymentID,
              payerID: data.payerID,
            },
          });
        }
        this.$emit('cancel', ...arguments);
        this.payment = null;
      },
      paypalError () {
        this.$emit('error', ...arguments);
      },
    },
  };
</script>
