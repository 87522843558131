import Vue from 'vue';

const state = {
  allowedRoles: ['admin', 'business'],
  mustLogin: false,
  user: null,
  profile: null,
  profiles: [],
};

const mutations = {
  setUser (state, user) {
    state.user = user;
  },
  setProfile (state, profile) {
    state.profile = profile;
  },
  setProfiles (state, profiles) {
    state.profiles = Array.isArray(profiles) ? profiles : [];
  },
};

const actions = {
  async checkLoginStatus ({ commit, state }) {
    const { data } = await Vue.http.get('sso/checkLoginStatus');
    if (!data.user && state.mustLogin) {
      throw new Error('mustLogin');
    }
    if (data.profile && !state.allowedRoles.includes(data.profile.role)) {
      throw new Error('allowedRoles');
    }
    commit('setUser', data.user);
    commit('setProfile', data.profile);
    if (data.user) {
      commit('setProfiles', (await Vue.http.get('user/me/profiles')).data.docs);
    } else {
      commit('setProfiles', []);
    }
    return data;
  },
};

export default {
  state,
  mutations,
  actions,
};
