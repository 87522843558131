<template>
  <div>
    <div class="va-row flex">
      <va-card :title="$t('f.categories.currentProfile')">
        <template slot="actions">
          <div class="d-flex justify-content-center">
            <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
            <va-button color="primary" @click="doEdit()">{{$t('a.edit')}}</va-button>
          </div>
        </template>
        <div class="va-layout fluid gutter--md">
          <div class="va-row" v-if="model">
            <div class="flex xs12 md6 xl4">
              <p class="display-5">{{$t('f.role')}}</p>
              <p>{{$t('roles.' + model.role)}}</p>
              <p class="display-5">{{$t('f.displayName')}}</p>
              <p>{{model.name || $t('d.empty')}}</p>
            </div>
            <div class="flex xs12 md6 xl4">
              <p class="display-5">{{$t('f.avatar')}}</p>
              <p>
                <a v-if="model.avatar" :href="attnDownloadHref(model.avatar)" target="_blank">
                  <ProfileAvatar :profile="model" size="80px"/>
                </a>
                <ProfileAvatar v-else :profile="model" size="80px"/>
              </p>
            </div>
          </div>
        </div>
      </va-card>
    </div>
    <EditDialog
      v-model="editDialog"
      id=""
      @create="reload"
      @update="reload"
      crudURL="user/me/profile/{id}"
    />
  </div>
</template>

<script>
  import ProfileAvatar from 'components/membership/ProfileAvatar';
  import Profiles from 'components/membership/Profiles';
  import { mapGetters } from 'vuex';
  import EditDialog from './ProfileEditDialog';
  import crudMixin from 'src/services/crudMixin';

  export default {
    name: 'MyProfile',
    mixins: [crudMixin],
    components: {
      ProfileAvatar,
      Profiles,
      EditDialog,
    },
    props: {},
    data () {
      return {
        model: null,
        editDialog: false,
      };
    },
    watch: {
      profile () {
        this.reload();
      },
    },
    computed: {
      ...mapGetters([
        'profile',
      ]),
      crudURL: () => 'user/me/profile',
      id: () => undefined,
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.model = (await this.$http.get(this.crudURL)).data;
      },
      doEdit () {
        this.editDialog = true;
      },
    },
  };
</script>
