var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('va-modal',{attrs:{"title":_vm.title,"noOutsideDismiss":"","hideDefaultActions":"","size":"large"},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('template',{slot:"actions"},[_c('va-button',{attrs:{"flat":"","color":"gray"},on:{"click":function($event){return _vm.doClose()}}},[_vm._v(_vm._s(_vm.$t('a.close')))]),(_vm.isCreate)?_c('va-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.promptCreate()}}},[_vm._v(_vm._s(_vm.$t('a.create')))]):_vm._e(),(!_vm.isCreate)?_c('va-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.promptUpdate()}}},[_vm._v(_vm._s(_vm.$t('a.update')))]):_vm._e(),_c('va-button',{attrs:{"color":"primary"},on:{"click":_vm.doCreateSharedLink}},[_vm._v(" "+_vm._s(_vm.$t('p.create$obj', { obj: _vm.$t('schema.ll_sharedlink') }))+" ")])],1),_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[(_vm.model)?_c('div',{staticClass:"va-layout fluid gutter--md"},[(!_vm.isCreate)?_c('div',{staticClass:"va-row"},[_c('objectid',{attrs:{"value":_vm.model._id,"tag":"p"}})],1):_vm._e(),_c('p',{staticClass:"text-category"},[_vm._v(_vm._s(_vm.$t('f.categories.basic')))]),_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex md12"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('f.targetLink'),"mode":"eager","rules":{required: true, url: {require_protocol: true}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.targetLink'),"placeholder":"http://","error":valid === false,"error-messages":errors},model:{value:(_vm.model.href),callback:function ($$v) {_vm.$set(_vm.model, "href", $$v)},expression:"model.href"}})]}}],null,false,146494185)})],1),_c('div',{staticClass:"flex md6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('f.coins'),"mode":"eager","rules":"decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.coins'),"error":valid === false,"error-messages":errors,"placeholder":"0"},model:{value:(_vm.model.coins),callback:function ($$v) {_vm.$set(_vm.model, "coins", $$v)},expression:"model.coins"}})]}}],null,false,3268691123)})],1),_c('div',{staticClass:"flex md6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('f.costPerClick'),"mode":"eager","rules":"min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.costPerClick'),"error":valid === false,"error-messages":errors},model:{value:(_vm.model.costPerClick),callback:function ($$v) {_vm.$set(_vm.model, "costPerClick", $$v)},expression:"model.costPerClick"}})]}}],null,false,3117211030)})],1),_c('div',{staticClass:"flex md6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('f.status'),"mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input-wrapper',{attrs:{"error":valid === false,"error-messages":errors}},[_c('va-simple-select',{attrs:{"label":_vm.$t('f.status'),"clearable":false,"options":['0$requested', '1$accepted', '99$rejected'],"formatter":_vm.statusFilter},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}})],1)]}}],null,false,427374473)})],1)]),_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex md6"},[_c('va-input',{attrs:{"label":_vm.$t('f.remarks'),"type":"textarea"},model:{value:(_vm.model.remarks),callback:function ($$v) {_vm.$set(_vm.model, "remarks", $$v)},expression:"model.remarks"}})],1)]),_c('p',{staticClass:"text-category"},[_vm._v(_vm._s(_vm.$t('f.categories.seo')))]),_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex md6"},[_c('va-input',{attrs:{"label":_vm.$t('f.seoTitle')},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}}),_c('va-input',{attrs:{"type":"textarea","label":_vm.$t('f.seoDescription')},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}}),_c('va-multi-select',{attrs:{"label":_vm.$t('f.tags'),"options":['food', 'beauty', 'gym', 'video', 'travel']},model:{value:(_vm.model.tags),callback:function ($$v) {_vm.$set(_vm.model, "tags", $$v)},expression:"model.tags"}})],1),(!_vm.isCreate)?_c('div',{staticClass:"flex md6"},[_c('p',{staticClass:"display-5"},[_vm._v(_vm._s(_vm.$t('f.seoImage')))]),_c('p',[_c('va-file-upload',{attrs:{"type":"single","value":_vm.attnConvert([_vm.model.image])},on:{"upload":_vm.uploadImage,"remove":_vm.removeImage}})],1),(_vm.model.image)?_c('img',{staticStyle:{"max-width":"256px"},attrs:{"src":_vm.attnThumbHref(_vm.model.image, 256)}}):_vm._e()]):_vm._e()])]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }