<template>
  <div class="demo-container">
    <div class="demo-container__item">
      <va-icon-vuestic/>
    </div>
  </div>
</template>

<script>
import VaIconVuestic from './VaIconVuestic.vue'

export default {
  components: {
    VaIconVuestic,
  },
}
</script>
