import moment from 'moment';
import Vue from 'vue';
import {
  currency as _currency,
  amount as _amount,
  objectId,
  phone as _phone,
} from 'src/services/filters';
import { get, escapeRegExp } from 'lodash';

export let dateFormat = 'YYYY/MM/DD';
export let datetimeFormat = 'YYYY/MM/DD HH:mm';
export let timeFormat = 'HH:mm';

export function id () {
  return {
    search: (str, field) => ({ [field.sortField || field.name]: str }),
    form: 'text',
  };
}

export function _id () {
  const $t = Vue.i18n.translate;
  return {
    name: 'id',
    title: $t('f.id'),
    sortField: '_id',
    readonly: true,
    formatter: objectId,
    ...id(),
  };
}

export function sid () {
  const $t = Vue.i18n.translate;
  return {
    name: 'sid',
    title: $t('f.id'),
    sortField: 'sid',
    readonly: true,
    search: true,
  };
}

export function boolean (True = '\u2713', False = '\u2717', Null = False) {
  return {
    formatter (v) {
      return v == null ? Null : v ? True : False;
    },
    form: 'checkbox',
  };
}

export function enabled () {
  const $t = Vue.i18n.translate;
  return {
    name: 'enabled',
    title: $t('f.enabled'),
    sortField: 'enabled',
    ...boolean(),
  };
}

export function datetime (pretty = false) {
  return {
    formatter (v) {
      if (v == null) return '';
      let m = moment(v);
      let exact = m.format(datetimeFormat);
      let relative = m.fromNow();
      return pretty
        ? `<span title="${exact}">${relative}</span>`
        : `<span title="${relative}">${exact}</span>`;
    },
    form: 'datetime',
  };
}

export function datetimerange (startProp, endProp, pretty = false) {
  return {
    formatter (v) {
      if (v == null) return '';
      let s = moment(get(v, startProp));
      let e = moment(get(v, endProp)).add(-1, 'ms');
      let sD = s.format(dateFormat);
      let eD = e.format(dateFormat);
      let sT = s.format(timeFormat);
      let eT = e.format(timeFormat);
      let sDT = s.format(datetimeFormat);
      let eDT = e.format(datetimeFormat);
      let exact = `${sDT} - ${eDT}`;
      let short = sD;
      if (sD !== eD) {
        short = exact;
      } else {
        short = `${sD} ${sT} - ${eT}`;
      }
      return pretty
        ? `<span title="${exact}">${short}</span>`
        : `<span title="${short}">${exact}</span>`;
    },
  };
}

export function daterange (startProp, endProp, pretty = false) {
  return {
    formatter (v) {
      if (v == null) return '';
      let s = moment(get(v, startProp));
      let e = moment(get(v, endProp)).add(-1, 'ms');
      let sD = s.format(dateFormat);
      let eD = e.format(dateFormat);
      if (pretty && sD === eD) {
        return sD;
      }
      return `${sD} - ${eD}`;
    },
  };
}

export function date (pretty = false) {
  return {
    formatter (v) {
      if (v == null) return '';
      let m = moment(v);
      let exact = m.format(dateFormat);
      let relative = m.fromNow();
      return pretty
        ? `<span title="${exact}">${relative}</span>`
        : `<span title="${relative}">${exact}</span>`;
    },
    form: 'date',
  };
}

export function time () {
  return {
    formatter (v) {
      if (v == null) return '';
      let m = moment(v);
      return m.format(timeFormat);
    },
    form: 'time',
  };
}

export function status () {
  const $t = Vue.i18n.translate;
  return {
    name: 'status',
    title: $t('f.status'),
    sortField: 'status',
    search: true,
    formatter (v) {
      if (v == null) return '';
      v = v.split('$').reverse()[0];
      return Vue.i18n.translate('statuses.' + v, v.charAt(0).toUpperCase() + v.slice(1));
    },
  };
}

export function i18n (sep = '.', queryCallback = null) {
  if (typeof queryCallback !== 'function') {
    queryCallback = q => ({
      $regex: escapeRegExp(q),
      $options: 'i',
    });
  }
  return {
    formatter (v) {
      return Vue.i18n.translateObj(v, '');
    },
    search (q, field) {
      return {
        $or: Vue.i18n.locales().map(id => ({
          [(field.sortField || field.name) + sep + id]: queryCallback(q),
        })),
      };
    },
  };
}

export function array (sep = ', ', iter = null) {
  return {
    formatter (v) {
      if (v == null) return '';
      return typeof iter === 'function' ? [].concat(v).map(iter).join(sep) : [].concat(v).join(sep);
    },
  };
}

export function tags (sep = ', ', iter) {
  return {
    search: true,
    ...array(sep, iter),
  };
}

export function link (fn) {
  return {
    formatter (v) {
      if (v == null) return '';
      return fn.call(this, v);
    },
  };
}

export function currency (dollar = '$ ', decimals = 2, accountingFormat = false) {
  return {
    formatter (v) {
      if (v == null) return '';
      return '<pre style="margin: 0">' + _currency(v, dollar, decimals, accountingFormat) + '</pre>';
    },
    dataClass: 'text-right',
    titleClass: 'text-right',
  };
}

export function amount (dollar = '$ ', decimals = 2, accountingFormat = false) {
  return {
    formatter (v) {
      if (v == null) return '';
      return '<pre style="margin: 0">' + _amount(v, dollar, decimals, accountingFormat) + '</pre>';
    },
    dataClass: 'text-right',
    titleClass: 'text-right',
  };
}

export function searchNumber () {
  return {
    search (q, field) {
      return {
        [field.sortField || field.name]: {
          $regex: String(q).replace(/[^0-9]/g, ''),
          $options: 'i',
        },
      };
    },
  };
}

export function phone () {
  return {
    formatter: _phone,
  };
}

export function rating (max = 5, def = null, fillChar = '★', emptyChar = '') {
  return {
    formatter (v) {
      if (v == null) v = def;
      if (v == null) return '';
      v = Math.round(Number(v));
      let empty = max - v;
      let css = ['text-danger', 'text-warning', 'text-success'];
      css = css[Math.min(css.length - 1, Math.trunc(v / max * css.length))];
      let str = fillChar.repeat(v) + (empty > 0 ? emptyChar.repeat(empty) : '');
      if (css) {
        return `<span class="${css}">${str}</span>`;
      } else {
        return str;
      }
    },
  };
}
