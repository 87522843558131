var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",staticClass:"login",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.doSubmit($event)}}},[(!_vm.useSms)?[(!_vm.ambiguous)?_c('ValidationProvider',{attrs:{"vid":"usernameOrEmailOrPhone","name":_vm.$t('auth.usernameOrEmailOrPhone'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('va-input',{attrs:{"label":_vm.$t('auth.usernameOrEmailOrPhone'),"error":valid === false,"error-messages":errors,"autofocus":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,false,3003854056)}):_vm._e(),(_vm.ambiguous)?_c('va-simple-select',{attrs:{"label":_vm.$t('f.field'),"clearable":false,"options":['username', 'email', 'phone'],"formatter":function (x) { return _vm.$t('f.' + x); }},model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}}):_vm._e(),(_vm.ambiguous)?_c('ValidationProvider',{attrs:{"vid":"field","name":_vm.$t('f.field'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('va-input',{staticClass:"mb-2",attrs:{"label":_vm.$t('f.' + _vm.field),"error":valid === false,"error-messages":errors},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,false,1713848268)}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"password","name":_vm.$t('auth.password'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('va-input',{attrs:{"name":"password","type":"password","label":_vm.$t('auth.password'),"error":valid === false,"error-messages":errors},model:{value:(_vm.pw),callback:function ($$v) {_vm.pw=$$v},expression:"pw"}})]}}],null,false,583036170)})]:[_c('ValidationProvider',{attrs:{"vid":"field","name":_vm.$t('f.phone'),"mode":"lazy","rules":"required|regex:^\\s*\\+?[0-9\\-\\s()]+$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('va-input',{attrs:{"label":_vm.$t('f.phone'),"error":valid === false,"error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}},[_c('template',{slot:"appendText"},[(_vm.canSendSms)?_c('va-button',{attrs:{"type":"button","disabled":!_vm.isPhoneValid,"small":""},on:{"click":function($event){return _vm.promptRequestSMSCode()}}},[_vm._v(" "+_vm._s(_vm.$t('a.getVerifyCode'))+" ")]):_c('va-button',{attrs:{"disabled":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('d.resendSms', {time: _vm.sms.waitUntil.diff(_vm.now, 'seconds')}))+" ")])],1)],2)]}}])}),_c('ValidationProvider',{attrs:{"vid":"verifyCode","name":_vm.$t('f.verifyCode'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('va-input',{attrs:{"label":_vm.$t('f.verifyCode'),"error":valid === false,"error-messages":errors},model:{value:(_vm.sms.code),callback:function ($$v) {_vm.$set(_vm.sms, "code", $$v)},expression:"sms.code"}},[(_vm.sms.nonce)?_c('template',{slot:"prependText"},[_c('span',{staticStyle:{"padding":".25em 0 .1em .5em"}},[_vm._v("("+_vm._s(_vm.sms.nonce)+")")])]):_vm._e()],2)]}}])})],_c('div',{staticClass:"d-flex align--center justify--space-between flex-wrap mb-4"},[_c('va-checkbox',{staticClass:"mr-2",attrs:{"label":_vm.$t('auth.keep_logged_in')},model:{value:(_vm.keepLoggedIn),callback:function ($$v) {_vm.keepLoggedIn=$$v},expression:"keepLoggedIn"}}),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"d-flex flex-grow-1 justify--space-between my-1"},[(_vm.useSms)?_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){_vm.useSms = false}}},[_vm._v(_vm._s(_vm.$t('auth.loginWithPassword')))]):_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){_vm.useSms = true}}},[_vm._v(_vm._s(_vm.$t('auth.loginWithSMS')))]),_c('a',{staticClass:"ml-2",attrs:{"href":_vm.forgotPasswordHref,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('auth.recover_password')))])])],1),_c('div',{staticClass:"flex-center"},[_c('va-button',{staticClass:"my-0",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('auth.login')))])],1),(_vm.err)?_c('div',{staticClass:"flex-center mt-3"},[_c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.err))])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }