var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"va-row flex mb-3"},[_c('va-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('va-tab',{attrs:{"value":"accountAndSecurity"}},[_vm._v(" "+_vm._s(_vm.$t('menu.accountAndSecurity'))+" ")]),(_vm.get(_vm.model, 'profiles.length') > 1)?_c('va-tab',{attrs:{"value":"profiles"}},[_vm._v(" "+_vm._s(_vm.$t('menu.otherProfiles'))+" ")]):_vm._e()],1)],1),(_vm.tab === 'accountAndSecurity')?[_c('div',{staticClass:"va-row flex"},[_c('va-card',{attrs:{"title":_vm.$t('user.profile')}},[_c('template',{slot:"actions"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('va-button',{attrs:{"color":"gray","flat":""},on:{"click":_vm.reload}},[_vm._v(_vm._s(_vm.$t('a.refresh')))])],1)]),_c('div',{staticClass:"va-layout fluid gutter--md"},[(_vm.model)?_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs12 md6 xl4"},[_c('p',{staticClass:"text-category"},[_vm._v(_vm._s(_vm.$t('f.categories.personalInfo')))]),_c('hr'),_c('p',{staticClass:"display-5"},[_vm._v(_vm._s(_vm.$t('f.username')))]),_c('p',[_vm._v(" "+_vm._s(_vm.model.username || _vm.$t('d.empty'))+" "),_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){_vm.editModel = _vm.model.username; _vm.editUsername = true}}},[_vm._v(" ("+_vm._s(_vm.$t('a.change'))+") ")])]),_c('ValidationObserver',{ref:"editUsername",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('va-modal',{attrs:{"title":_vm.$t('a.edit'),"onOk":function (d) {
                    validate().then(function () {
                      _vm.doUpdate({ username: _vm.editModel }, _vm.id)
                        .then(function (ok) {
                          if (!ok) { return; }
                          d.close();
                          _vm.model.username = _vm.editModel;
                        });
                    });
                    return false;
                  }},model:{value:(_vm.editUsername),callback:function ($$v) {_vm.editUsername=$$v},expression:"editUsername"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('f.username'),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.username'),"error":valid === false,"error-messages":errors,"messages":[_vm.$t('d.optional')]},model:{value:(_vm.editModel),callback:function ($$v) {_vm.editModel=$$v},expression:"editModel"}})]}}],null,true)})],1)]}}],null,false,2530034432)}),_c('p',{staticClass:"display-5"},[_vm._v(_vm._s(_vm.$t('f.email')))]),_c('p',[_vm._v(" "+_vm._s(_vm.model.email || _vm.$t('d.empty'))+" "),_c('a',{attrs:{"href":_vm.changeEmailHref,"title":_vm.$t('auth.change_email'),"target":"_blank"}},[_vm._v(" ("+_vm._s(_vm.$t('a.change'))+") ")])]),_c('p',{staticClass:"display-5"},[_vm._v(_vm._s(_vm.$t('f.phone')))]),_c('p',[_vm._v(" "+_vm._s(_vm.model.phone || _vm.$t('d.empty'))+" "),_c('a',{attrs:{"href":_vm.changePhoneHref,"title":_vm.$t('auth.change_phone'),"target":"_blank"}},[_vm._v(" ("+_vm._s(_vm.$t('a.change'))+") ")])])],1),_c('div',{staticClass:"flex xs12 md6 xl4"},[_c('p',{staticClass:"text-category"},[_vm._v(_vm._s(_vm.$t('f.categories.preferences')))]),_c('hr'),_c('div',{staticClass:"display-5"},[_vm._v(" "+_vm._s(_vm.$t('f.language'))+" "),_c('span',{staticClass:"text-hint"},[_c('va-icon',{attrs:{"icon":"ion ion-ios-information-circle-outline"}}),_vm._v(" "+_vm._s(_vm.$t('d.languagePreferenceHint'))+" ")],1)]),_c('p',[_vm._v(" "+_vm._s(_vm.languageMap(_vm.get(_vm.model, 'preference.language')))+" "),_c('a',{attrs:{"href":"javascript:"},on:{"click":function($event){_vm.editModel = _vm.get(_vm.model, 'preference.language'); _vm.editLanguage = true}}},[_vm._v(" ("+_vm._s(_vm.$t('a.change'))+") ")])]),_c('va-modal',{attrs:{"title":_vm.$t('a.edit'),"onOk":function (d) {
                    _vm.doUpdate({ preference: { language: _vm.editModel || null } }, _vm.id)
                      .then(function (ok) {
                        if (!ok) { return; }
                        d.close();
                        _vm.reload();
                      });
                    return false;
                  }},model:{value:(_vm.editLanguage),callback:function ($$v) {_vm.editLanguage=$$v},expression:"editLanguage"}},[_c('va-simple-select',{attrs:{"label":_vm.$t('f.language'),"options":['zh', 'en'],"searchable":"","clearable":"","formatter":_vm.languageMap},model:{value:(_vm.editModel),callback:function ($$v) {_vm.editModel=$$v},expression:"editModel"}})],1)],1),_c('div',{staticClass:"flex xs12 md6 xl4"},[_c('p',{staticClass:"text-category"},[_vm._v(_vm._s(_vm.$t('f.categories.security')))]),_c('hr'),_c('p',{staticClass:"display-5"},[_vm._v(_vm._s(_vm.$t('auth.password')))]),_c('p',[_vm._v(" "+_vm._s(_vm.model.hasPw ? '••••••••' : _vm.$t('d.unset'))+" "),(_vm.model.pw_updated)?_c('span',{staticClass:"text-hint",attrs:{"title":_vm.moment(_vm.model.pw_updated).format('LLL')}},[_c('va-icon',{attrs:{"icon":"ion ion-ios-information-circle-outline"}}),_vm._v(" "+_vm._s(_vm.$t('d.lastChangedAt$time', {time: _vm.moment(_vm.model.pw_updated).fromNow()}))+" ")],1):_vm._e()]),_c('va-button',{attrs:{"tag":"a","href":_vm.changePasswordHref,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('auth.change_password'))+" ")]),_c('va-button',{attrs:{"color":"danger"},on:{"click":_vm.promptLogoutFromAllPlace}},[_vm._v(" "+_vm._s(_vm.$t('auth.logoutFromAllPlace'))+" ")])],1)]):_vm._e()])],2)],1),_c('MyProfile',{ref:"profile"})]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 'profiles' && _vm.get(_vm.model, 'profiles.length') > 1),expression:"tab === 'profiles' && get(model, 'profiles.length') > 1"}]},[_c('Profiles',{ref:"table",attrs:{"crudURL":"user/me/profiles/{id}","customActions":_vm.profilesActions,"fieldEvent":_vm.profilesFieldEvent,"rowClicked":false}},[_c('template',{slot:"additionalTableControl"})],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }