import Vue from 'vue';

const EmptyParentComponent = {
  template: '<router-view></router-view>',
};

export const routes = [
  {
    name: 'logout',
    path: 'logout',
    async beforeEnter (to, from, next) {
      Vue.prototype.$redirectLogout();
      // const { host, base } = store.getters.api;
      // window.location = host + base + '/sso/logout?redirect=' +
      //   encodeURIComponent(window.location.origin);
    },
  },
];

export const admin = [
  {
    name: 'membership',
    path: 'm',
    component: EmptyParentComponent,
    children: [
      {
        name: 'm_users',
        path: 'users',
        component: () => import('../components/membership/Users.vue'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        name: 'm_user',
        path: 'users/:id',
        component: () => import('../components/membership/User.vue'),
        props: r => ({ id: r.params.id }),
        meta: {
          auth: ['admin'],
        },
      },
      {
        name: 'm_profiles',
        path: 'profiles',
        component: () => import('../components/membership/Profiles.vue'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        name: 'm_profile',
        path: 'profiles/:id',
        component: () => import('../components/membership/Profile.vue'),
        props: r => ({ id: r.params.id }),
        meta: {
          auth: ['admin'],
        },
      },
    ],
  },
];
