<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-checkbox"
  >
    <va-checkbox
      v-if="!rowField.singleSelect"
      @input="toggleAllCheckbox"
      v-bind="isAllItemsInCurrentPageSelected()"
    />
  </th>
  <td
    v-else
    class="vuetable-td-component-checkbox"
  >
    <va-radio-button
      v-if="isSingle"
      @input="toggleCheckbox(rowData, $event)"
      :option="true"
      :value="isSelected(rowData)"
    >
      <template slot="label">&nbsp;</template>
    </va-radio-button>
    <va-checkbox
      v-else
      @input="toggleCheckbox(rowData, $event)"
      :value="isSelected(rowData)"
    />
  </td>
</template>

<script>
  import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin';

  export default {
    name: 'VuetableFieldCheckboxCustom',
    mixins: [VuetableFieldCheckboxMixin],
    computed: {
      isSingle () {
        return this.rowField.singleSelect;
      },
    },
    methods: {
      toggleCheckbox (dataItem, checked) {
        if (checked && this.rowField.singleSelect) {
          this.vuetable.clearSelectedValues();
        }
        this.vuetable.onCheckboxToggled(checked, this.rowField.name,
          dataItem);
      },
      toggleAllCheckbox (checked) {
        if (checked && this.rowField.singleSelect) {
          return;
        }
        this.vuetable.onCheckboxToggledAll(checked);
      },
      isAllItemsInCurrentPageSelected () {
        if (!this.vuetable.tableData) return { value: false };

        let idColumn = this.vuetable.trackBy;
        let selected = this.vuetable.tableData.filter(
          (item) => this.vuetable.isSelectedRow(item[idColumn]),
        );

        // count == 0, clear the checkbox
        if (selected.length <= 0) {
          return { value: false };
        } else if (selected.length < this.vuetable.tableData.length) {
          // count > 0 and count < perPage, set checkbox state to 'indeterminate'
          return { value: true, indeterminate: true };
        } else {
          // count == perPage, set checkbox state to 'checked'
          return { value: true, indeterminate: false };
        }
      },
    },
  };
</script>
