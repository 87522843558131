import Vue from 'vue';
import store from '../store';
import Cookies from 'js-cookie';
import moment from 'moment';

function translateObjIn (
  locale,
  dict,
  defaultValue = '',
) {
  if (!dict || !locale) {
    return defaultValue;
  }
  const fallback = store.state.i18n.fallback;
  const localeRegional = locale.split('-')[0];
  for (const k of [locale, localeRegional, fallback]) {
    if (dict.hasOwnProperty(k)) {
      return dict[k];
    }
  }
  return defaultValue;
}

function translateObj (
  dict,
  defaultValue = '',
) {
  return translateObjIn.apply(this,
    [Vue.i18n.locale()].concat(arguments));
}

Vue.i18n.translateObj = translateObj;
Vue.i18n.translateObjIn = translateObjIn;
Vue.prototype.$i18n.translateObj = translateObj;
Vue.prototype.$i18n.translateObjIn = translateObjIn;
Vue.prototype.$tObj = translateObj;
Vue.prototype.$tObjIn = translateObjIn;

// add translations directly to the application
// Vue.i18n.add('br', require('./pt_br.json'));
Vue.i18n.add('en', require('./en.json'));
// Vue.i18n.add('es', require('./es.json'));
// Vue.i18n.add('cn', require('./cn.json'));
Vue.i18n.add('zh', require('./zh.json'));

// set the start locale to use
Vue.i18n.set(Cookies.get('locale') || 'en');

// set fallback for non-translated strings
Vue.i18n.fallback('en');

store.subscribe(({ type, payload }, state) => {
  if (type === 'i18n/SET_LOCALE') {
    Cookies.set('locale', payload.locale,
      { expires: moment().add(1, 'month').toDate() });
  }
});
