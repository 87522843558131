<template>
  <div class="grow profile-dropdown flex-center">
    <slot/>
    <va-dropdown-old
      v-model="isShown"
      position="bottom"
    >
      <slot name="dropdown">
        <div
          v-for="option in options"
          :key="option.name"
          class="dropdown-item plain-link-item"
        >
          <router-link :to="{name: option.redirectTo}" class="plain-link" href="#">
            {{ $t(`user.${option.name}`) }}
          </router-link>
        </div>
      </slot>
    </va-dropdown-old>
  </div>
</template>

<script>
export default {
  name: 'profile-section',
  data () {
    return {
      isShown: false,
    }
  },
  props: {
    options: {
      type: Array,
      default: () => [
        {
          name: 'profile',
          redirectTo: 'myself',
        },
        {
          name: 'logout',
          redirectTo: 'logout',
        },
      ],
    },
  },
}
</script>

<style lang="scss">
@import '../../../../../vuestic-theme/vuestic-sass/resources/resources';

.profile-dropdown {
  cursor: pointer;
}
</style>
