<template>
  <div class="va-avatar__container" :style="containerStyles">
    <slot>
      <div class="va-avatar__img" :style="{ backgroundImage: `url('${src}')` }"/>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'va-avatar',
    props: {
      size: {
        type: String,
        default: '50px',
      },
      src: {},
      border: {
        type: String,
      },
      background: {
        type: String,
      },
    },
    computed: {
      containerStyles () {
        return {
          width: this.size,
          height: this.size,
          border: this.border,
          background: this.background,
        };
      },
    },
  };
</script>

<style lang="scss">
  @import '../vuestic-theme/vuestic-sass/resources/resources';

  .va-avatar {

    &__container {
      display: inline-block;
      background: white;
      border-radius: 50%;
      border: 2px solid $lighter-gray;
      overflow: hidden;

      > * {
        height: 100%;
        width: 100%;
      }
    }

    &__img {
      background-size: cover;
      background-position: center;
    }

  }
</style>
