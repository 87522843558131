export const breadcrumbs = {
  root: {
    name: '/',
    displayName: 'breadcrumbs.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
    },
    {
      name: 'account',
      displayName: 'user.profile',
      disabled: true,
      children: [
        {
          name: 'myself',
          displayName: 'menu.accountAndSecurity',
        },
        {
          name: 'payments',
          displayName: 'menu.paymentsAndSubscriptions',
        },
        {
          name: 'transactions',
          displayName: 'schema.ec_transactions',
        },
      ],
    },
    {
      name: 'membership',
      displayName: 'menu.membership',
      disabled: true,
      children: [
        {
          name: 'm_users',
          displayName: 'schema.m_users',
        },
        {
          name: 'm_user',
          displayName: 'schema.m_user',
          disabled: true,
        },
        {
          name: 'm_profiles',
          displayName: 'schema.m_profiles',
        },
        {
          name: 'm_profile',
          displayName: 'schema.m_profile',
          disabled: true,
        },
      ],
    },
    {
      name: 'ecommerce',
      displayName: 'menu.ecommerce',
      disabled: true,
      children: [
        {
          name: 'ec_transactions',
          displayName: 'schema.ec_transactions',
        },
      ],
    },
    {
      name: 'linklink',
      displayName: 'menu.linklink',
      disabled: true,
      children: [
        {
          name: 'll_sourcelinks',
          displayName: 'schema.ll_sourcelinks',
        },
        {
          name: 'll_sourcelink',
          displayName: 'schema.ll_sourcelink',
          disabled: true,
        },
        {
          name: 'll_sharedlinks',
          displayName: 'schema.ll_sharedlinks',
        },
        {
          name: 'll_sharedlink',
          displayName: 'schema.ll_sharedlink',
          disabled: true,
        },
        {
          name: 'll_cashoutApplications',
          displayName: 'schema.ll_cashoutApplications',
        },
      ],
    },
    {
      name: 'statistics',
      displayName: 'menu.statistics',
      disabled: true,
      children: [
        {
          name: 'charts',
          displayName: 'menu.charts',
        },
        {
          name: 'progress-bars',
          displayName: 'menu.progressBars',
        },
      ],
    },
    {
      name: 'forms',
      displayName: 'menu.forms',
      disabled: true,
      children: [
        {
          name: 'form-elements',
          displayName: 'menu.formElements',
        },
        {
          name: 'form-wizards',
          displayName: 'menu.formWizards',
        },
        {
          name: 'medium-editor',
          displayName: 'menu.mediumEditor',
        },
      ],
    },
    {
      name: 'tables',
      displayName: 'menu.tables',
    },
    {
      name: 'ui',
      displayName: 'menu.uiElements',
      disabled: true,
      children: [
        {
          name: 'typography',
          displayName: 'menu.typography',
        },
        {
          name: 'buttons',
          displayName: 'menu.buttons',
        },
        {
          name: 'collapse',
          displayName: 'menu.collapse',
        },
        {
          name: 'filters',
          displayName: 'menu.filters',
        },
        {
          name: 'color-pickers',
          displayName: 'menu.colorPickers',
        },
        {
          name: 'timelines',
          displayName: 'menu.timelines',
        },
        {
          name: 'notifications',
          displayName: 'menu.notifications',
        },
        {
          name: 'dropdowns',
          displayName: 'menu.dropdowns',
        },
        {
          name: 'icon-sets',
          displayName: 'menu.icons',
          children: [
            {
              displayName: 'concrete',
              name: 'icon-set',
            },
          ],
        },
        {
          name: 'spinners',
          displayName: 'menu.spinners',
        },
        {
          name: 'grid',
          displayName: 'menu.grid',
        },
        {
          name: 'modals',
          displayName: 'menu.modals',
        },
        {
          name: 'file-upload',
          displayName: 'menu.fileUpload',
        },
        {
          name: 'chips',
          displayName: 'menu.chips',
        },
        {
          name: 'cards',
          displayName: 'menu.cards',
        },
        {
          name: 'tree-view',
          displayName: 'menu.treeView',
        },
        {
          name: 'spacing',
          displayName: 'menu.spacing',
        },
        {
          name: 'sliders',
          displayName: 'menu.sliders',
        },
        {
          name: 'popovers',
          displayName: 'menu.popovers',
        },
        {
          name: 'chat',
          displayName: 'menu.chat',
        },
        {
          name: 'rating',
          displayName: 'menu.rating',
        },
      ],
    },
    {
      name: 'maps',
      displayName: 'menu.maps',
      disabled: true,
      children: [
        {
          name: 'google-maps',
          displayName: 'Google Maps',
        },
        {
          name: 'yandex-maps',
          displayName: 'Yandex Maps',
        },
        {
          name: 'leaflet-maps',
          displayName: 'Leaflet Maps',
        },
        {
          name: 'bubble-maps',
          displayName: 'Bubble Maps',
        },
        {
          name: 'line-maps',
          displayName: 'Line Maps',
        },
      ],
    },
    {
      name: 'pages',
      displayName: 'menu.pages',
      disabled: true,
      children: [
        {
          name: '404-pages',
          displayName: '404 Pages',
        },
      ],
    },
  ],
};
