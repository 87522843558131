<template>
  <div class="content va-layout fluid gutter--md">
    <div class="header va-row wrap">
      <div class="title display-4 grow">{{title}}</div>
      <div class="actions">
        <va-button small color="gray" flat @click="doClose()">{{$t('a.close')}}</va-button>
        <va-button small color="primary" @click="promptUpdate()">{{$t('a.update')}}</va-button>
        <va-button small color="danger" @click="promptDelete()">{{$t('a.delete')}}</va-button>
      </div>
    </div>
    <hr/>
    <ValidationObserver tag="div" class="va-row" ref="form">
      <template v-if="model">
        <div class="flex md3">
          <va-input
            v-model="model.name"
            :label="$t('f.displayName')"
          />
        </div>
        <div class="flex md3">
          <va-simple-select
            v-model="model.status"
            :label="$t('f.status')"
            :clearable="false"
            required
            :options="['0$requested', '1$accepted', '99$rejected']"
            :formatter="statusFilter"
          />
        </div>
      </template>
    </ValidationObserver>
  </div>
</template>

<script>
  import VaAvatar from 'components/VaAvatar';
  import ApiError from 'services/ApiError';
  import detailRowMixin from 'src/services/detailRowMixin';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import { status } from 'src/services/filters';

  export default {
    name: 'ProfileDetailRow',
    mixins: [detailRowMixin],
    components: {
      VaAvatar,
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        crudURL: 'profile/{id}',
      };
    },
    computed: {
      title () {
        return this.$t('d.quickView');
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      statusFilter: status,
      async reload () {
        this.model = (await this.$http.get(this.crudURL,
          { params: { id: this.id } })).data;
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      async doDelete () {
        let cascade;
        while (true) {
          try {
            await this.$http.delete(this.crudURL, { params: { id: this.id, cascade } });
            return true;
          } catch (err) {
            const ae = ApiError.wrap(err);
            if (ae.code === 'dangerous_operation') {
              if (confirm(this.$t('p.promptForceDeleteProfile'))) {
                cascade = 1;
                continue;
              }
            }
            throw ae;
          }
        }
      },
    },
  };
</script>
