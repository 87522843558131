<template>
  <div class="content va-layout fluid gutter--md">
    <div class="header va-row wrap">
      <div class="title display-4 grow">{{title}}</div>
      <div class="actions">
        <va-button small color="gray" flat @click="doClose()">{{$t('a.close')}}</va-button>
        <va-button small color="primary" @click="promptUpdate()">{{$t('a.update')}}</va-button>
        <va-button small color="danger" @click="promptDelete()">{{$t('a.delete')}}</va-button>
      </div>
    </div>
    <hr/>
    <ValidationObserver tag="div" class="va-row" ref="form">
      <template v-if="model">
        <div class="flex md3">
          <p class="text--bold">{{$t('f.username')}}</p>
          <p>{{model.username || $t('d.empty')}}</p>
          <p class="text--bold">{{$t('f.email')}}</p>
          <p>{{model.email || $t('d.empty')}}</p>
          <p class="text--bold">{{$t('f.phone')}}</p>
          <p>{{model.phone || $t('d.empty')}}</p>
        </div>
        <div class="flex md3">
          <p class="display-5">{{$t('schema.m_profiles')}}</p>
          <ol>
            <li v-for="p in model.profiles" :key="p._id">
              <router-link :to="{name: 'm_profile',params: {id: p._id}}">
                {{`${p.name || $t('d.empty')} (${p.role}, ${$t('f.id')}: ${p.sid})`}}
              </router-link>
            </li>
          </ol>
        </div>
        <div class="flex md3">
          <p class="display-5">{{$t('auth.reset_password')}}</p>
          <form
            ref="resetPassword"
            :action="resetPasswordHref"
            method="post"
            target="_blank"
            class="d-none">
            <input type="hidden" name="redirect" value="1"/>
            <input type="hidden" name="link_only" value="1"/>
          </form>
          <va-button @click="doResetPassword">
            {{$t('p.clickTo$action', {action: $t('auth.reset_password')})}}
          </va-button>
        </div>
      </template>
    </ValidationObserver>
  </div>
</template>

<script>
  import detailRowMixin from 'src/services/detailRowMixin';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';

  export default {
    name: 'UserDetailRow',
    mixins: [detailRowMixin],
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        crudURL: 'user/{id}',
      };
    },
    computed: {
      title () {
        return this.$t('d.quickView');
      },
      resetPasswordHref () {
        return `${this.api.host}${this.api.base}/user/${this.id}/requestChangePassword`;
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.model = (await this.$http.get(this.crudURL,
          {
            params: {
              id: this.id,
              populate: { path: 'profiles', select: 'sid role name' },
            },
          })).data;
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      doResetPassword () {
        this.$refs.resetPassword.submit();
      },
    },
  };
</script>
