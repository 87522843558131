const EmptyParentComponent = {
  template: '<router-view></router-view>',
};

export const admin = [
  {
    name: 'ecommerce',
    path: 'ec',
    component: EmptyParentComponent,
    children: [
      {
        name: 'ec_transactions',
        path: 'transactions',
        component: () => import('../components/ecommerce/Transactions.vue'),
        meta: {
          auth: ['admin'],
        },
      },
      // {
      //   name: 'ec_transaction',
      //   path: 'transactions/:id',
      //   component: () => import('../components/ecommerce/Transaction.vue'),
      //   props: r => ({ id: r.params.id }),
      //   meta: {
      //     auth: true,
      //   },
      // },
    ],
  },
];
