<template>
  <div class="tables">
    <div class="va-row">
      <div class="flex xs12">
        <va-card :title="$t('schema.ll_clicks')">
          <va-data-table
            ref="table"
            class="row-clickable"
            apiUrl="ll/click"
            :httpFetch="tableAdapter.httpFetch"
            :transform="tableAdapter.transform"
            :tableFields="tableFields"
            :itemsPerPage="itemsPerPage"
            apiMode
            :appendParams="appendParamsComputed"
            :queryParams="tableAdapter.queryParams"
            :filterQuery="tableAdapter.filterQuery"
            :sortOrder="sortOrder"
            :vuetableOptions="tableAdapter.vuetableOptions"
            @vuetable:row-clicked="onRowClicked"
          >
            <template slot="additionalTableControl">
              <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
              <slot name="additionalTableControl"/>
            </template>
          </va-data-table>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
  import vuetableMixin from 'src/services/vuetableMixin';
  import { date } from 'src/services/tableColumnTemplates';
  import { merge } from 'lodash';

  export default {
    name: 'Clicks',
    mixins: [vuetableMixin],
    components: {},
    props: {
      appendParams: {
        default: () => ({}),
      },
    },
    data () {
      return {
        editDialog: false,
        editId: null,
      };
    },
    computed: {
      appendParamsComputed () {
        return merge({}, this.appendParams);
      },
      tableFields () {
        return [
          {
            name: 'created_at',
            title: this.$t('f.time'),
            sortField: 'created_at',
            ...date(true),
          },
          {
            name: 'status',
            title: this.$t('f.status'),
            sortField: 'status',
          },
          {
            name: 'coins',
            title: this.$t('f.llcoins'),
            sortField: 'coins',
          },
          {
            name: 'req.ip',
            title: this.$t('f.ipAddr'),
            sortField: 'req.ip',
          },
          {
            name: 'req.track_id',
            title: this.$t('f.sessionId'),
            sortField: 'req.track_id',
            visible: false,
          },
          {
            name: 'req.referer',
            title: this.$t('f.referrer'),
            sortField: 'req.referer',
          },
          {
            name: 'req.userAgent',
            title: this.$t('f.userAgent'),
            sortField: 'req.userAgent',
            width: '50%',
          },
          {
            name: '_stats.app',
            title: this.$t('f.source'),
            sortField: '_stats.app',
          },
        ];
      },
    },
    methods: {},
  };
</script>
