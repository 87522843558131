<template>
  <div class="tables">
    <div class="va-row">
      <div class="flex xs12">
        <va-card :title="$t('schema.ll_cashoutApplication')">
          <va-data-table
            ref="table"
            class="row-clickable"
            apiUrl="ll/cashoutApplication?populate=created_by"
            :httpFetch="tableAdapter.httpFetch"
            :transform="tableAdapter.transform"
            :tableFields="tableFields"
            :itemsPerPage="itemsPerPage"
            apiMode
            :appendParams="appendParamsComputed"
            :queryParams="tableAdapter.queryParams"
            :filterQuery="tableAdapter.filterQuery"
            :sortOrder="sortOrder"
            :vuetableOptions="tableAdapter.vuetableOptions"
            @vuetable:row-clicked="onRowClicked"
            @vuetable:field-event="onFieldEvent"
          >
            <template slot="additionalTableControl">
              <!--              <va-toggle-->
              <!--                :label="$t('d.showDisabled')"-->
              <!--                v-model="appendFilter.enabled"-->
              <!--                :trueValue="ne"-->
              <!--                :falseValue="true"-->
              <!--                small-->
              <!--                style="margin-bottom: 0"-->
              <!--              />-->
              <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
              <slot name="additionalTableControl">
                <!--                <va-button @click="doEdit(null)">{{$t('a.create')}}</va-button>-->
              </slot>
            </template>
            <!--            <template slot="detailRowComponent" slot-scope="scope">-->
            <!--              <DetailRow-->
            <!--                v-bind="scope"-->
            <!--                @update="reload"-->
            <!--                @close="hideDetailRow"-->
            <!--                @delete="reload"-->
            <!--              />-->
            <!--            </template>-->
          </va-data-table>
        </va-card>
      </div>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="editId"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </div>
</template>

<script>
  import EditDialog from './CashoutApplicationEditDialog';
  // import DetailRow from './SourceLinkDetailRow';
  import ActionsColumn, { actions } from 'src/components/tables/ActionsColumn';
  import vuetableMixin from 'src/services/vuetableMixin';
  import { merge, cloneDeep } from 'lodash';
  import { _id, date, status } from 'src/services/tableColumnTemplates';

  export default {
    name: 'CashoutApplications',
    mixins: [vuetableMixin],
    components: {
      EditDialog,
      // DetailRow,
    },
    data () {
      return {
        crudURL: 'll/cashoutApplication/{id}',
        ne: { $ne: true },
        appendFilter: {},
      };
    },
    computed: {
      appendParamsComputed () {
        return merge({
          filter: cloneDeep(this.appendFilter),
        }, this.appendParams);
      },
      tableFields () {
        return [
          _id(),
          {
            name: 'created_by.name',
            title: this.$t('f.created_by'),
          },
          status(),
          {
            name: 'created_at',
            title: this.$t('f.created_at'),
            sortField: 'created_at',
            ...date(true),
          },
          {
            name: ActionsColumn,
            actions ({ data }) {
              return [
                actions.view.call(this),
                actions.edit.call(this),
                actions.delete.call(this),
              ].filter(x => x);
            },
          },
        ];
      },
    },
    methods: {
      onFieldEvent (type, payload) {
        if (type === 'action') {
          const { action, data } = payload;
          switch (action.key) {
            case 'delete':
              this.promptDelete(data._id);
              break;
            case 'edit':
              this.doEdit(data._id);
              break;
            case 'view':
              this.$router.push({ name: 'll_cashoutApplication', params: { id: data._id } });
              break;
          }
        }
      },
    },
  };
</script>
