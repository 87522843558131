import { merge } from 'lodash';

export function create (init) {
  return merge({
    username: undefined,
    email: undefined,
    phone: undefined,
    enabled: true,
    preference: {
      language: null,
    },
  }, init);
}
