<template>
  <va-modal
    v-model="valueProxy"
    :title="title"
    noOutsideDismiss
    hideDefaultActions
    size="large"
  >
    <template slot="actions">
      <va-button flat color="gray" @click="doClose()">{{$t('a.cancel')}}</va-button>
      <va-button color="primary" @click="promptCreate()" v-if="!id">{{$t('a.create')}}</va-button>
      <va-button color="primary" @click="promptUpdate()" v-if="id">{{$t('a.update')}}</va-button>
    </template>
    <ValidationObserver tag="div" ref="form">
      <div v-if="model">
        <div class="va-row" v-if="isCreate">
          <div class="flex xs12">
            <ValidationProvider
              vid="gateway"
              v-slot="{errors, valid}" :name="$t('f.gateway')" mode="lazy"
              rules="required"
            >
              <va-input-wrapper
                :error="valid === false"
                :error-messages="errors"
              >
                <va-simple-select
                  v-model="model.gateway"
                  :label="$t('f.gateway')"
                  :options="Object.keys(gateways)"
                  :clearable="false"
                  :formatter="id => gateways[id] && gateways[id].name"
                  :readonly="!isCreate"
                />
              </va-input-wrapper>
            </ValidationProvider>
          </div>
        </div>
        <div class="va-row" v-if="model.gateway === 'paypal.me'">
          <div class="flex xs12">
            <ValidationProvider
              vid="paypalmeId"
              v-slot="{errors, valid}" :name="$t('f.paypalmeId')" mode="lazy"
              :rules="{required: true, regex: /(?:https?:\/\/)?(?:www\.)?paypal\.me\/([^/]+)\/?/i}"
            >
              <va-input
                v-model="model.paypalmeId"
                :label="$t('f.paypalmeId')"
                :error="valid === false"
                :error-messages="errors"
                placeholder="paypal.me/"
              />
            </ValidationProvider>
          </div>
        </div>
        <div class="va-row">
          <div class="flex xs12">
            <va-input
              v-model="model.description"
              :label="$t('f.remarks')"
              type="textarea"
            />
          </div>
        </div>
      </div>
    </ValidationObserver>
  </va-modal>
</template>

<script>
  import Objectid from 'components/objectid';
  import editDialogMixin from 'src/services/editDialogMixin';
  import { create } from 'src/services/schema/ECPaymentProfile';
  import { keyBy } from 'lodash';

  export default {
    name: 'PaymentProfileEditDialog',
    components: { Objectid },
    mixins: [editDialogMixin],
    data () {
      return {
        crudURL: 'user/me/ec/payment/profile/{id}',
        gateways: {},
      };
    },
    computed: {
      title () {
        return this.$t('schema.ec_paymentProfile');
      },
    },
    methods: {
      async reload () {
        this.gateways = keyBy((await this.$http.get('ec/payment/gateway')).data, 'id');
        this.model = this.id ? (await this.$http.get(this.crudURL,
          { params: { id: this.id } })).data : create();
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      },
      async promptCreate () {
        if (!await this.$refs.form.validate()) {
          return;
        }
        try {
          const model = (await this.$http.post(this.crudURL, this.model,
            { params: { id: null } })).data;
          this.showToast(this.$t('p.created'));
          this.$emit('create', model);
          return model;
        } catch (err) {
          this.handleError(err);
        }
      },
    },
  };
</script>
