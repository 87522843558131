import Vue from 'vue';

export function httpFetch (url, options) {
  if (options.params) {
    for (let k in options.params) {
      if (['sort', 'filter', 'populate', 'search'].includes(k)) {
        options.params[k] = JSON.stringify(options.params[k]);
      }
    }
  }
  return Vue.http.get(url, options);
}

export function transform (data) {
  const { docs, total, limit, offset, page } = data;
  return {
    data: docs,
    total: total,
    per_page: limit,
    current_page: page,
    last_page: Math.ceil(total / limit) || 0,
    from: offset,
    to: offset + docs.length,
  };
}

export function queryParams (sortOrder, currentPage, perPage) {
  const params = {};
  params.page = currentPage;
  params.limit = perPage;
  if (sortOrder && sortOrder.length) {
    params.sort = {};
    for (const f of sortOrder) {
      params.sort[f.sortField] = f.direction === 'desc' ? -1 : 1;
    }
  }
  return params;
}

export const vuetableOptions = {
  trackBy: '_id',
};

export const filterQuery = 'search';
