<template>
  <component
    :is="tag"
    class="text--code"
    :title="title"
    @click.prevent="copy"
  >
    <slot>{{$t('f.id')}}: {{value}}</slot>
  </component>
</template>

<script>
  export default {
    name: 'objectid',
    props: {
      value: {},
      tag: {
        type: String,
        default: 'span',
      },
    },
    computed: {
      title () {
        return this.$t('p.clickTo$action', { action: this.$t('a.copy') });
      },
    },
    methods: {
      async copy () {
        await this.$copyText(this.value);
        this.showToast(this.$t('p.copied'));
      },
    },
  };
</script>
