const EmptyParentComponent = {
  template: '<router-view></router-view>',
};

export const admin = [
  {
    name: 'linklink',
    path: 'll',
    component: EmptyParentComponent,
    children: [
      {
        name: 'll_cashoutApplications',
        path: 'cashoutApplications',
        component: () => import('../components/linklink/CashoutApplications.vue'),
        meta: {
          auth: true,
        },
      },
      {
        name: 'll_cashoutApplication',
        path: 'cashoutApplications/:id',
        component: () => import('../components/linklink/CashoutApplication.vue'),
        props: r => ({ id: r.params.id }),
        meta: {
          auth: true,
        },
      },
      {
        name: 'll_sourcelinks',
        path: 'sourcelinks',
        component: () => import('../components/linklink/SourceLinks.vue'),
        meta: {
          auth: true,
        },
      },
      {
        name: 'll_sourcelink',
        path: 'sourcelinks/:id',
        component: () => import('../components/linklink/SourceLink.vue'),
        props: r => ({ id: r.params.id }),
        meta: {
          auth: true,
        },
      },
      {
        name: 'll_sharedlinks',
        path: 'sharedlinks',
        component: () => import('../components/linklink/SharedLinks.vue'),
        meta: {
          auth: true,
        },
      },
      {
        name: 'll_sharedlink',
        path: 'sharedlinks/:id',
        component: () => import('../components/linklink/SharedLink.vue'),
        props: r => ({ id: r.params.id }),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
