import _moment from 'moment';
import Vue from 'vue';
import { get } from 'lodash';
import {
  dateFormat,
  datetimeFormat,
  timeFormat,
} from '@/services/tableColumnTemplates';
import { parsePhoneNumber } from 'libphonenumber-js';

export function moment (v, format = 'YYYY/MM/DD HH:mm') {
  if (v == null) return '';
  return _moment(v).format(format);
}

export function duration (v, format) {
  if (v == null) return '';
  return _moment.duration(v).format(format);
}

export function datePrettify (
  v, longFormat = 'YYYY/MM/DD HH:mm', now = new Date()) {
  if (v == null) return '';
  v = _moment(v);
  return Math.abs(v.diff(now, 'day', true)) > 2 ? v.format(longFormat) : v.from(
    now);
}

export function dateRange (v, startProp, endProp, pretty = false) {
  if (v == null) return '';
  let s = _moment(get(v, startProp, null));
  let e = _moment(get(v, endProp, null)).add(-1, 'ms');
  let sD = s.isValid() ? s.format(dateFormat) : '';
  let eD = e.isValid() ? e.format(dateFormat) : '';
  if (pretty && sD === eD && sD) {
    return sD;
  }
  return `${sD} - ${eD}`.trim();
}

export function datetimeRange (v, startProp, endProp, pretty = false) {
  if (v == null) return '';
  let s = _moment(get(v, startProp, null));
  let e = _moment(get(v, endProp, null)).add(-1, 'ms');
  let sD = s.isValid() ? s.format(dateFormat) : '';
  let eD = e.isValid() ? e.format(dateFormat) : '';
  let sT = s.isValid() ? s.format(timeFormat) : '';
  let eT = e.isValid() ? e.format(timeFormat) : '';
  let sDT = s.isValid() ? s.format(datetimeFormat) : '';
  let eDT = e.isValid() ? e.format(datetimeFormat) : '';
  let exact = `${sDT} - ${eDT}`.trim();
  let short = sD;
  if (sD !== eD) {
    short = exact;
  } else {
    short = `${sD} ${sT} - ${eT}`.trim();
  }
  return pretty ? short : exact;
}

export function currency (
  v, dollar = '$ ', decimals = 2, accountingFormat = false) {
  if (v == null) return '';
  let value = dollar + Math.abs(v).toFixed(decimals);
  if (accountingFormat) {
    return v >= 0 ? ' ' + value + ' ' : '(' + value + ')';
  }
  return v >= 0 ? value : '-' + value;
}

export function amount (
  v, dollar = '$ ', decimals = 2, accountingFormat = false) {
  if (v == null) return '';
  const prefix = v.currency
    ? Vue.i18n.translate(`currencies.${v.currency.toLowerCase()}.shortcode`,
      v.currency.toUpperCase() + dollar)
    : '';
  return currency(v.value, prefix, decimals, accountingFormat);
}

export function round (v, decimals = 2) {
  if (v == null) return '';
  let order = Math.pow(10, decimals);
  return Math.round(order * v) / order;
}

function formatSiUnit (value, units, zeroIndex, base, decimals) {
  decimals = Math.pow(10, decimals);
  let order = Math.floor(Math.log(value) / Math.log(base));
  order = Math.min(Math.max(order + zeroIndex, 0), units.length - 1) -
    zeroIndex;
  let round = Math.round((value / Math.pow(base, order)) * decimals) / decimals;
  return `${round} ${units[order + zeroIndex]}`;
}

export function byte (value, decimals = 2) {
  return formatSiUnit(value, ['B', 'KB', 'MB', 'GB', 'TB'], 0, 1024, decimals);
}

export function objectId (v) {
  if (v == null) return '';
  return v.substr(-6).toUpperCase();
}

export function status (v) {
  if (v == null) return '';
  v = v.split('$').reverse()[0];
  return Vue.i18n.translate('statuses.' + v,
    v.charAt(0).toUpperCase() + v.slice(1));
}

export function phone (v) {
  if (v == null) return '';
  try {
    return parsePhoneNumber(v, 'HK')
      .formatInternational()
      .replace(/^(\+[0-9]+)\s/, '($1) ');
  } catch (e) {
    return v;
  }
}
