import { merge } from 'lodash';

export function create (init) {
  return merge({
    enabled: true,
    role: undefined,
    status: '0$requested',
    name: undefined,
  }, init);
}
