<template>
  <div class="va-simple-select">
    <div
      class="form-group with-icon-right dropdown select-form-group"
      v-dropdown.closeOnMenuClick="{ isBlocked: true, onDropdownClose: onDropdownClose }"
      :class="{'has-error': hasErrors()}"
    >
      <div
        class="input-group dropdown-toggle va-simple-select__dropdown-toggle">
        <div>
          <input
            @focus="showDropdown()"
            @input="onSearch"
            :class="{'has-value': !!(value || displayValue)}"
            v-model="displayValue"
            :name="name"
            :placeholder="computedPlaceholder"
            :readonly="!searchable"
          >
          <label class="control-label">{{label}}</label>
          <va-icon icon="bar"/>
          <small v-show="hasErrors()" class="help text-danger">
            {{ showRequiredError() }}
          </small>
        </div>
        <va-icon
          icon="ion ion-ios-arrow-down icon-right input-icon va-simple-select__dropdown-arrow"
          @click="showDropdown"
        />
      </div>
      <div v-if="isClearable">
        <va-icon
          icon="fa fa-close icon-cross icon-right input-icon va-simple-select__unselect"
          @click.native="clear"
        />
      </div>
      <div
        class="dropdown-menu va-simple-select__dropdown-menu"
        aria-labelledby="dropdownMenuButton">
        <scrollbar ref="scrollbar">
          <div
            class="dropdown-menu-content va-simple-select__dropdown-menu-content">
            <div
              class="dropdown-item va-simple-select__dropdown-item"
              v-for="(option, index) in options"
              :key="index"
              :class="{'selected': isOptionSelected(option)}"
              @click="toggleSelection(option)"
            >
            <span
              class="ellipsis">{{formatOption(option)}}</span>
            </div>
          </div>
        </scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '../../vuestic-directives/Dropdown'
import Scrollbar from '../va-scrollbar/VaScrollbar.vue'

export default {
  name: 'va-simple-select',
  components: {
    Scrollbar,
  },
  directives: {
    dropdown: Dropdown,
  },
  props: {
    label: String,
    options: Array,
    value: {
      required: true,
    },
    optionKey: String,
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
    },
    formatter: {
      type: Function,
    },
    placeholder: {
      type: String,
    },
    searchable: {
      type: Boolean,
    },
  },
  data () {
    return {
      validated: false,
      displayValue: this.formatOption(this.value),
    }
  },
  watch: {
    value: {
      handler (value) {
        this.displayValue = this.formatOption(value)
      },
      immediate: true,
    },
  },
  computed: {
    isClearable () {
      return this.clearable && (this.value != null || (this.searchable && this.displayValue !== ''))
    },
    computedPlaceholder () {
      return this.searchable ? this.placeholder : undefined
    },
  },
  methods: {
    onDropdownClose () {
      this.displayValue = this.formatOption(this.value)
    },
    onSearch (v) {
      this.$emit('search', v)
    },
    toggleSelection (option) {
      this.selectOption(option)
    },
    clear () {
      if (this.searchable) {
        this.displayValue = ''
      }
      this.onSearch('')
      this.$emit('input', null)
    },
    showDropdown () {
      if (this.searchable) {
        this.displayValue = ''
      }
      this.onSearch('')
    },
    isOptionSelected (option) {
      if (this.optionKey && option) {
        return this.value[this.optionKey] === option[this.optionKey]
      } else {
        return this.value === option
      }
    },
    formatOption (option) {
      if (typeof this.formatter === 'function') {
        return this.formatter(option)
      } else if (option && this.optionKey) {
        return option[this.optionKey]
      } else if (option) {
        return option
      } else {
        return ''
      }
    },
    selectOption (option) {
      this.$emit('input', option)
    },
    validate () {
      this.validated = true
    },
    isValid () {
      let isValid = true
      if (this.required) {
        isValid = !!this.value
      }
      return isValid
    },
    hasErrors () {
      let hasErrors = false
      if (this.required) {
        hasErrors = this.validated && !this.value
      }
      return hasErrors
    },
    showRequiredError () {
      return `The ${this.name} field is required`
    },
  },
}
</script>

<style lang="scss">
@import "../../vuestic-sass/resources/resources";

.va-simple-select {

  &__unselect {
    margin-right: 20px;
    cursor: pointer;
  }

  .va-simple-select__dropdown-arrow.va-simple-select__dropdown-arrow {
    top: 12px;
    cursor: pointer;
  }

  &__dropdown-menu {
    padding: 0;

    .va-scrollbar {
      max-height: $dropdown-item-height * 4;
    }
  }
}
</style>
