<template>
  <div class="tree-view sets-list va-row">
    <div class="flex md12 xs12">
      <div class="va-row">

        <div class="small-set flex lg6 xs12">
          <va-card :title="$t('treeView.basic')">
            <tree-view-basic-preview/>
          </va-card>
        </div>

        <div class="small-set flex lg6 xs12">
          <va-card :title="$t('treeView.icons')">
            <tree-view-icons-preview/>
          </va-card>
        </div>
      </div>

      <div class="va-row">
        <div class="small-set flex lg6 xs12">
          <va-card :title="$t('treeView.advanced')">
            <tree-view-advanced-preview/>
          </va-card>
        </div>

        <div class="small-set flex lg6 xs12">
          <va-card :title="$t('treeView.editable')">
            <tree-view-editable-preview/>
          </va-card>
        </div>
      </div>
      <div class="small-set flex lg6 xs12">
        <va-card :title="$t('treeView.selectable')">
          <tree-view-selectable-preview/>
        </va-card>
      </div>

    </div>
  </div>
</template>

<script>
import VaCard from '../../../vuestic-theme/vuestic-components/va-card/VaCard'
import TreeViewBasicPreview from './TreeViewBasicPreview'
import TreeViewIconsPreview from './TreeViewIconsPreview'
import TreeViewSelectablePreview from './TreeViewSelectablePreview'
import TreeViewAdvancedPreview from './TreeViewAdvancedPreview'
import TreeViewEditablePreview from './TreeViewEditablePreview'

export default {
  name: 'tree-view',
  components: {
    TreeViewEditablePreview,
    TreeViewAdvancedPreview,
    TreeViewSelectablePreview,
    TreeViewIconsPreview,
    TreeViewBasicPreview,
    VaCard,
  },
  data () {
    return {
      treeViewData: {},
    }
  },
}
</script>
