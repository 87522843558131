<template>
  <svg class="va-icon-free" xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 44.99 51.04">
    <defs></defs>
    <title>overview_icon_2</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1"
              d="M1.08,28.21C1.08,13.62,8.38,6.29,19,6.29S37,13.69,37,28.21,29.66,50.54,19,50.54,1.08,42.8,1.08,28.21Zm23.56,0c0-11.3-2.58-16.9-5.62-16.9s-5.62,5.6-5.62,16.9S16,41.66,19,41.66,24.65,39.51,24.65,28.21Z"/>
        <line class="cls-2" x1="39.83" y1="47.62" x2="39.83" y2="50.96"/>
        <path class="cls-3"
              d="M18.73,9.64c-4.9,0-6.9,4.54-6.9,15.66,0,11.29,2.06,16.1,6.9,16.1s6.9-4.81,6.9-16.1C25.63,14.17,23.63,9.64,18.73,9.64Zm0,28.76c-1.07,0-3.9,0-3.9-13.1,0-12.66,2.64-12.66,3.9-12.66s3.9,0,3.9,12.66C22.63,38.4,19.8,38.4,18.73,38.4Z"/>
        <path class="cls-3"
              d="M42.9,43.74A3.76,3.76,0,0,1,40.17,45c-1.95,0-3.24-1.57-3.24-4.4s1.53-4.35,3.29-4.35a3.67,3.67,0,0,1,2.5,1.11l2.08-2.55A6.8,6.8,0,0,0,41.33,33V31h-3v2.12a7.09,7.09,0,0,0-1.64.63,43.71,43.71,0,0,0,.77-8.41c0-15.84-7-25.3-18.73-25.3S0,9.46,0,25.3,7.18,51,18.73,51A16.4,16.4,0,0,0,33.12,43.1,6.77,6.77,0,0,0,40,48.46a6.35,6.35,0,0,0,5-2.22ZM18.73,48C8.88,48,3,39.54,3,25.3S8.73,3,18.73,3s15.73,8.13,15.73,22.3S28.58,48,18.73,48Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'va-icon-free',
}
</script>

<style lang="scss">
.va-icon-free {
  display: inline-block;
  width: 55px;
  height: 47.8px;

  .cls-1 {
    fill: #4ae387;
  }

  .cls-2 {
    fill: none;
    stroke: #34495e;
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }

  .cls-3 {
    fill: #34495e;
  }
}
</style>
