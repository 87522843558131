import Vue from 'vue';
import VueResource from 'vue-resource';
import Cookies from 'js-cookie';
import store from '../store/index';

Vue.use(VueResource);

Vue.http.options.credentials = true;
Object.defineProperty(Vue.http.options, 'root', {
  get: () => Cookies.get('apibase') || store.getters.api.host + store.getters.api.base,
  enumerable: true,
  configurable: true,
});
