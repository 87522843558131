import { get } from 'lodash';

export default class ApiError {
  statusCode;
  message;
  name;
  body;

  constructor (message, statusCode = 400, body = undefined) {
    if (arguments.length === 1 && typeof message === 'number') {
      statusCode = message;
      message = this.constructor.name;
    }
    this.message = message;
    Object.defineProperty(this, 'stack', {
      enumerable: true,
      configurable: true,
      writable: true,
    });
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    this.body = body;
  }

  static wrap (err) {
    if (err instanceof ApiError) {
      return err;
    } else if (err instanceof Error) {
      const out = new ApiError(err.message, null);
      out.stack = err.stack;
      out.name = err.name;
      Object.assign(out, err);
      return out;
    } else if (get(err, 'statusText')) {
      const out = new ApiError(
        (err.data && err.data.message) || err.statusText,
        err.status);
      out.response = err;
      Object.assign(out, err.data);
      return out;
    } else {
      const msg = (err && (err.message || err.msg)) || err;
      return new ApiError(msg, null);
    }
  }
}
