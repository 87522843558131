<template>
  <div>
    <div class="va-row flex">
      <va-card :title="$t('schema.m_profiles')">
        <va-data-table
          ref="table"
          class="row-clickable"
          :apiUrl="crudURL.replace('{id}', '')"
          :httpFetch="tableAdapter.httpFetch"
          :transform="tableAdapter.transform"
          :tableFields="tableFieldsComputed"
          :itemsPerPage="itemsPerPage"
          apiMode
          :appendParams="appendParamsComputed"
          :queryParams="tableAdapter.queryParams"
          :filterQuery="tableAdapter.filterQuery"
          :sortOrder="sortOrder"
          :vuetableOptions="tableAdapter.vuetableOptions"
          @vuetable:row-clicked="onRowClicked"
          @vuetable:field-event="onFieldEvent"
        >
          <template slot="additionalTableControl">
            <va-toggle
              :label="$t('d.showDisabled')"
              v-model="appendFilter.enabled"
              :trueValue="ne"
              :falseValue="true"
              small
              style="margin-bottom: 0"
            />
            <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
            <slot name="additionalTableControl">
              <va-button @click="doEdit(null)">
                {{$t('p.create$obj', {obj: $t('schema.m_profile')})}}
              </va-button>
            </slot>
          </template>
          <template slot="detailRowComponent" slot-scope="scope">
            <slot name="detailRowComponent" v-bind="scope">
              <DetailRow
                v-bind="scope"
                @update="reload"
                @close="hideDetailRow"
                @delete="reload"
              />
            </slot>
          </template>
          <template v-slot:avatar="{rowData}">
            <ProfileAvatar :profile="rowData" size="40px"/>
          </template>
        </va-data-table>
      </va-card>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="editId"
      @create="x => editId = x._id"
      @update="reload"
      @delete="reload"
    />
  </div>
</template>

<script>
  import ProfileAvatar from 'components/membership/ProfileAvatar';
  import ApiError from 'services/ApiError';
  import EditDialog from './ProfileEditDialog';
  import DetailRow from './ProfileDetailRow.vue';
  import ActionsColumn, { actions } from 'components/tables/ActionsColumn';
  import vuetableMixin from 'services/vuetableMixin';
  import { merge, cloneDeep } from 'lodash';
  import { sid, date, datetime, status } from 'services/tableColumnTemplates';

  export default {
    name: 'Profiles',
    mixins: [vuetableMixin],
    props: {
      crudURL: {
        type: String,
        default: 'profile/{id}',
      },
    },
    components: {
      ProfileAvatar,
      EditDialog,
      DetailRow,
    },
    data () {
      return {
        ne: { $ne: true },
        appendFilter: {
          enabled: true,
        },
      };
    },
    computed: {
      appendParamsComputed () {
        return merge({
          filter: cloneDeep(this.appendFilter),
        }, this.appendParams);
      },
      tableFields () {
        return [
          {
            name: 'avatar',
            title: '',
          },
          sid(),
          {
            name: 'name',
            title: this.$t('f.displayName'),
            sortField: 'name',
          },
          {
            name: 'role',
            title: this.$t('f.role'),
            formatter: x => this.$t('roles.' + x),
          },
          status(),
          {
            name: 'created_at',
            title: this.$t('f.created_at'),
            sortField: 'created_at',
            ...date(true),
          },
          {
            name: 'updated_at',
            title: this.$t('f.updated_at'),
            sortField: 'updated_at',
            ...datetime(true),
          },
        ];
      },
      actionsColumn () {
        return {
          name: ActionsColumn,
          actions ({ data }) {
            return [
              actions.view.call(this),
              actions.edit.call(this),
              data.enabled
                ? actions.disable.call(this)
                : actions.enable.call(this),
              !data.enabled ? actions.delete.call(this) : null,
            ].filter(x => x);
          },
        };
      },
    },
    methods: {
      async onFieldEvent (type, payload) {
        if (this.fieldEvent === false) {
          return;
        } else if (typeof this.fieldEvent === 'function') {
          if (await this.fieldEvent.apply(this, arguments) === false) {
            return;
          }
        }
        if (type === 'action') {
          const { action, data } = payload;
          switch (action.key) {
            case 'delete':
              this.promptDelete(data._id);
              break;
            case 'edit':
              this.doEdit(data._id);
              break;
            case 'view':
              this.$router.push({ name: 'm_profile', params: { id: data._id } });
              break;
            case 'disable':
              if (!confirm(this.$t('p.promptDisable'))) {
                break;
              }
              this.promptUpdate({ enabled: false }, data._id);
              break;
            case 'enable':
              if (!confirm(this.$t('p.promptEnable'))) {
                break;
              }
              this.promptUpdate({ enabled: true }, data._id);
              break;
          }
        }
      },
      async doDelete (id) {
        let cascade;
        while (true) {
          try {
            await this.$http.delete(this.crudURL, { params: { id: id, cascade } });
            return true;
          } catch (err) {
            const ae = ApiError.wrap(err);
            if (ae.code === 'dangerous_operation') {
              if (confirm(this.$t('p.promptForceDeleteProfile'))) {
                cascade = 1;
                continue;
              }
            }
            throw ae;
          }
        }
      },
    },
  };
</script>
