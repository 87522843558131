<template>
  <svg class="va-icon-vue" xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 55.05 47.8">
    <defs></defs>
    <title>overview_icon_1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon class="cls-1"
                 points="27.75 21.19 18.48 6.18 4.78 6.18 27.75 42.92 50.89 6.18 36.13 6.18 27.75 21.19"/>
        <path class="cls-2"
              d="M33.08,0,27.44,9.76,21.84,0H0L27.43,47.8,55,0ZM27.43,15.77,34.81,3h4.6l-12,20.72L15.55,3h4.55ZM5.18,3h6.91L27.43,29.73,42.88,3h7L27.44,41.78Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'va-icon-vue',
}
</script>

<style lang="scss">
.va-icon-vue {
  display: inline-block;
  width: 55px;
  height: 47.8px;

  .cls-1 {
    fill: #4ae387;
  }

  .cls-2 {
    fill: #34495e;
  }
}
</style>
