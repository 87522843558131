<template>
  <va-navbar>
    <header-selector slot="selector" :isOpen.sync="valueProxy"/>
    <span slot="logo" style="line-height: 100%; display: block">
<!--      <va-icon-vuestic/>-->
      <linklink-icon style="font-size: 1.5em;"/>
    </span>
    <span slot="center">
<!--      {{$t('navbar.messageUs')}}&nbsp;<a href="mailto:hello@epicmax.co">hello@epicmax.co</a>-->
      <span :title="env.COMMITHASH">
        {{$t('navbar.version', {version: 'g' + env.VERSION})}}@{{env.BRANCH}}
      </span>
      ({{env.TIME | moment('YYYY/MM/DD HH:mm:ss')}})
    </span>

    <message-dropdown class="nav-item"/>
    <notification-dropdown class="nav-item"/>
    <language-dropdown class="nav-item"/>
    <profile-dropdown class="nav-item">
      <ProfileAvatar :profile="profile" :user="user" size="50px"/>
      <template slot="dropdown">
        <div class="dropdown-item plain-link-item">
          <router-link :to="{name: 'myself'}" class="plain-link">
            {{ $t(`user.profile`) }}
          </router-link>
        </div>
        <div class="dropdown-item plain-link-item" v-if="hasMultiProfiles">
          <a :href="switchProfileHref" class="plain-link">
            {{ $t(`user.switchProfile`) }}
          </a>
        </div>
        <div class="dropdown-item plain-link-item">
          <router-link :to="{name: 'logout'}" class="plain-link">
            {{ $t(`user.logout`) }}
          </router-link>
        </div>
      </template>
    </profile-dropdown>
  </va-navbar>

</template>

<script>
  import ProfileAvatar from 'components/membership/ProfileAvatar';
  import { get } from 'lodash';
  import { mapGetters, mapState } from 'vuex';
  import LinklinkIcon from 'components/admin/linklink-icon';
  import VaIconVuestic
    from '../../../vuestic-theme/vuestic-components/va-icon/va-iconset/VaIconVuestic';
  import VaNavbar
    from '../../../vuestic-theme/vuestic-components/va-navbar/VaNavbar';
  import HeaderSelector from './components/HeaderSelector';
  import LanguageDropdown from './components/dropdowns/LanguageDropdown';
  import ProfileDropdown from './components/dropdowns/ProfileDropdown';
  import NotificationDropdown from './components/dropdowns/NotificationDropdown';
  import MessageDropdown from './components/dropdowns/MessageDropdown';

  export default {
    name: 'app-navbar',
    components: {
      ProfileAvatar,
      LinklinkIcon,
      VaIconVuestic,
      VaNavbar,
      HeaderSelector,
      MessageDropdown,
      NotificationDropdown,
      LanguageDropdown,
      ProfileDropdown,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      ...mapGetters([
        'api',
        'user',
        'profile',
      ]),
      ...mapState({
        allowedRoles: s => s.auth.allowedRoles,
      }),
      valueProxy: {
        get () {
          return this.isOpen;
        },
        set (opened) {
          this.$emit('toggle-menu', opened);
        },
      },
      env: () => process.env,
      hasMultiProfiles () {
        return get(this.user, 'profiles.length') > 1;
      },
      switchProfileHref () {
        let q = new URLSearchParams();
        q.append('switch', '1');
        q.append('role', this.allowedRoles.join(','));
        q.append('redirect', window.location.href);
        return this.api.host + this.api.sso + '?' + q.toString();
      },
    },
  };
</script>
