<template>
  <div class="content va-layout fluid gutter--md">
    <div class="header va-row wrap">
      <div class="title display-4 grow">{{title}}</div>
      <div class="actions">
        <va-checkbox
          v-if="model"
          :label="$t('f.enabled')"
          :value="model.enabled"
          @input="toggleEnabled"
          class="d-inline-flex"
        />
        <va-button small color="gray" flat @click="doClose()">{{$t('a.close')}}</va-button>
        <va-button small color="primary" @click="promptUpdate()">{{$t('a.update')}}</va-button>
        <va-button small color="primary" @click="doCreateSharedLink" v-if="model && model.enabled">
          {{ $t('p.create$obj', { obj: $t('schema.ll_sharedlink') }) }}
        </va-button>
      </div>
    </div>
    <hr/>
    <ValidationObserver tag="div" class="va-row" ref="form">
      <template v-if="model">
        <div class="flex md3">
          <ValidationProvider
            v-slot="{errors, valid}"
            :rules="{required: true, url: {require_protocol: true}}">
            <va-input
              v-model="model.href"
              :label="$t('f.targetLink')"
              placeholder="http://"
              :error="!valid"
              :error-messages="errors"
            />
          </ValidationProvider>
          <va-input
            v-model="model.title"
            :label="$t('f.seoTitle')"
          />
          <va-input
            v-model="model.description"
            type="textarea"
            :label="$t('f.seoDescription')"
          />
        </div>
        <div class="flex md3">
          <va-multi-select
            v-model="model.tags"
            :label="$t('f.tags')"
            :options="['food', 'beauty', 'gym', 'video', 'travel']"
          />
          <va-input
            v-model="model.remarks"
            :label="$t('f.remarks')"
            type="textarea"
          />
        </div>
        <div class="flex md3">
          <va-simple-select
            v-model="model.status"
            :label="$t('f.status')"
            :clearable="false"
            required
            :options="['0$requested', '1$accepted', '99$rejected']"
            :formatter="statusFilter"
          />
        </div>
      </template>
    </ValidationObserver>
  </div>
</template>

<script>
  import detailRowMixin from 'src/services/detailRowMixin';
  import { status } from 'src/services/filters';

  export default {
    name: 'SourceLinkDetailRow',
    mixins: [detailRowMixin],
    data () {
      return {
        crudURL: 'll/sourcelink/{id}',
      };
    },
    computed: {
      title () {
        return this.$t('d.quickView');
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.model = (await this.$http.get(this.crudURL,
          { params: { id: this.id } })).data;
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      statusFilter: status,
      async toggleEnabled (v) {
        if (!confirm(this.$t(v ? 'p.promptEnable' : 'p.promptDisable'))) {
          return;
        }
        if (await this.promptUpdate({ enabled: v })) {
          this.model.enabled = v;
        }
      },
      async doCreateSharedLink () {
        if (this.id) {
          const data = (await this.$http.post('ll/sharedlink', {
            sourcelink: this.id,
          })).data;
          this.doClose();
          this.$router.push({ name: 'll_sharedlink', params: { id: data._id } });
        }
      },
    },
  };
</script>
