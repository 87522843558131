<template>
  <div class="language-dropdown flex-center grow">
    <va-icon :icon="['flag-icon flag-icon-large', flagIconClass(currentLanguage())]"/>
    <va-dropdown-old
      class="language-dropdown__container"
      v-model="isShown"
      position="bottom"
    >
      <a class="dropdown-item"
         v-for="(option, id) in options"
         :key="id"
         :class="{ active: option.code === currentLanguage() }"
         @click="setLanguage(option.code)"
      >
        <va-icon :icon="['flag-icon flag-icon-small', flagIconClass(option.code)]"/>
        <span class="dropdown-item__text ellipsis">
          {{ $t(`language.${option.name}`) }}
        </span>
      </a>
    </va-dropdown-old>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'language-dropdown',
  data () {
    return {
      isShown: false,
    }
  },
  props: {
    options: {
      type: Array,
      default: () => [
        {
          code: 'en',
          name: 'english',
          flag: 'gb',
        },
        // {
        //   code: 'es',
        //   name: 'spanish',
        // },
        // {
        //   code: 'br',
        //   name: 'brazilian_portuguese',
        // },
        // {
        //   code: 'cn',
        //   name: 'simplified_chinese',
        // },
        {
          code: 'zh',
          name: 'traditional_chinese',
          flag: 'hk',
        },
      ],
    },
  },
  methods: {
    setLanguage (locale) {
      Vue.i18n.set(locale)
    },

    currentLanguage () {
      return Vue.i18n.locale()
    },

    flagIconClass (code) {
      const x = this.options.find(x => x.code === code);
      return x ? `flag-icon-${x.flag || x.code}` : '';
    },
  },
}
</script>

<style lang="scss">
@import '../../../../../vuestic-theme/vuestic-sass/resources/resources';
$flag-icon-css-path: '~flag-icon-css/flags';
@import "~flag-icon-css/sass/variables";
@import "~flag-icon-css/sass/flag-icon-base";
@include flag-icon(gb);
@include flag-icon(hk);

.language-dropdown {
  cursor: pointer;

  .flag-icon-large {
    display: block;
    width: 31px;
    height: 23px;
  }

  @at-root {
    &__container {
      .flag-icon-small {
        min-width: 22px;
        height: 17px;
        margin-right: 12px;
      }
    }
  }
}
</style>
