<template>
  <paypalButtons v-bind="$props"/>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'PayPalSmartPayment',
    components: {
      paypalButtons: async () => {
        const s = document.createElement('script');
        const q = new URLSearchParams();
        q.append('client-id',
          'ATXzXjGdMsbMeSqEnvL0fT2izVswJ8M7_Cxu18s-WtcrDSpB9xZK04o2AYTiZGEOTIL07FkNcqCs2zcm');
        q.append('debug', true);
        q.append('currency', 'HKD');
        s.src = 'https://www.paypal.com/sdk/js?' + q.toString();
        document.head.appendChild(s);
        while (!window.paypal) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        return window.paypal.Buttons.driver('vue', Vue);
      },
    },
    props: {
      createOrder: { type: Function },
      onApprove: { type: Function },
      onCancel: { type: Function },
      onError: { type: Function },
    },
  };
</script>
