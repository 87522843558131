<template>
  <button class="dropdown-close-button" @click="$closeDropdown()">
    Close dropdown
  </button>
</template>

<script>
export default {
  name: 'dropdown-close-button',
}
</script>

<style lang="scss">
.dropdown-close-button- {

}
</style>
