import ApiError from 'services/ApiError';
import { mapGetters } from 'vuex';
import { getOid } from 'services/utils';
import { identity } from 'lodash';

export default {
  computed: {
    ...mapGetters([
      'api',
    ]),
  },
  methods: {
    handleError (err) {
      const ae = ApiError.wrap(err);
      alert(ae.message);
    },
    async promptUpdate (patch, id = this.id) {
      try {
        const x = await this.doUpdate(patch, id);
        this.showToast(this.$t('p.updated'));
        return x;
      } catch (err) {
        this.handleError(err);
      }
    },
    async doUpdate (patch, id) {
      return (await this.$http.patch(this.crudURL, patch,
        { params: { id } })).data;
    },
    async promptDelete (id = this.id) {
      if (!confirm(this.$t('p.promptDelete'))) {
        return;
      }
      try {
        const x = await this.doDelete(id);
        this.showToast(this.$t('p.deleted'));
        return x;
      } catch (err) {
        this.handleError(err);
      }
    },
    async doDelete (id) {
      await this.$http.delete(this.crudURL, { params: { id } });
      return true;
    },
    attnDownloadHref (id) {
      id = getOid(id);
      return `${this.api.host}${this.api.base}/attachment/${id}/download`;
    },
    attnThumbHref (id, size) {
      id = getOid(id);
      size = size ? '?size=' + size : '';
      return `${this.api.host}${this.api.base}/attachment/${id}/thumbnail/${size}`;
    },
    async attnUpload (files, fields) {
      const d = new FormData();
      if (fields) {
        for (const [k, v] of Object.entries(fields)) {
          d.append(k, v);
        }
      }
      if (Array.isArray(files)) {
        for (const f of files) {
          d.append('files', f);
        }
      } else {
        d.append('file', files);
      }
      const { data } = await this.$http.post('attachment/upload', d);
      return this.attnConvert(data);
    },
    async attnDelete (files) {
      for (const x of [].concat(files)) {
        if (x) {
          await this.$http.delete('attachment/{id}',
            { params: { id: getOid(x) } });
        }
      }
      return true;
    },
    attnConvert (file) {
      if (Array.isArray(file)) {
        return file.map(this.attnConvert).filter(identity);
      }
      if (!file) {
        return null;
      }
      if (typeof file === 'string') {
        return { image: `${this.api.host}/attachment/${file}/thumbnail` };
      } else if (file._id) {
        file.image = `${this.api.host}/attachment/${file._id}/thumbnail`;
        if (!file.name) {
          file.name = file.filename;
        }
      }
      return file;
    },
  },
};
