<template>
  <va-avatar ref="content" :size="size" :src="avatar"/>
</template>

<script>
  import { get } from 'lodash';
  import VaAvatar from 'components/VaAvatar';
  import crudMixin from 'services/crudMixin';
  import { letterAvatar } from 'services/utils';

  export default {
    name: 'ProfileAvatar',
    mixins: [crudMixin],
    components: {
      VaAvatar,
    },
    props: {
      profile: {
        type: Object,
      },
      user: {
        type: Object,
      },
      size: {},
    },
    data () {
      return {
        bestSize: null,
      };
    },
    watch: {
      size: {
        immediate: true,
        async handler () {
          this.bestSize = await this.calculateBestSize();
        },
      },
    },
    computed: {
      avatar () {
        const id = get(this.profile, 'avatar');
        if (id) {
          if (this.bestSize) {
            return this.attnThumbHref(id, this.bestSize);
          }
        } else {
          return 'data:image/svg+xml;base64,' +
            btoa(letterAvatar(get(this.profile, 'name') || get(this.user, 'username')));
        }
      },
    },
    methods: {
      async calculateBestSize () {
        await this.$nextTick();
        const content = get(this.$refs, 'content.$el');
        if (!content) {
          return '64';
        }
        const sizes = {
          '64': 64,
          '128': 128,
          '256': 256,
          '512': 512,
          hd: 1280,
          fhd: 1920,
          '2k': 2048,
        };
        let k;
        let s;
        for ([k, s] of Object.entries(sizes)) {
          if (s >= Math.max(content.scrollWidth - 4, content.scrollHeight - 4)) {
            break;
          }
        }
        return k;
      },
    },
  };
</script>
