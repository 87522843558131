<template>
  <ValidationObserver
    tag="form" ref="form"
    class="signup" @submit.prevent="doRegister()"
  >
    <va-simple-select
      v-model="model.role"
      :label="$t('f.role')"
      :clearable="false"
      :options="['linkConsumer', 'business']"
      :formatter="x => $t('roles.' + x)"
    />
    <ValidationProvider
      v-slot="{errors, valid}" :name="$t('f.username')" mode="lazy"
      rules="required"
    >
      <va-input
        v-model="model.username"
        :label="$t('f.username')"
        :error="valid === false"
        :error-messages="errors"
        :messages="[$t('d.optional')]"
      />
    </ValidationProvider>
    <ValidationProvider v-slot="{errors, valid}" :name="$t('f.displayName')" mode="lazy">
      <va-input
        v-model="model.name"
        :label="$t('f.displayName')"
        :error="valid === false"
        :error-messages="errors"
        :messages="[$t('d.optional')]"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{errors, valid}" :name="$t('f.email')" mode="lazy"
      rules="required|email"
    >
      <va-input
        v-model="model.email"
        type="email"
        :label="$t('f.email')"
        :error="valid === false"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      vid="phone" v-slot="{errors, valid}" :name="$t('f.phone')" mode="lazy"
      rules="regex:^\s*\+?[0-9\-\s()]+$"
    >
      <va-input
        v-model="phone"
        :label="$t('f.phone')"
        :error="valid === false"
        :error-messages="errors"
        :messages="[$t('d.optional')]"
      >
        <template slot="appendText">
          <va-button
            v-if="canSendSms"
            :disabled="!valid"
            @click.stop="promptRequestSMSCode()"
            small
          >
            {{$t('a.getVerifyCode')}}
          </va-button>
          <va-button v-else disabled small>
            {{$t('d.resendSms', {time: sms.waitUntil.diff(now, 'seconds')})}}
          </va-button>
        </template>
      </va-input>
    </ValidationProvider>
    <ValidationProvider
      key="verifyCode"
      v-if="phone"
      v-slot="{errors, valid}" :name="$t('f.verifyCode')" mode="lazy"
      rules="required"
    >
      <va-input
        v-model="sms.code"
        :label="$t('f.verifyCode')"
        :error="valid === false"
        :error-messages="errors"
      >
        <template slot="prependText" v-if="sms.nonce">
          <span style="padding: .25em 0 .1em .5em;">({{sms.nonce}})</span>
        </template>
      </va-input>
    </ValidationProvider>
    <ValidationProvider
      vid="pw" v-slot="{errors, valid}" :name="$t('auth.password')" mode="lazy"
      rules="required"
    >
      <va-input
        v-model="model.pw"
        type="password"
        :label="$t('auth.password')"
        :error="valid === false"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{errors, valid}" :name="$t('auth.confirmPassword')" mode="lazy"
      rules="required|confirmed:pw"
    >
      <va-input
        v-model="pw1"
        type="password"
        :label="$t('auth.confirmPassword')"
        :error="valid === false"
        :error-messages="errors"
      />
    </ValidationProvider>
    <div class="flex-center mt-4">
      <va-button type="submit" class="my-0">
        {{ $t('auth.signUp') }}
      </va-button>
    </div>
    <div class="flex-center" v-if="err">
      <div class="text-danger">{{err}}</div>
    </div>
  </ValidationObserver>
</template>

<script>
  import moment from 'moment';
  import ApiError from 'services/ApiError';

  export default {
    name: 'signup',
    data () {
      return {
        err: null,
        now: moment(),
        tick: null,
        model: {
          role: 'business',
          username: '',
          name: '',
          email: '',
          phone: null,
          pw: '',
        },
        pw1: '',
        phone: '',
        sms: {
          code: null,
          nonce: null,
          token_template: null,
          waitUntil: null,
        },
      };
    },
    mounted () {
      this.tick = setInterval(() => {
        this.now = moment();
      }, 1000);
    },
    beforeDestroy () {
      clearInterval(this.tick);
    },
    computed: {
      canSendSms () {
        return !this.sms.waitUntil || this.now >= this.sms.waitUntil;
      },
    },
    methods: {
      async doRegister () {
        try {
          this.err = null;
          if (!await this.$refs.form.validate()) {
            return;
          }
          await this.$http.post('register', {
            ...this.model,
            phone: this.sms.token_template ? {
              token: this.sms.token_template.replace('{code}', this.sms.code),
            } : undefined,
          });
          this.showToast(this.$t('p.registerSuccess'));
          this.$router.push('/');
        } catch (err) {
          console.debug(err);
          const ae = ApiError.wrap(err);
          this.err = ae.message;
        }
      },
      async promptRequestSMSCode () {
        try {
          this.err = null;
          if (this.phone[0] !== '+') {
            this.phone = '+852' + this.phone;
          }
          // refresh ui
          await new Promise(resolve => setTimeout(resolve));
          if (!confirm(this.$t('p.confirmSendSms', { phone: this.phone }))) {
            return;
          }
          this.sms.code = null;
          const res = (await this.$http.post('requestSmsCode', { phone: this.phone })).data;
          this.phone = res.phone;
          this.sms.nonce = res.nonce;
          this.sms.token_template = res.accessToken.token_template;
          this.sms.waitUntil = moment(res.waitUntil);
        } catch (err) {
          console.debug(err);
          const ae = ApiError.wrap(err);
          this.err = ae.message;
        }
      },
    },
  };
</script>
