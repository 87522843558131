<template>
  <va-modal
    v-model="valueProxy"
    :title="title"
    noOutsideDismiss
    hideDefaultActions
    size="large"
  >
    <template slot="actions">
      <va-button flat color="gray" @click="doClose()">{{$t('a.close')}}</va-button>
      <va-button color="primary" @click="promptUpdate()" v-if="id">{{$t('a.update')}}</va-button>
    </template>
    <ValidationObserver tag="div" ref="form">
      <div v-if="model">
        <div v-if="!isCreate" class="va-row">
          <objectid :value="model.sid" tag="p"/>
        </div>
        <div class="va-row">
          <div class="flex md12">
            <va-input
              v-model="model.username"
              :label="$t('f.username')"
            />
            <va-input-wrapper
              :messages="[$t('d.languagePreferenceHint')]"
            >
              <va-simple-select
                v-model="model.preference.language"
                :label="$t('f.language')"
                :clearable="false"
                :options="['zh', 'en']"
                :formatter="languageMap"
              />
            </va-input-wrapper>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </va-modal>
</template>

<script>
  import Objectid from 'components/objectid';
  import editDialogMixin from 'src/services/editDialogMixin';
  import { create } from 'src/services/schema/User';

  export default {
    name: 'MyselfEditDialog',
    components: { Objectid },
    mixins: [editDialogMixin],
    props: {
      id: { default: 'me' },
    },
    data () {
      return {
        crudURL: 'user/{id}',
      };
    },
    computed: {
      title () {
        return this.$t('user.profile');
      },
    },
    methods: {
      async reload () {
        this.model = create((await this.$http.get(this.crudURL,
          { params: { id: this.id } })).data);
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      languageMap (locale) {
        const map = {
          zh: 'traditional_chinese',
          'zh-hk': 'traditional_chinese',
          'zh-cn': 'simplified_chinese',
          en: 'english',
        };
        if (map[locale]) {
          return this.$t('language.' + map[locale]);
        } else {
          return locale;
        }
      },
    },
  };
</script>
