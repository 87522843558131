<template>
  <va-modal
    v-model="valueProxy"
    :title="title"
    noOutsideDismiss
    hideDefaultActions
    size="large"
  >
    <template slot="actions">
      <va-button flat color="gray" @click="doClose()">{{$t('a.close')}}</va-button>
      <va-button color="primary" @click="promptCreate()" v-if="isCreate">{{$t('a.create')}}
      </va-button>
      <va-button color="primary" @click="promptUpdate()" v-if="!isCreate">{{$t('a.update')}}
      </va-button>
      <!--      <va-button color="danger" @click="promptDelete()" v-if="id != null">{{$t('a.delete')}}</va-button>-->
    </template>
    <ValidationObserver tag="div" ref="form">
      <div v-if="model">
        <div v-if="!isCreate" class="va-row">
          <objectid :value="model.sid" tag="p"/>
        </div>
        <div class="va-row">
          <div class="flex md12" v-if="isCreate">
            <ValidationProvider
              v-slot="{errors, valid}"
              :name="$t('f.role')"
              rules="required"
              mode="eager"
            >
              <va-input-wrapper
                :error="!valid"
                :error-messages="errors"
              >
                <va-simple-select
                  v-model="model.role"
                  :label="$t('f.role')"
                  :clearable="false"
                  :options="['admin', 'linkConsumer', 'business']"
                  :formatter="x => $t('roles.' + x)"
                />
              </va-input-wrapper>
            </ValidationProvider>
          </div>
          <div class="flex md12">
            <va-input
              v-model="model.name"
              :label="$t('f.displayName')"
            />
          </div>
          <div class="flex md12">
            <ValidationProvider
              v-slot="{errors, valid}"
              :name="$t('f.status')"
              rules="required"
              mode="eager"
            >
              <va-input-wrapper
                :error="!valid"
                :error-messages="errors"
              >
                <va-simple-select
                  v-model="model.status"
                  :label="$t('f.status')"
                  :clearable="false"
                  :options="['0$requested', '1$accepted', '99$rejected']"
                  :formatter="statusFilter"
                />
              </va-input-wrapper>
            </ValidationProvider>
          </div>
          <div class="flex md12" v-if="!isCreate">
            <p class="display-5">{{$t('f.avatar')}}</p>
            <p>
              <va-file-upload
                type="single"
                :value="attnConvert([model.avatar])"
                @upload="uploadImage"
                @remove="removeImage"
              />
            </p>
            <img
              v-if="model.avatar"
              style="max-width: 256px;"
              :src="attnThumbHref(model.avatar, 256)"
            />
          </div>
        </div>
      </div>
    </ValidationObserver>
  </va-modal>
</template>

<script>
  import Objectid from 'components/objectid';
  import ApiError from 'services/ApiError';
  import editDialogMixin from 'src/services/editDialogMixin';
  import { create } from 'src/services/schema/Profile';
  import { status } from 'src/services/filters';
  import VaInputWrapper from 'vuestic-components/va-input/VaInputWrapper';

  export default {
    name: 'ProfileEditDialog',
    components: { VaInputWrapper, Objectid },
    mixins: [editDialogMixin],
    props: {
      crudURL: {
        type: String,
        default: 'profile/{id}',
      },
    },
    data () {
      return {};
    },
    computed: {
      title () {
        return this.$t('schema.m_profile');
      },
    },
    methods: {
      async reload () {
        this.model = this.id != null ? (await this.$http.get(this.crudURL,
          { params: { id: this.id, populate: 'avatar' } })).data : create();
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      async promptCreate () {
        if (!await this.$refs.form.validate()) {
          return;
        }
        try {
          const model = (await this.$http.post(this.crudURL, this.model,
            { params: { id: null } })).data;
          this.showToast(this.$t('p.created'));
          this.$emit('create', model);
          return model;
        } catch (err) {
          this.handleError(err);
        }
      },
      async uploadImage (files) {
        if (!files[0]) return;
        await this.attnDelete(this.model.avatar);
        const attn = await this.attnUpload(files[0]);
        this.$set(this.model, 'avatar', attn);
        return this.promptUpdate({ avatar: attn });
      },
      async removeImage () {
        await this.attnDelete(this.model.avatar);
        this.$set(this.model, 'avatar', null);
        return this.promptUpdate({ avatar: null });
      },
      statusFilter: status,
      async doDelete () {
        let cascade;
        while (true) {
          try {
            await this.$http.delete(this.crudURL, { params: { id: this.id, cascade } });
            return true;
          } catch (err) {
            const ae = ApiError.wrap(err);
            if (ae.code === 'dangerous_operation') {
              if (confirm(this.$t('p.promptForceDeleteProfile'))) {
                cascade = 1;
                continue;
              }
            }
            throw ae;
          }
        }
      },
    },
  };
</script>
