import colors from 'services/colors.json';

export const normalizeValue = (value, minValue = 0, maxValue = 100) => {
  if (value <= minValue) {
    return minValue;
  }

  if (value >= maxValue) {
    return maxValue;
  }

  return value;
};

export function copyText (el) {
  if (document.selection) { // IE
    let range = document.body.createTextRange();
    range.moveToElementText(el);
    range.select();
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(el);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
  document.execCommand('copy');
}

export function getOid (v) {
  return v && v._id ? v._id : v;
}

export function get2LettersFromName (name) {
  const parts = (name || '').split(/\s+/).filter(x => x).map(x => x[0]);
  if (parts.length >= 2) {
    return parts[0] + parts[1];
    // if (/^[A-Za-z0-9]+$/.test(eng)) {
    //   return eng;
    // } else {
    //   return parts[0];
    // }
  } else {
    return parts[0] || '';
  }
}

/**
 * generate svg avatar from two letters
 * @param {string} text username
 * @param {number} [size]
 * @param {string} [color]
 * @return {string} svg code
 */
export function letterAvatar (text, size = 50, color) {
  text = get2LettersFromName(text).toUpperCase();
  const textSize = Math.ceil(size / 2);
  const font = [
    'Helvetica',
    'Arial',
    'Lucida Grande',
    'sans-serif',
  ];
  const c = [colors.grey['300']].concat(
    Object.values(colors).map(x => x['600']).filter(x => x),
  );
  const colorIndex = (((text.charCodeAt(0) - 65) || 0) + c.length) % c.length;
  const finalColor = color || c[colorIndex];

  return [
    `<svg xmlns="http://www.w3.org/2000/svg"`,
    ` height="${size}" width="${size}" style="background: ${finalColor}">`,
    `<text text-anchor="middle" dominant-baseline="central" x="50%" y="50%" fill="white"`,
    ` font-size="${textSize}" font-family="${font.join(',')}">`,
    text || '?',
    `</text>`,
    `</svg>`,
  ].join('');
}

export function parsePaypalmeId (url) {
  const m = /(?:https?:\/\/)?(?:www\.)?paypal\.me\/([^/]+)\/?/i
    .exec(String(url));
  return m && m[1];
}

export function jsonDecode (str) {
  try {
    return JSON.parse(str);
  } catch (_) {
    if (typeof str === 'object') {
      return str;
    }
  }
}
