<template>
  <div class="demo-container">
    <div class="demo-container__item">
      <SpacingPlaygroud/>
    </div>
  </div>
</template>

<script>
import SpacingPlaygroud from './SpacingPlaygroud'

export default {
  components: { SpacingPlaygroud },
}
</script>
