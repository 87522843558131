<template>
  <div>
    <p class="display-5">{{title || (model && $t(`currencies.${model.currency}.name`))}}</p>
    <hr/>
    <div v-if="model" class="d-flex">
      <div class="flex-grow-1">
        <p class="display-5">{{$t('f.accountBalance')}}</p>
        <p>
          {{model.balance | currency(currencyPrefix)}}
        </p>
      </div>
      <div>
        <va-button v-if="topUpApi" type="button" @click="topUpDialog = true">
          {{$t('a.topUp')}}
        </va-button>
        <TopUpPaymentDialog
          v-if="topUpApi"
          v-model="topUpDialog"
          @success="onTopUpSuccess()"
          :createPaymentApi="topUpApi"
        />
        <slot name="buttons" :model="model"/>
      </div>
    </div>
    <div v-else>
      {{$t('d.loading')}}
    </div>
  </div>
</template>

<script>
  import TopUpPaymentDialog from 'components/membership/TopUpPaymentDialog';

  export default {
    name: 'MyWallet',
    components: { TopUpPaymentDialog },
    props: {
      title: {
        type: String,
      },
      walletUrl: {
        type: String,
        required: true,
      },
      topUpApi: {
        type: String,
      },
    },
    data () {
      return {
        model: null,
        topUpDialog: false,
      };
    },
    computed: {
      currencyPrefix () {
        return this.model
          ? this.$t(`currencies.${this.model.currency}.code`, this.model.currency) + ' '
          : '';
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.model = (await this.$http.get(this.walletUrl)).data;
      },
      onTopUpSuccess () {
        this.showToast(this.$t('p.success'));
        this.reload();
      },
    },
  };
</script>
