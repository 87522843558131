<template>
  <div>
    <div class="va-row flex mb-3">
      <va-tabs v-model="tab">
        <va-tab value="accountAndSecurity">
          {{$t('menu.accountAndSecurity')}}
        </va-tab>
        <va-tab value="profiles" v-if="get(model, 'profiles.length') > 1">
          {{$t('menu.otherProfiles')}}
        </va-tab>
      </va-tabs>
    </div>

    <template v-if="tab === 'accountAndSecurity'">
      <div class="va-row flex">
        <va-card :title="$t('user.profile')">
          <template slot="actions">
            <div class="d-flex justify-content-center">
              <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
              <!--              <va-button color="primary" @click="doEdit()">{{$t('a.edit')}}</va-button>-->
            </div>
          </template>
          <div class="va-layout fluid gutter--md">
            <div class="va-row" v-if="model">
              <div class="flex xs12 md6 xl4">
                <p class="text-category">{{$t('f.categories.personalInfo')}}</p>
                <hr/>
                <p class="display-5">{{$t('f.username')}}</p>
                <p>
                  {{model.username || $t('d.empty')}}
                  <a href="javascript:" @click="editModel = model.username; editUsername = true">
                    ({{$t('a.change')}})
                  </a>
                </p>
                <ValidationObserver slim ref="editUsername" v-slot="{ validate, reset }">
                  <va-modal
                    :title="$t('a.edit')"
                    v-model="editUsername"
                    :onOk="d => {
                      validate().then(() => {
                        doUpdate({ username: editModel }, id)
                          .then(ok => {
                            if (!ok) return;
                            d.close();
                            model.username = editModel;
                          });
                      });
                      return false;
                    }"
                  >
                    <ValidationProvider
                      v-slot="{errors, valid}"
                      :name="$t('f.username')"
                      mode="lazy"
                    >
                      <va-input
                        v-model="editModel"
                        :label="$t('f.username')"
                        :error="valid === false"
                        :error-messages="errors"
                        :messages="[$t('d.optional')]"
                      />
                    </ValidationProvider>
                  </va-modal>
                </ValidationObserver>
                <p class="display-5">{{$t('f.email')}}</p>
                <p>
                  {{model.email || $t('d.empty')}}
                  <a :href="changeEmailHref" :title="$t('auth.change_email')" target="_blank">
                    ({{$t('a.change')}})
                  </a>
                </p>
                <p class="display-5">{{$t('f.phone')}}</p>
                <p>
                  {{model.phone || $t('d.empty')}}
                  <a :href="changePhoneHref" :title="$t('auth.change_phone')" target="_blank">
                    ({{$t('a.change')}})
                  </a>
                </p>
              </div>
              <div class="flex xs12 md6 xl4">
                <p class="text-category">{{$t('f.categories.preferences')}}</p>
                <hr/>
                <div class="display-5">
                  {{$t('f.language')}}
                  <span class="text-hint">
                    <va-icon icon="ion ion-ios-information-circle-outline"/>
                    {{$t('d.languagePreferenceHint')}}
                  </span>
                </div>
                <p>
                  {{languageMap(get(model, 'preference.language'))}}
                  <a
                    href="javascript:"
                    @click="editModel = get(model, 'preference.language'); editLanguage = true">
                    ({{$t('a.change')}})
                  </a>
                </p>
                <va-modal
                  :title="$t('a.edit')"
                  v-model="editLanguage"
                  :onOk="d => {
                      doUpdate({ preference: { language: editModel || null } }, id)
                        .then(ok => {
                          if (!ok) return;
                          d.close();
                          reload();
                        });
                      return false;
                    }"
                >
                  <va-simple-select
                    v-model="editModel"
                    :label="$t('f.language')"
                    :options="['zh', 'en']"
                    searchable
                    clearable
                    :formatter="languageMap"
                  />
                </va-modal>
              </div>
              <div class="flex xs12 md6 xl4">
                <p class="text-category">{{$t('f.categories.security')}}</p>
                <hr/>
                <p class="display-5">{{$t('auth.password')}}</p>
                <p>
                  {{model.hasPw ? '••••••••' : $t('d.unset')}}
                  <span
                    v-if="model.pw_updated"
                    class="text-hint"
                    :title="moment(model.pw_updated).format('LLL')"
                  >
                    <va-icon icon="ion ion-ios-information-circle-outline"/>
                    {{$t('d.lastChangedAt$time', {time: moment(model.pw_updated).fromNow()})}}
                  </span>
                </p>
                <va-button tag="a" :href="changePasswordHref" target="_blank">
                  {{$t('auth.change_password')}}
                </va-button>
                <va-button @click="promptLogoutFromAllPlace" color="danger">
                  {{$t('auth.logoutFromAllPlace')}}
                </va-button>
              </div>
            </div>
          </div>
        </va-card>
      </div>
      <MyProfile ref="profile"/>
    </template>

    <div v-show="tab === 'profiles' && get(model, 'profiles.length') > 1">
      <Profiles
        ref="table"
        crudURL="user/me/profiles/{id}"
        :customActions="profilesActions"
        :fieldEvent="profilesFieldEvent"
        :rowClicked="false"
      >
        <template slot="additionalTableControl">&nbsp;</template>
      </Profiles>
    </div>
  </div>
</template>

<script>
  import { get } from 'lodash';
  import moment from 'moment';
  import ApiError from 'services/ApiError';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import Profiles from 'components/membership/Profiles';
  import crudMixin from 'src/services/crudMixin';
  import ActionsColumn from 'src/components/tables/ActionsColumn';
  import MyProfile from 'components/membership/MyProfile';
  import { create } from 'src/services/schema/User';

  export default {
    name: 'Myself',
    mixins: [crudMixin],
    components: {
      Profiles,
      MyProfile,
    },
    data () {
      return {
        crudURL: 'user/{id}',
        id: 'me',
        tab: 'accountAndSecurity',
        model: null,
        editModel: undefined,
        editUsername: false,
        editLanguage: false,
      };
    },
    watch: {
      user () {
        this.reload();
      },
    },
    computed: {
      ...mapGetters([
        'user',
        'profileId',
      ]),
      ...mapState({
        allowedRoles: s => s.auth.allowedRoles,
      }),
      get: () => get,
      moment: () => moment,
      changePasswordHref () {
        return `${this.api.host}${this.api.sso}/changePassword`;
      },
      changeEmailHref () {
        return `${this.api.host}${this.api.sso}/changeEmail`;
      },
      changePhoneHref () {
        return `${this.api.host}${this.api.sso}/changePhone`;
      },
      profilesActions () {
        return {
          name: ActionsColumn,
          actions: ({ data }) => {
            const isDef = data._id === get(this.model, 'profiles[0]');
            const isCurrent = data._id === this.profileId;
            const canSwitch = this.allowedRoles.includes(data.role);
            return [
              {
                key: isCurrent ? '_switchProfile' : 'switchProfile',
                icon: 'ion ion-md-person',
                color: isCurrent ? 'info' : 'gray',
                title: this.$t(canSwitch
                  ? (isCurrent ? 'd.currentProfile' : 'a.switchToProfile')
                  : 'd.roleNotAllowedToSwitch'),
                disabled: !canSwitch,
              },
              {
                key: isDef ? '_setAsDefault' : 'setAsDefault',
                icon: 'ion ion-ios-star',
                color: isDef ? 'warning' : 'gray',
                title: this.$t(isDef ? 'd.defaultProfile' : 'a.setAsDefault'),
              },
            ];
          },
        };
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      ...mapActions([
        'checkLoginStatus',
      ]),
      async reload () {
        this.model = create(
          (await this.$http.get(this.crudURL,
            { params: { id: this.id } })).data,
        );
        this.$nextTick(() => {
          this.$refs.table.reload();
        });
      },
      async profilesFieldEvent (type, payload) {
        if (type === 'action') {
          const { action, data } = payload;
          switch (action.key) {
            case 'setAsDefault': {
              const target = data._id;
              const updated = [target].concat(this.model.profiles.filter(x => x !== target));
              if (await this.promptUpdate({ profiles: updated })) {
                this.model.profiles = updated;
                this.$nextTick(() => {
                  this.$refs.table.reload();
                });
              }
              break;
            }
            case 'switchProfile': {
              try {
                await this.$http.post('sso/switch/{id}', null, { params: { id: data._id } });
                await this.checkLoginStatus();
                this.showToast(this.$t('p.success'));
              } catch (err) {
                const ae = ApiError.wrap(err);
                alert(ae.message);
              }
              break;
            }
          }
          return false;
        }
      },
      async promptLogoutFromAllPlace () {
        if (!confirm(this.$t('p.promptLogoutFromAllPlace'))) {
          return;
        }
        try {
          await this.$http.post('clearTokens');
          this.$router.push({ name: 'logout' });
        } catch (err) {
          const ae = ApiError.wrap(err);
          alert(ae.message);
        }
      },
      languageMap (locale) {
        if (!locale) {
          return this.$t('d.unset');
        }
        const map = {
          zh: 'traditional_chinese',
          'zh-hk': 'traditional_chinese',
          'zh-cn': 'simplified_chinese',
          en: 'english',
        };
        if (map[locale]) {
          return this.$t('language.' + map[locale]);
        } else {
          return locale;
        }
      },
    },
  };
</script>
