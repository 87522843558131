<template>
  <div>
    <div class="va-row flex">
      <va-card :title="$t('schema.m_user')">
        <template slot="actions">
          <div class="d-flex justify-content-center">
            <va-checkbox
              v-if="model"
              :label="$t('f.enabled')"
              :value="model.enabled"
              @input="toggleEnabled"
              class="d-inline-flex"
            />
            <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
            <va-button color="primary" @click="doEdit()">{{$t('a.edit')}}</va-button>
            <va-button color="danger" @click="promptDelete()">{{$t('a.delete')}}</va-button>
          </div>
        </template>
        <div class="va-layout fluid gutter--md">
          <div class="va-row" v-if="model">
            <div class="flex md4">
              <p class="text-category">{{$t('f.categories.personalInfo')}}</p>
              <hr/>
              <p class="display-5">{{$t('f.username')}}</p>
              <p>{{model.username || $t('d.empty')}}</p>
              <p class="display-5">{{$t('f.email')}}</p>
              <p>{{model.email || $t('d.empty')}}</p>
              <p class="display-5">{{$t('f.phone')}}</p>
              <p>{{model.phone || $t('d.empty')}}</p>
            </div>
            <div class="flex md4">
              <p class="text-category">{{$t('f.categories.security')}}</p>
              <hr/>
              <p class="display-5">{{$t('auth.password')}}</p>
              <p
                v-if="model.pw_updated"
                class="text--secondary small"
                :title="moment(model.pw_updated).format('LLL')"
              >
                {{$t('d.lastChangedAt$time', {time: moment(model.pw_updated).fromNow()})}}
              </p>
              <p>{{model.hasPw ? '••••••••' : $t('d.unset')}}</p>
              <form
                ref="resetPassword"
                :action="resetPasswordHref"
                method="post"
                target="_blank"
                class="d-none">
                <input type="hidden" name="redirect" value="1"/>
                <input type="hidden" name="link_only" value="1"/>
              </form>
              <va-button @click="doResetPassword">
                {{$t('auth.reset_password')}}
              </va-button>
              <va-button @click="promptClearAllTokens" color="danger">
                {{$t('a.clearAllTokens')}}
              </va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
    <div class="va-row flex">
      <Profiles
        ref="table"
        :appendParams="profilesFilter"
        :customActions="profilesActions"
        :fieldEvent="profilesFieldEvent"
      >
        <template slot="additionalTableControl">
          <va-button @click="promptLinkProfileToUser">{{$t('a.linkProfileToUser')}}</va-button>
        </template>
      </Profiles>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="id"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
    <va-modal
      v-model="selectProfile"
      :title="$t('a.select$obj', {obj: $t('schema.m_profile')})"
      size="large"
      :okText="$t('a.confirm')"
      :cancelText="$t('a.cancel')"
      :onOk="promptUpdateUserProfiles"
    >
      <Profiles
        ref="selectProfile"
        :rowClicked="false"
        :showRowActions="false"
        selectable="multi"
      >
        <span slot="additionalTableControl"></span>
      </Profiles>
    </va-modal>
  </div>
</template>

<script>
  import { get } from 'lodash';
  import moment from 'moment';
  import Profiles from 'components/membership/Profiles';
  import EditDialog from './UserEditDialog';
  import crudMixin from 'src/services/crudMixin';
  import ActionsColumn, { actions } from 'src/components/tables/ActionsColumn';

  export default {
    name: 'User',
    mixins: [crudMixin],
    components: {
      Profiles,
      EditDialog,
    },
    props: {
      id: {
        type: String,
      },
      crudURL: {
        type: String,
        default: 'user/{id}',
      },
    },
    data () {
      return {
        model: null,
        editDialog: false,
        selectProfile: false,
      };
    },
    computed: {
      moment: () => moment,
      resetPasswordHref () {
        return `${this.api.host}${this.api.base}/user/${this.id}/requestChangePassword`;
      },
      profilesFilter () {
        return { filter: { _id: { $in: get(this.model, 'profiles') || [] } } };
      },
      profilesActions () {
        return {
          name: ActionsColumn,
          actions: ({ data }) => {
            const isDef = data._id === get(this.model, 'profiles[0]');
            return [
              actions.view.call(this),
              actions.edit.call(this),
              {
                key: isDef ? '_setAsDefault' : 'setAsDefault',
                icon: 'ion ion-ios-star',
                color: isDef ? 'warning' : 'gray',
                title: this.$t(isDef ? 'd.defaultProfile' : 'a.setAsDefault'),
              },
            ];
          },
        };
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.model = (await this.$http.get(this.crudURL,
          { params: { id: this.id } })).data;
        this.$nextTick(() => {
          this.$refs.table.reload();
        });
      },
      async profilesFieldEvent (type, payload) {
        if (type === 'action') {
          const { action, data } = payload;
          if (action.key === 'setAsDefault') {
            const target = data._id;
            const updated = [target].concat(this.model.profiles.filter(x => x !== target));
            if (await this.promptUpdate({ profiles: updated })) {
              this.model.profiles = updated;
              this.$nextTick(() => {
                this.$refs.table.reload();
              });
            }
            return false;
          }
        }
      },
      doEdit () {
        this.editDialog = true;
      },
      async promptDelete () {
        if (await crudMixin.methods.promptDelete.call(this)) {
          this.$router.replace({ name: 'm_users' });
        }
      },
      async toggleEnabled (v) {
        if (!confirm(this.$t(v ? 'p.promptEnable' : 'p.promptDisable'))) {
          return;
        }
        if (await this.promptUpdate({ enabled: v })) {
          this.model.enabled = v;
        }
      },
      doResetPassword () {
        this.$refs.resetPassword.submit();
      },
      promptLinkProfileToUser () {
        this.selectProfile = true;
        this.$nextTick(() => {
          this.$refs.selectProfile.vuetable.selectedTo = [].concat(this.model.profiles);
        });
      },
      async promptUpdateUserProfiles () {
        let update = [].concat(this.$refs.selectProfile.vuetable.selectedTo);
        // keep the default profile as the 0th item
        if (update.includes(this.model.profiles[0])) {
          update = [this.model.profiles[0]].concat(
            update.filter(x => x !== this.model.profiles[0]));
        }
        const add = update.filter(x => !this.model.profiles.includes(x)).length;
        const remove = this.model.profiles.filter(x => !update.includes(x)).length;
        if (!add && !remove) {
          this.showToast(this.$t('p.noChange'));
          return;
        }
        const prompt = add && remove
          ? 'promptUpdateProfiles$add$remove'
          : add
            ? 'promptUpdateProfiles$add'
            : 'promptUpdateProfiles$remove';
        if (!confirm(this.$t('p.' + prompt, { add, remove }))) {
          return;
        }
        if (await this.promptUpdate({ profiles: update })) {
          this.model.profiles = update;
        }
      },
      async promptClearAllTokens () {
        if (!confirm(this.$t('p.prompt$action', { action: this.$t('a.clearAllTokens') }))) {
          return;
        }
        await this.$http.delete('user/{id}/tokens', { params: { id: this.id } });
        this.showToast(this.$t('p.success'));
      },
    },
  };
</script>
