<template>
  <div class="tables">
    <div class="va-row">
      <div class="flex xs12">
        <va-card :title="$t('schema.m_users')">
          <va-data-table
            ref="table"
            class="row-clickable"
            :apiUrl="crudURL.replace('{id}', '')"
            :httpFetch="tableAdapter.httpFetch"
            :transform="tableAdapter.transform"
            :tableFields="tableFields"
            :itemsPerPage="itemsPerPage"
            apiMode
            :appendParams="appendParamsComputed"
            :queryParams="tableAdapter.queryParams"
            :filterQuery="tableAdapter.filterQuery"
            :sortOrder="sortOrder"
            :vuetableOptions="tableAdapter.vuetableOptions"
            @vuetable:row-clicked="onRowClicked"
            @vuetable:field-event="onFieldEvent"
          >
            <template slot="additionalTableControl">
              <va-toggle
                :label="$t('d.showDisabled')"
                v-model="appendFilter.enabled"
                :trueValue="ne"
                :falseValue="true"
                small
                style="margin-bottom: 0"
              />
              <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
              <slot name="additionalTableControl">
                <va-button @click="doEdit(null)">
                  {{$t('auth.createAccount')}}
                </va-button>
              </slot>
            </template>
            <template slot="href" slot-scope="{rowData: x}">
              <a :href="x.href"
                 target="_blank"
                 :title="$t('a.openLink')"
                 @click.stop
              >{{x.href}}</a>
            </template>
            <template slot="detailRowComponent" slot-scope="scope">
              <DetailRow
                v-bind="scope"
                @update="reload"
                @close="hideDetailRow"
                @delete="reload"
              />
            </template>
          </va-data-table>
        </va-card>
      </div>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="editId"
      @create="x => editId = x._id"
      @update="reload"
      @delete="reload"
    />
  </div>
</template>

<script>
  import EditDialog from './UserEditDialog';
  import DetailRow from './UserDetailRow.vue';
  import ActionsColumn, { actions } from 'src/components/tables/ActionsColumn';
  import vuetableMixin from 'src/services/vuetableMixin';
  import { merge, cloneDeep } from 'lodash';
  import { sid, date, datetime, array } from 'src/services/tableColumnTemplates';

  export default {
    name: 'Users',
    mixins: [vuetableMixin],
    components: {
      EditDialog,
      DetailRow,
    },
    props: {
      crudURL: {
        type: String,
        default: 'user/{id}',
      },
    },
    data () {
      return {
        ne: { $ne: true },
        appendFilter: {
          enabled: true,
        },
      };
    },
    computed: {
      appendParamsComputed () {
        return merge({
          filter: cloneDeep(this.appendFilter),
        }, this.appendParams);
      },
      tableFields () {
        return [
          sid(),
          {
            name: 'username',
            title: this.$t('f.username'),
            sortField: 'username',
          },
          {
            name: 'email',
            title: this.$t('f.email'),
            sortField: 'email',
          },
          {
            name: 'phone',
            title: this.$t('f.phone'),
            sortField: 'phone',
          },
          {
            name: '_roles',
            title: this.$t('f.identities'),
            ...array(', ', x => this.$t('roles.' + x)),
          },
          {
            name: 'created_at',
            title: this.$t('f.created_at'),
            sortField: 'created_at',
            ...date(true),
          },
          {
            name: 'updated_at',
            title: this.$t('f.updated_at'),
            sortField: 'updated_at',
            ...datetime(true),
          },
          {
            name: ActionsColumn,
            actions ({ data }) {
              return [
                actions.view.call(this),
                actions.edit.call(this),
                data.enabled
                  ? actions.disable.call(this)
                  : actions.enable.call(this),
                !data.enabled ? actions.delete.call(this) : null,
              ].filter(x => x);
            },
          },
        ];
      },
    },
    methods: {
      onFieldEvent (type, payload) {
        if (type === 'action') {
          const { action, data } = payload;
          switch (action.key) {
            case 'delete':
              this.promptDelete(data._id);
              break;
            case 'edit':
              this.doEdit(data._id);
              break;
            case 'view':
              this.$router.push({ name: 'm_user', params: { id: data._id } });
              break;
            case 'disable':
              if (!confirm(this.$t('p.promptDisable'))) {
                break;
              }
              this.promptUpdate({ enabled: false }, data._id);
              break;
            case 'enable':
              if (!confirm(this.$t('p.promptEnable'))) {
                break;
              }
              this.promptUpdate({ enabled: true }, data._id);
              break;
          }
        }
      },
    },
  };
</script>
