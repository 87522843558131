<template>
  <div class="auth-layout flex-center flex-column">
    <router-link class="auth-layout__container text-center" to="/">
      <!--      <va-icon-vuestic class="auth-layout__logo"/>-->
      <linklink-icon/>
    </router-link>

    <div class="auth-layout__card">
      <va-card>
        <va-tabs
          v-model="tab"
          center
        >
          <va-tab value="login">
            {{$t('auth.login')}}
          </va-tab>
          <va-tab value="signup">
            {{$t('auth.createNewAccount')}}
          </va-tab>
        </va-tabs>
        <va-separator/>

        <div class="auth-layout__form">
          <router-view/>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
  import LinklinkIcon from 'components/admin/linklink-icon';
  import VaIconVuestic
    from '../../vuestic-theme/vuestic-components/va-icon/va-iconset/VaIconVuestic';
  import VaSeparator
    from '../../vuestic-theme/vuestic-components/va-card/VaSeparator';

  export default {
    name: 'AuthLayout',
    components: { LinklinkIcon, VaSeparator, VaIconVuestic },
    data () {
      return {
        selectedTabIndex: 0,
      };
    },
    computed: {
      tab: {
        set (name) {
          this.$router.replace({ name });
        },
        get () {
          return this.$route.name;
        },
      },
    },
  };
</script>

<style lang="scss">
  @import "../../vuestic-theme/vuestic-sass/resources/resources";

  .auth-layout {
    min-height: 100vh;
    background-image: linear-gradient(to right, #17e6a1, #00b33c);
    padding: 1rem;

    &__card {
      /*height: 30rem;*/
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    &__form {
      padding: .875rem 0 0;
      @include media-breakpoint-up(sm) {
        padding: 2.875rem 3.75rem .625rem;
        width: 30rem;
      }
    }

    &__container {
      height: $auth-logo-height;
      width: 100%;
      margin-bottom: 5.625rem;
      @include media-breakpoint-down(xs) {
        margin-bottom: 3rem;
      }
    }

    &__logo {
      height: 100%;
      width: 100%;
      margin-bottom: 5.625rem;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 6%;
    }

    .linklink-icon {
      font-size: 70px;
      @include media-breakpoint-down(xs) {
        font-size: 3rem;
      }
    }
  }
</style>
