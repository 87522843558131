var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",staticClass:"signup",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.doRegister()}}},[_c('va-simple-select',{attrs:{"label":_vm.$t('f.role'),"clearable":false,"options":['linkConsumer', 'business'],"formatter":function (x) { return _vm.$t('roles.' + x); }},model:{value:(_vm.model.role),callback:function ($$v) {_vm.$set(_vm.model, "role", $$v)},expression:"model.role"}}),_c('ValidationProvider',{attrs:{"name":_vm.$t('f.username'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.username'),"error":valid === false,"error-messages":errors,"messages":[_vm.$t('d.optional')]},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('f.displayName'),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.displayName'),"error":valid === false,"error-messages":errors,"messages":[_vm.$t('d.optional')]},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('f.email'),"mode":"lazy","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"type":"email","label":_vm.$t('f.email'),"error":valid === false,"error-messages":errors},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"phone","name":_vm.$t('f.phone'),"mode":"lazy","rules":"regex:^\\s*\\+?[0-9\\-\\s()]+$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.phone'),"error":valid === false,"error-messages":errors,"messages":[_vm.$t('d.optional')]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}},[_c('template',{slot:"appendText"},[(_vm.canSendSms)?_c('va-button',{attrs:{"disabled":!valid,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.promptRequestSMSCode()}}},[_vm._v(" "+_vm._s(_vm.$t('a.getVerifyCode'))+" ")]):_c('va-button',{attrs:{"disabled":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('d.resendSms', {time: _vm.sms.waitUntil.diff(_vm.now, 'seconds')}))+" ")])],1)],2)]}}])}),(_vm.phone)?_c('ValidationProvider',{key:"verifyCode",attrs:{"name":_vm.$t('f.verifyCode'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"label":_vm.$t('f.verifyCode'),"error":valid === false,"error-messages":errors},model:{value:(_vm.sms.code),callback:function ($$v) {_vm.$set(_vm.sms, "code", $$v)},expression:"sms.code"}},[(_vm.sms.nonce)?_c('template',{slot:"prependText"},[_c('span',{staticStyle:{"padding":".25em 0 .1em .5em"}},[_vm._v("("+_vm._s(_vm.sms.nonce)+")")])]):_vm._e()],2)]}}],null,false,2879706886)}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":"pw","name":_vm.$t('auth.password'),"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"type":"password","label":_vm.$t('auth.password'),"error":valid === false,"error-messages":errors},model:{value:(_vm.model.pw),callback:function ($$v) {_vm.$set(_vm.model, "pw", $$v)},expression:"model.pw"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('auth.confirmPassword'),"mode":"lazy","rules":"required|confirmed:pw"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('va-input',{attrs:{"type":"password","label":_vm.$t('auth.confirmPassword'),"error":valid === false,"error-messages":errors},model:{value:(_vm.pw1),callback:function ($$v) {_vm.pw1=$$v},expression:"pw1"}})]}}])}),_c('div',{staticClass:"flex-center mt-4"},[_c('va-button',{staticClass:"my-0",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('auth.signUp'))+" ")])],1),(_vm.err)?_c('div',{staticClass:"flex-center"},[_c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.err))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }