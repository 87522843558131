import { render, staticRenderFns } from "./SourceLinkEditDialog.vue?vue&type=template&id=4642be0e&"
import script from "./SourceLinkEditDialog.vue?vue&type=script&lang=js&"
export * from "./SourceLinkEditDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports