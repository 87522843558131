<template>
  <va-modal
    v-model="valueProxy"
    :title="title"
    noOutsideDismiss
    hideDefaultActions
    size="large"
  >
    <template slot="actions">
      <va-button flat color="gray" @click="doClose()">{{$t('a.close')}}</va-button>
      <va-button color="primary" @click="promptCreate()" v-if="isCreate">{{$t('a.create')}}
      </va-button>
      <va-button color="primary" @click="promptUpdate()" v-if="!isCreate">{{$t('a.update')}}
      </va-button>
      <!--      <va-button color="danger" @click="promptDelete()" v-if="id">{{$t('a.delete')}}</va-button>-->
    </template>
    <ValidationObserver tag="div" ref="form">
      <div v-if="model" class="va-layout fluid gutter--md">
        <div class="va-row">
          <div class="flex xs12">
            <ValidationProvider
              ref="sid"
              vid="sid"
              v-slot="{errors, valid}" :name="$t('f.id')" mode="lazy"
              :rules="{required: true, regex:/^[A-Za-z0-9_\-.,]+$/i}"
            >
              <va-input
                v-model="model.sid"
                :label="$t('f.id')"
                :placeholder="$t('f.sid_hint')"
                :messages="[$t('f.sid_hint')]"
                :error="!valid"
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>
        <p class="text-category">{{$t('f.categories.seo')}}</p>
        <div class="va-row">
          <div class="flex md12">
            <va-input
              v-model="model.title"
              :label="$t('f.seoTitle')"
              :placeholder="$t('f.sharedlink_hint')"
            />
            <va-input
              v-model="model.description"
              type="textarea"
              :label="$t('f.seoDescription')"
              :placeholder="$t('f.sharedlink_hint')"
            />
          </div>
          <div class="flex md12" v-if="!isCreate">
            <p class="display-5">{{$t('f.seoImage')}}</p>
            <p>
              <va-file-upload
                type="single"
                :value="attnConvert([model.image])"
                @upload="uploadImage"
                @remove="removeImage"
              />
            </p>
            <img v-if="model.image"
                 style="max-width: 256px;"
                 :src="attnThumbHref(model.image, 256)"/>
          </div>
          <div class="flex md12">
            <va-input
              v-model="model.remarks"
              :label="$t('f.remarks')"
              type="textarea"
            />
          </div>
        </div>
      </div>
    </ValidationObserver>
  </va-modal>
</template>

<script>
  import Objectid from 'components/objectid';
  import ApiError from 'services/ApiError';
  import crudMixin from 'src/services/crudMixin';
  import editDialogMixin from 'src/services/editDialogMixin';
  import { create } from 'src/services/schema/SourceLink';

  export default {
    name: 'SharedLinkEditDialog',
    components: { Objectid },
    mixins: [crudMixin, editDialogMixin],
    data () {
      return {
        crudURL: 'll/sharedlink/{id}',
      };
    },
    computed: {
      title () {
        return this.$t('schema.ll_sharedlink');
      },
    },
    methods: {
      async reload () {
        this.model = this.id ? (await this.$http.get(this.crudURL,
          { params: { id: this.id, populate: 'image' } })).data : create();
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
      async promptCreate () {
        if (!await this.$refs.form.validate()) {
          return;
        }
        try {
          const model = (await this.$http.post(this.crudURL, this.model,
            { params: { id: null } })).data;
          this.showToast(this.$t('p.created'));
          this.$emit('create', model);
          return model;
        } catch (err) {
          this.handleError(err);
        }
      },
      handleError (err) {
        const ae = ApiError.wrap(err);
        if (ae.code === 'duplicate_key') {
          this.$refs.sid.applyResult({ valid: false, errors: [this.$t('d.sid_was_taken')] });
          return;
        }
        alert(ae.message);
      },
      async uploadImage (files) {
        if (!files[0]) return;
        await this.attnDelete(this.model.image);
        const attn = await this.attnUpload(files[0]);
        this.$set(this.model, 'image', attn);
        return this.doUpdate({ image: attn._id }, this.id);
      },
      async removeImage () {
        await this.attnDelete(this.model.image);
        this.$set(this.model, 'image', null);
        return this.doUpdate({ image: null }, this.id);
      },
    },
  };
</script>
