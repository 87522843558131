import crudMixin from 'src/services/crudMixin';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  mixins: [crudMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    rowData: Object,
    rowIndex: Number,
  },
  computed: {
    id () {
      return this.rowData && this.rowData._id;
    },
  },
  data () {
    return {
      model: null,
    };
  },
  methods: {
    doClose () {
      this.$emit('close', { data: this.model, index: this.index });
    },
    async promptUpdate (patch = this.model, id = this.id) {
      const x = await crudMixin.methods.promptUpdate.call(this, patch, id);
      if (x) {
        this.$emit('update', { data: this.model, index: this.index });
      }
      return x;
    },
    async promptDelete (id = this.id) {
      const x = await crudMixin.methods.promptDelete.call(this, id);
      if (x) {
        this.$emit('delete', { data: this.model, index: this.index });
      }
      return x;
    },
  },
};
