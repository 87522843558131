import crudMixin from 'services/crudMixin';
import { mapGetters } from 'vuex';
import { merge, identity } from 'lodash';
import ItemsPerPageDef
  from '../vuestic-theme/vuestic-components/va-datatable/data/items-per-page-definition';
import * as tableAdapter from 'src/api/tableAdapter.js';
import VuetableFieldCheckboxCustom
  from 'src/components/tables/VuetableFieldCheckboxCustom';

export default {
  mixins: [crudMixin],
  props: {
    appendParams: {
      default: () => ({}),
    },
    rowClicked: {
      type: [Function, Boolean],
      default: null,
    },
    showRowActions: {
      type: Boolean,
      default: true,
    },
    customActions: {
      default: null,
    },
    fieldEvent: {
      type: [Function, Boolean],
      default: null,
    },
    selectable: {
      type: [String, Boolean],
      default: null,
    },
  },
  data () {
    return {
      editId: null,
      editDialog: false,
      itemsPerPage: ItemsPerPageDef.itemsPerPage,
      sortOrder: [
        {
          field: 'created_at',
          direction: 'desc',
        },
      ],
    };
  },
  watch: {
    appendParamsComputed: {
      deep: true,
      handler () {
        // need $nextTick to make sure props propagated to child
        this.$nextTick(() => {
          this.reload();
        });
      },
    },
  },
  computed: {
    ...mapGetters([
      'api',
    ]),
    tableAdapter: () => tableAdapter,
    appendParamsComputed () {
      return merge({}, this.appendParams);
    },
    vuetable () {
      return this.$refs.table.$refs.vuetable;
    },
    tableFieldsComputed () {
      return [
        this.selectable ? {
          name: VuetableFieldCheckboxCustom,
          singleSelect: this.selectable === 'single',
        } : null,
      ]
        .concat(this.tableFields)
        .concat(this.showRowActions
          ? (this.customActions || this.actionsColumn)
          : null)
        .filter(identity);
    },
    tableFields: () => [],
    actionsColumn: () => null,
  },
  methods: {
    async onRowClicked (row) {
      if (this.rowClicked === false) {
        return;
      } else if (typeof this.rowClicked === 'function') {
        if (await this.rowClicked(row) === false) {
          return;
        }
      }
      this.toggleDetailRow(row);
    },
    onFieldEvent (type, payload) {
      if (typeof this.fieldEvent === 'function') {
        this.fieldEvent.apply(this, arguments);
      }
    },
    toggleDetailRow ({ data }) {
      this.$refs.table.$refs.vuetable.toggleDetailRow(data._id);
    },
    showDetailRow ({ data }) {
      this.$refs.table.$refs.vuetable.showDetailRow(data._id);
    },
    hideDetailRow ({ data }) {
      this.$refs.table.$refs.vuetable.hideDetailRow(data._id);
    },
    reload () {
      this.$refs.table.$refs.vuetable.reload();
    },
    async promptDelete (id) {
      if (!confirm(this.$t('p.promptDelete'))) {
        return;
      }
      try {
        const x = await this.doDelete(...arguments);
        this.showToast(this.$t('p.deleted'));
        this.reload();
        return x;
      } catch (err) {
        this.handleError(err);
      }
    },
    async promptUpdate (patch, id) {
      try {
        const x = await this.doUpdate(...arguments);
        this.showToast(this.$t('p.updated'));
        this.reload();
        return x;
      } catch (err) {
        this.handleError(err);
      }
    },
    async doUpdate (patch, id) {
      if (!id) {
        throw new Error('missing id');
      }
      return (await this.$http.patch(this.crudURL, patch,
        { params: { id } })).data;
    },
    doEdit (id) {
      this.editId = id;
      this.editDialog = true;
    },
  },
};
