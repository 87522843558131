<template>
  <div>
    <div class="va-row flex">
      <va-card :title="$t('schema.ll_sourcelink')">
        <template slot="actions">
          <div class="d-flex justify-content-center">
            <va-checkbox
              v-if="model"
              :label="$t('f.enabled')"
              :value="model.enabled"
              @input="toggleEnabled"
              class="d-inline-flex"
            />
            <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
            <va-button color="primary" @click="doEdit()">{{$t('a.edit')}}</va-button>
            <va-button color="danger" @click="promptDelete()">{{$t('a.delete')}}</va-button>
          </div>
        </template>
        <div class="va-layout fluid gutter--md" v-if="model">
          <div class="va-row">
            <div class="flex md4">
              <p class="display-5">{{$t('f.targetLink')}}</p>
              <p><a :href="model.href" target="_blank">{{model.href}}</a></p>
            </div>
            <div class="flex md4">
              <p class="display-5">{{$t('f.remarks')}}</p>
              <p style="white-space: pre-line">{{model.remarks || '-'}}</p>
            </div>
            <div class="flex md4">
              <p class="display-5">{{$t('p.create$obj', {obj: $t('schema.ll_sharedlink')})}}</p>
              <va-button @click="doCreateSharedLink()">
                {{$t('p.clickTo$action', {action: $t('a.create')})}}
              </va-button>
            </div>
          </div>
          <div class="va-row">
            <div class="flex xs12 md4">
              <p class="text-category">{{$t('f.categories.seo')}}</p>
              <hr/>
              <p class="display-5">{{$t('f.seoTitle')}}</p>
              <p>{{model.title}}</p>
              <p class="display-5">{{$t('f.seoDescription')}}</p>
              <p>{{model.description}}</p>
              <p class="display-5">{{$t('f.seoImage')}}</p>
              <p>
                <img v-if="model.image"
                     style="max-width: 256px;"
                     :src="attnThumbHref(model.image, 256)"/>
                <span v-else>-</span>
              </p>
            </div>
            <div class="flex xs12 md4">
              <p class="text-category">{{$t('f.categories.basic')}}</p>
              <hr/>
              <p class="display-5">{{$t('f.coinsLeft')}}</p>
              <p v-if="Number.isFinite(model.coins)"
                 :class="model.coins < model.costPerClick ? 'text-danger font-weight-bold' : ''">
                {{model.coins | currency('', 2)}}
              </p>
              <p v-else>-</p>
              <p class="display-5">{{$t('f.costPerClick')}}</p>
              <p v-if="Number.isFinite(model.costPerClick)">
                {{model.costPerClick | currency('', 2)}}</p>
              <p v-else>-</p>
              <p class="display-5">{{$t('f.clicksLeft')}}</p>
              <p v-if="Number.isFinite(model.coins)">
                {{Math.floor(model.coins / model.costPerClick)}}
              </p>
              <p v-else>&infin;</p>
            </div>
            <div class="flex xs12 md4" v-if="stats">
              <p class="text-category">{{$t('f.categories.statistics')}}</p>
              <hr/>
              <p class="display-5">{{$t('f.clicks')}}</p>
              <p>{{stats.status.success || 0}}</p>
              <p class="display-5">{{$t('f.failedClicks')}}
                <va-icon
                  icon="ion ion-ios-information-circle-outline"
                  :title="$t('d.failedClicksDesc')"/>
              </p>
              <p>{{stats.status.quota_exceed || 0}}</p>
              <p class="display-5">{{$t('f.distinctSessions')}}</p>
              <p>{{stats.distinct}}</p>
              <p class="display-5">{{$t('f.robots')}}</p>
              <p>{{stats.status.peek || 0}}</p>
            </div>
          </div>
        </div>
      </va-card>
    </div>
    <div class="va-row flex">
      <SharedLinks ref="table" :appendParams="sharedLinksFilter"/>
    </div>
    <div class="va-row">
      <div class="flex md6">
        <va-card :title="$t('f.referrer')">
          <va-data-table
            ref="table2"
            :apiMode="false"
            :tableData="byReferer"
            :tableFields="byRefererFields"
            :sortFunctions="byRefererSort"
            :dataModeFilterableFields="['_id']"
          />
        </va-card>
      </div>
    </div>
    <div class="va-row flex">
      <Clicks ref="table1" :appendParams="clicksFilter"/>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="id"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </div>
</template>

<script>
  import SharedLinks from './SharedLinks';
  import EditDialog from './SourceLinkEditDialog';
  import Clicks from './Clicks';
  import crudMixin from 'src/services/crudMixin';

  export default {
    name: 'SourceLink',
    mixins: [crudMixin],
    components: {
      Clicks,
      SharedLinks,
      EditDialog,
    },
    props: {
      id: { type: String },
    },
    data () {
      return {
        crudURL: 'll/sourcelink/{id}',
        model: null,
        stats: null,
        byReferer: { data: [] },
        editDialog: false,
      };
    },
    computed: {
      sharedLinksFilter () {
        return { filter: { sourcelink: this.id } };
      },
      clicksFilter () {
        return { filter: { sourcelink: this.id } };
      },
      byRefererFields () {
        return [
          {
            name: '_id',
            title: this.$t('f.referrer'),
            sortField: '_id',
            formatter: x => x || 'Others',
          },
          {
            name: 'count',
            title: this.$t('f.count'),
            sortField: 'count',
          },
        ];
      },
      byRefererSort () {
        return {
          _id: this.comparator,
          count: this.comparator,
        };
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      comparator (a, b) {
        return a > b ? 1 : a < b ? -1 : 0;
      },
      async reload () {
        this.model = (await this.$http.get(this.crudURL,
          { params: { id: this.id } })).data;
        this.stats = (await this.$http.get(this.crudURL + '/stats',
          { params: { id: this.id } })).data;
        this.byReferer.data =
          (await this.$http.get('ll/click/stats/byReferer',
            {
              params: {
                filter: JSON.stringify({ sourcelink: this.id, status: 'success' }),
              },
            })).data;
        this.$refs.table.reload();
        this.$refs.table1.reload();
        this.$refs.table2.reload();
      },
      doEdit () {
        this.editDialog = true;
      },
      async promptDelete () {
        if (await crudMixin.methods.promptDelete.call(this)) {
          this.$router.replace({ name: 'll_sourcelinks' });
        }
      },
      async toggleEnabled (v) {
        if (!confirm(this.$t(v ? 'p.promptEnable' : 'p.promptDisable'))) {
          return;
        }
        if (await this.promptUpdate({ enabled: v })) {
          this.model.enabled = v;
        }
      },
      async doCreateSharedLink () {
        const data = (await this.$http.post('ll/sharedlink', {
          sourcelink: this.id,
        })).data;
        this.$router.push({ name: 'll_sharedlink', params: { id: data._id } });
      },
    },
  };
</script>
