import { render, staticRenderFns } from "./FeaturesTab.vue?vue&type=template&id=621d885e&scoped=true&"
import script from "./FeaturesTab.vue?vue&type=script&lang=js&"
export * from "./FeaturesTab.vue?vue&type=script&lang=js&"
import style0 from "./FeaturesTab.vue?vue&type=style&index=0&id=621d885e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621d885e",
  null
  
)

export default component.exports