<template>
  <div>
    <div class="va-row flex mb-3">
      <va-tabs v-model="tab">
        <va-tab value="wallet">{{$t('schema.ec_wallet')}}</va-tab>
        <va-tab value="paymentMethods">{{$t('schema.ec_paymentProfiles')}}</va-tab>
      </va-tabs>
    </div>

    <div v-if="tab === 'wallet'" class="va-row flex">
      <va-card :title="$t('schema.ec_wallet')">
        <template slot="actions">
          <div class="d-flex justify-content-center">
            <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
          </div>
        </template>
        <div class="va-layout fluid gutter--md">
          <div class="va-row">
            <MyWallet
              class="flex xs12 md6"
              walletUrl="user/me/ll/wallet"
              topUpApi="user/me/ll/wallet/createTopupPayment"
            >
              <template v-slot:buttons="{model}">
                <va-button type="button" :disabled="!(model.balance > 0)">
                  {{$t('a.cashOut')}}
                </va-button>
                <va-button
                  type="button" color="info"
                  @click="transactionHistory(model)">
                  {{$t('f.transactionHistory')}}
                </va-button>
              </template>
            </MyWallet>
            <div class="flex xs12 md6">
              <p class="display-5">{{$t('f.categories.achievements')}}</p>
              <hr/>
              <p class="display-5">{{$t('f.accumulatedLLCoinsEarned')}}</p>
              <p>
                {{coinsEarned | currency($t('currencies.llcoin.code'), 2)}}
              </p>
            </div>
          </div>
        </div>
      </va-card>
    </div>

    <div v-if="tab === 'paymentMethods'" class="va-row flex">
      <MyPaymentProfiles/>
    </div>
  </div>
</template>

<script>
  import MyPaymentProfiles from 'components/membership/MyPaymentProfiles';
  import MyWallet from 'components/membership/MyWallet';
  import { mapGetters } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'MyPaymentAndSubscriptions',
    components: { MyWallet, MyPaymentProfiles },
    props: {},
    data () {
      return {
        tab: 'wallet',
        coinsEarned: null,
      };
    },
    computed: {
      ...mapGetters([
        'profile',
      ]),
      moment: () => moment,
    },
    watch: {
      profile () {
        this.reload();
      },
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        const p = await Promise.all([
          this.$http.get('user/me/ll/coinsEarned'),
        ]);
        this.coinsEarned = p[0].data.coinsEarned;
      },
      transactionHistory (wallet) {
        this.$router.push({
          name: 'transactions',
          query: {
            filter: JSON.stringify({
              refs: 'ec_wallet:' + wallet._id,
            }),
          },
        });
      },
    },
  };
</script>
