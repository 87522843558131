<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="va-icon-vuestic" width="304" height="31" viewBox="0 0 304 31">
    <defs>
      <linearGradient id="a" x1="0%" y1="50%" y2="50%">
        <stop offset="0%" stop-color="#4AE387"/>
        <stop offset="100%" stop-color="#C8EA13"/>
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path fill="url(#a)" fill-rule="nonzero" d="M13.2 30.96L.48 1.2h7.344l5.808 13.872L19.44 1.2h7.344l-12.72 29.76H13.2zm30.768-10.656V1.2h7.008v18.048c0 3.84 1.824 5.184 4.272 5.184 2.688 0 4.368-1.344 4.368-5.184V1.2h7.056v19.104c0 6.816-5.088 10.656-11.424 10.656-6.576 0-11.28-4.032-11.28-10.656zM85.68 30V1.2h19.056v6.24h-12v5.184h11.088v6.144H92.736v5.184h12.528V30H85.68zm37.248-8.448h6.864c0 1.68 1.344 3.024 2.976 3.024 1.776 0 2.88-1.008 2.88-2.496 0-2.16-2.832-2.784-5.184-3.6-4.848-1.728-7.536-3.984-7.536-8.928 0-5.088 4.368-9.312 9.792-9.312 6.384 0 9.408 4.08 9.792 9.408h-6.624c0-1.632-1.008-2.976-2.976-2.976-1.536 0-3.024.912-3.024 2.784 0 2.16 2.64 2.544 5.088 3.264 5.136 1.536 7.536 4.416 7.536 8.928 0 5.088-4.32 9.312-9.744 9.312-6.288 0-9.84-4.224-9.84-9.408zM166.272 30V7.44h-6.432V1.2h20.064v6.24h-6.528V30h-7.104zm31.296 0V1.2h7.056V30h-7.056zm26.352-14.4c0-8.592 6.48-15.36 15.6-15.36 5.472 0 9.264 2.064 12.336 5.28l-4.848 4.608c-2.016-1.968-4.608-3.216-7.488-3.216-5.136 0-8.448 3.84-8.448 8.688 0 4.848 3.312 8.688 8.448 8.688 2.88 0 5.472-1.248 7.488-3.216l4.704 4.608c-2.88 3.024-6.864 5.28-12.192 5.28-9.12 0-15.6-6.768-15.6-15.36z"/>
      <path fill="#E4FF32" d="M268.864 16v-2.352c.896-.832 1.732-1.616 2.508-2.352A34.338 34.338 0 0 0 273.4 9.22c.576-.648 1.028-1.264 1.356-1.848.328-.584.492-1.132.492-1.644 0-.688-.172-1.196-.516-1.524-.344-.328-.796-.492-1.356-.492a2.19 2.19 0 0 0-1.356.444c-.392.296-.772.628-1.14.996l-2.208-2.208c.4-.416.792-.78 1.176-1.092a5.936 5.936 0 0 1 1.212-.768c.424-.2.872-.348 1.344-.444a7.775 7.775 0 0 1 1.548-.144c.768 0 1.472.12 2.112.36a4.95 4.95 0 0 1 1.656 1.008 4.45 4.45 0 0 1 1.08 1.572c.256.616.384 1.3.384 2.052 0 .608-.132 1.228-.396 1.86-.264.632-.604 1.26-1.02 1.884a17.342 17.342 0 0 1-1.404 1.824 46.4 46.4 0 0 1-1.548 1.68c.384-.048.82-.092 1.308-.132.488-.04.9-.06 1.236-.06h2.544V16h-11.04zm17.136.288c-.704 0-1.28-.24-1.728-.72-.448-.48-.672-1.072-.672-1.776s.224-1.296.672-1.776c.448-.48 1.024-.72 1.728-.72s1.28.24 1.728.72c.448.48.672 1.072.672 1.776s-.224 1.296-.672 1.776c-.448.48-1.024.72-1.728.72zm11.52 0c-.848 0-1.616-.172-2.304-.516-.688-.344-1.284-.852-1.788-1.524s-.892-1.504-1.164-2.496c-.272-.992-.408-2.136-.408-3.432 0-1.296.136-2.436.408-3.42.272-.984.66-1.8 1.164-2.448a4.955 4.955 0 0 1 1.788-1.464c.688-.328 1.456-.492 2.304-.492.832 0 1.596.164 2.292.492a4.88 4.88 0 0 1 1.8 1.476c.504.656.892 1.472 1.164 2.448.272.976.408 2.112.408 3.408 0 1.296-.136 2.44-.408 3.432-.272.992-.66 1.824-1.164 2.496-.504.672-1.104 1.18-1.8 1.524a5.101 5.101 0 0 1-2.292.516zm0-3.168c.24 0 .468-.06.684-.18.216-.12.404-.356.564-.708.16-.352.288-.84.384-1.464.096-.624.144-1.44.144-2.448s-.048-1.816-.144-2.424c-.096-.608-.224-1.08-.384-1.416-.16-.336-.348-.556-.564-.66a1.557 1.557 0 0 0-.684-.156c-.24 0-.468.052-.684.156-.216.104-.404.324-.564.66-.16.336-.288.808-.384 1.416-.096.608-.144 1.416-.144 2.424s.048 1.824.144 2.448c.096.624.224 1.112.384 1.464.16.352.348.588.564.708.216.12.444.18.684.18z"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'va-icon-vuestic',
}
</script>

<style lang="scss">
.va-icon-vuestic {
  display: inline-block;
  width: 129px;
  height: 15.4px;
}
</style>
