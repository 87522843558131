<template>
  <va-modal
    v-model="valueProxy"
    :title="title"
    noOutsideDismiss
    hideDefaultActions
    size="large"
  >
    <template slot="actions">
      <va-button flat color="gray" @click="doClose()">{{ $t('a.close') }}</va-button>
      <va-button color="primary" @click="promptCreate()" v-if="isCreate">{{ $t('a.create') }}</va-button>
      <va-button color="primary" @click="promptUpdate()" v-if="!isCreate">{{ $t('a.update') }}</va-button>
      <!--      <va-button color="danger" @click="promptDelete()" v-if="id">{{$t('a.delete')}}</va-button>-->
      <va-button color="primary" @click="doCreateSharedLink">
        {{ $t('p.create$obj', { obj: $t('schema.ll_sharedlink') }) }}
      </va-button>
    </template>
    <ValidationObserver tag="div" ref="form">
      <div v-if="model" class="va-layout fluid gutter--md">
        <div v-if="!isCreate" class="va-row">
          <objectid :value="model._id" tag="p"/>
        </div>
        <p class="text-category">{{ $t('f.categories.basic') }}</p>
        <div class="va-row">
          <div class="flex md12">
            <ValidationProvider
              v-slot="{errors, valid}" :name="$t('f.targetLink')" mode="eager"
              :rules="{required: true, url: {require_protocol: true}}"
            >
              <va-input
                v-model="model.href"
                :label="$t('f.targetLink')"
                placeholder="http://"
                :error="valid === false"
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div class="flex md6">
            <ValidationProvider
              v-slot="{errors, valid}" :name="$t('f.coins')" mode="eager"
              rules="decimal:2"
            >
              <va-input
                v-model="model.coins"
                :label="$t('f.coins')"
                :error="valid === false"
                :error-messages="errors"
                placeholder="0"
              />
            </ValidationProvider>
          </div>
          <div class="flex md6">
            <ValidationProvider
              v-slot="{errors, valid}" :name="$t('f.costPerClick')" mode="eager"
              rules="min_value:0|decimal:2"
            >
              <va-input
                v-model="model.costPerClick"
                :label="$t('f.costPerClick')"
                :error="valid === false"
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div class="flex md6">
            <ValidationProvider
              v-slot="{errors, valid}" :name="$t('f.status')" mode="eager"
              rules="required"
            >
              <va-input-wrapper
                :error="valid === false"
                :error-messages="errors"
              >
                <va-simple-select
                  v-model="model.status"
                  :label="$t('f.status')"
                  :clearable="false"
                  :options="['0$requested', '1$accepted', '99$rejected']"
                  :formatter="statusFilter"
                />
              </va-input-wrapper>
            </ValidationProvider>
          </div>
        </div>
        <div class="va-row">
          <div class="flex md6">
            <va-input
              v-model="model.remarks"
              :label="$t('f.remarks')"
              type="textarea"
            />
          </div>
        </div>
        <p class="text-category">{{ $t('f.categories.seo') }}</p>
        <div class="va-row">
          <div class="flex md6">
            <va-input
              v-model="model.title"
              :label="$t('f.seoTitle')"
            />
            <va-input
              v-model="model.description"
              type="textarea"
              :label="$t('f.seoDescription')"
            />
            <va-multi-select
              v-model="model.tags"
              :label="$t('f.tags')"
              :options="['food', 'beauty', 'gym', 'video', 'travel']"
            />
          </div>
          <div class="flex md6" v-if="!isCreate">
            <p class="display-5">{{ $t('f.seoImage') }}</p>
            <p>
              <va-file-upload
                type="single"
                :value="attnConvert([model.image])"
                @upload="uploadImage"
                @remove="removeImage"
              />
            </p>
            <img
              v-if="model.image"
              style="max-width: 256px;"
              :src="attnThumbHref(model.image, 256)"
            />
          </div>
        </div>
      </div>
    </ValidationObserver>
  </va-modal>
</template>

<script>
import Objectid from 'components/objectid';
import editDialogMixin from 'src/services/editDialogMixin';
import { create } from 'src/services/schema/SourceLink';
import { status } from 'src/services/filters';

export default {
  name: 'SourceLinkEditDialog',
  components: { Objectid },
  mixins: [editDialogMixin],
  data () {
    return {
      crudURL: 'll/sourcelink/{id}',
    };
  },
  computed: {
    title () {
      return this.$t('schema.ll_sourcelink');
    },
  },
  watch: {
    id () {
      if (this.value) {
        this.reload();
      }
    },
  },
  methods: {
    async reload () {
      this.model = create(this.id ? (await this.$http.get(this.crudURL,
        { params: { id: this.id, populate: 'image' } })).data : {});
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },
    async promptCreate () {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        const model = (await this.$http.post(this.crudURL, this.model,
          { params: { id: null } })).data;
        this.showToast(this.$t('p.created'));
        this.$emit('create', model);
        return model;
      } catch (err) {
        this.handleError(err);
      }
    },
    async uploadImage (files) {
      if (!files[0]) return;
      await this.attnDelete(this.model.image);
      const attn = await this.attnUpload(files[0]);
      this.$set(this.model, 'image', attn);
      return this.doUpdate({ image: attn._id }, this.id);
    },
    async removeImage () {
      await this.attnDelete(this.model.image);
      this.$set(this.model, 'image', null);
      return this.doUpdate({ image: null }, this.id);
    },
    statusFilter: status,
    async doCreateSharedLink () {
      if (this.id) {
        const data = (await this.$http.post('ll/sharedlink', {
          sourcelink: this.id,
        })).data;
        this.doClose();
        this.$router.push({ name: 'll_sharedlink', params: { id: data._id } });
      }
    },
  },
};
</script>
