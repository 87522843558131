<template>
  <div class="tables">
    <div class="va-row">
      <div class="flex xs12">
        <va-card :title="$t('schema.ll_sharedlinks')">
          <va-data-table
            ref="table"
            class="row-clickable"
            apiUrl="ll/sharedlink?populate=sourcelink"
            :httpFetch="tableAdapter.httpFetch"
            :transform="tableAdapter.transform"
            :tableFields="tableFields"
            :itemsPerPage="itemsPerPage"
            apiMode
            :appendParams="appendParamsComputed"
            :queryParams="tableAdapter.queryParams"
            :filterQuery="tableAdapter.filterQuery"
            :sortOrder="sortOrder"
            :vuetableOptions="tableAdapter.vuetableOptions"
            @vuetable:row-clicked="onRowClicked"
            @vuetable:field-event="onFieldEvent"
          >
            <template slot="additionalTableControl">
              <va-toggle
                :label="$t('d.showDisabled')"
                v-model="appendFilter.enabled"
                :trueValue="ne"
                :falseValue="true"
                small
                style="margin-bottom: 0"
              />
              <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
              <slot name="additionalTableControl"></slot>
            </template>
            <template slot="href" slot-scope="{rowData: x}">
              <a href="#"
                 :title="$t('p.clickTo$action', {action: $t('a.copy')})"
                 @click.stop="copyText(x.href)"
              >{{x.href}}</a>
            </template>
            <template slot="detailRowComponent" slot-scope="scope">
              <DetailRow
                v-bind="scope"
                @update="reload"
                @close="hideDetailRow"
                @delete="reload"
              />
            </template>
          </va-data-table>
        </va-card>
      </div>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="editId"
      @create="x => editId = x._id"
      @update="reload"
      @delete="reload"
    />
  </div>
</template>

<script>
  import EditDialog from './SharedLinkEditDialog';
  import DetailRow from './SharedLinkDetailRow';
  import ActionsColumn, { actions } from 'src/components/tables/ActionsColumn';
  import vuetableMixin from 'src/services/vuetableMixin';
  import { merge, cloneDeep } from 'lodash';
  import { sid, date, currency } from 'src/services/tableColumnTemplates';

  export default {
    name: 'SharedLinks',
    mixins: [vuetableMixin],
    components: {
      EditDialog,
      DetailRow,
    },
    data () {
      return {
        crudURL: 'll/sharedlink/{id}',
        ne: { $ne: true },
        appendFilter: {
          enabled: true,
        },
      };
    },
    computed: {
      appendParamsComputed () {
        return merge({
          filter: cloneDeep(this.appendFilter),
        }, this.appendParams);
      },
      tableFields () {
        return [
          sid(),
          {
            name: 'href',
            title: this.$t('schema.ll_sharedlink'),
            sortField: 'sid',
          },
          {
            name: 'sourcelink.href',
            title: this.$t('f.targetLink'),
            dataClass: 'ellipsis',
          },
          {
            name: 'sourcelink.title',
            title: this.$t('f.seoTitle'),
            dataClass: 'ellipsis',
          },
          {
            name: 'sourcelink.description',
            title: this.$t('f.seoDescription'),
            dataClass: 'ellipsis',
          },
          {
            name: '_stats.status.success',
            title: this.$t('f.clicks'),
            sortField: '_stats.status.success',
            dataClass: 'text-right',
            titleClass: 'text-right',
          },
          {
            name: '_stats.distinct',
            title: this.$t('f.distinctSessions'),
            sortField: '_stats.distinct',
            dataClass: 'text-right',
            titleClass: 'text-right',
          },
          {
            name: 'coinsEarned',
            title: this.$t('f.coinsEarned'),
            sortField: 'coinsEarned',
            ...currency('', 2),
          },
          {
            name: 'created_at',
            title: this.$t('f.created_at'),
            sortField: 'created_at',
            ...date(true),
          },
          {
            name: ActionsColumn,
            actions ({ data }) {
              return [
                actions.view.call(this),
                actions.edit.call(this),
                data.enabled
                  ? actions.disable.call(this)
                  : actions.enable.call(this),
                !data.enabled ? actions.delete.call(this) : null,
              ].filter(x => x);
            },
          },
        ];
      },
    },
    methods: {
      onFieldEvent (type, payload) {
        if (type === 'action') {
          const { action, data } = payload;
          switch (action.key) {
            case 'delete':
              this.promptDelete(data._id);
              break;
            case 'edit':
              this.doEdit(data._id);
              break;
            case 'view':
              this.$router.push({ name: 'll_sharedlink', params: { id: data._id } });
              break;
            case 'disable':
              if (!confirm(this.$t('p.promptDisable'))) {
                break;
              }
              this.promptUpdate({ enabled: false }, data._id);
              break;
            case 'enable':
              if (!confirm(this.$t('p.promptEnable'))) {
                break;
              }
              this.promptUpdate({ enabled: true }, data._id);
              break;
          }
        }
      },
      async copyText (t) {
        await this.$copyText(t);
        this.showToast(this.$t('p.copied'));
      },
    },
  };
</script>
