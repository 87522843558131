<template>
  <div class="tables">
    <div class="va-row">
      <div class="flex xs12">
        <va-card :title="$t('schema.ec_transactions')">
          <va-data-table
            ref="table"
            class="row-clickable"
            apiUrl="ec/transaction"
            :httpFetch="tableAdapter.httpFetch"
            :transform="tableAdapter.transform"
            :tableFields="tableFields"
            :itemsPerPage="itemsPerPage"
            apiMode
            :appendParams="appendParamsComputed"
            :queryParams="tableAdapter.queryParams"
            :filterQuery="tableAdapter.filterQuery"
            :sortOrder="sortOrder"
            :vuetableOptions="tableAdapter.vuetableOptions"
            @vuetable:row-clicked="onRowClicked"
            @vuetable:field-event="onFieldEvent"
          >
            <template slot="additionalTableControl">
              <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
            </template>
            <!--            <template slot="detailRowComponent" slot-scope="scope">-->
            <!--              <DetailRow-->
            <!--                v-bind="scope"-->
            <!--                @update="reload"-->
            <!--                @close="hideDetailRow"-->
            <!--                @delete="reload"-->
            <!--              />-->
            <!--            </template>-->
          </va-data-table>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { jsonDecode } from 'services/utils';
  // import ActionsColumn, { actions } from 'src/components/tables/ActionsColumn';
  import vuetableMixin from 'src/services/vuetableMixin';
  import { merge, cloneDeep } from 'lodash';
  import { sid, datetime, status, amount, tags } from 'src/services/tableColumnTemplates';

  export default {
    name: 'Transactions',
    mixins: [vuetableMixin],
    components: {},
    data () {
      return {
        crudURL: 'ec/transaction/{id}',
        ne: { $ne: true },
        appendFilter: {},
      };
    },
    computed: {
      appendParamsComputed () {
        return merge(
          { filter: cloneDeep(this.appendFilter) },
          { filter: jsonDecode(this.$route.query.filter) },
          this.appendParams,
        );
      },
      tableFields () {
        return [
          sid(),
          // {
          //   name: 'ref',
          //   title: this.$t('f.ref'),
          //   sortField: 'ref',
          // },
          {
            name: 'tags',
            title: this.$t('f.tags'),
            sortField: 'tags',
            dataClass: 'ellipsis',
            ...tags(', ', x => this.$t('ec_transaction.tags.' + x, x)),
          },
          {
            name: 'amount',
            title: this.$t('f.coinsAmount'),
            sortField: 'amount.value',
            ...amount('', 2, true),
          },
          status(),
          {
            name: 'remarks',
            title: this.$t('f.remarks'),
            sortField: 'remarks',
          },
          {
            name: 'created_at',
            title: this.$t('f.created_at'),
            sortField: 'created_at',
            ...datetime(),
          },
          // {
          //   name: ActionsColumn,
          //   actions ({ data }) {
          //     return [
          //       actions.view.call(this),
          //       actions.delete.call(this),
          //     ].filter(x => x);
          //   },
          // },
        ];
      },
    },
    methods: {
      onFieldEvent (type, payload) {
        if (type === 'action') {
          const { action, data } = payload;
          switch (action.key) {
            case 'delete':
              this.promptDelete(data._id);
              break;
            case 'view':
              this.$router.push({ name: 'ec_transaction', params: { id: data._id } });
              break;
          }
        }
      },
    },
  };
</script>
