<template>
  <div>
    <div class="va-row flex">
      <va-card :title="$t('schema.ll_cashoutApplication')">
        <template slot="actions">
          <div class="d-flex justify-content-center">
            <va-button color="gray" flat @click="reload">{{$t('a.refresh')}}</va-button>
            <va-button color="primary" @click="doEdit()">{{$t('a.edit')}}</va-button>
            <va-button
              v-if="model && model.status === '0$pending'"
              color="success" @click="promptApprove()"
            >
              {{$t('a.approve')}}
            </va-button>
            <va-button
              v-if="model && model.status === '0$pending'"
              color="danger" @click="promptReject()"
            >
              {{$t('a.reject')}}
            </va-button>
          </div>
        </template>
        <div class="va-layout fluid gutter--md">
          <div class="va-row" v-if="model">
            <div class="flex xs12 md6">
              <p class="display-5">{{$t('f.created_by')}}</p>
              <p>{{get(model, 'created_by.name')}}</p>
              <p class="display-5">{{$t('f.coinsAmount')}}</p>
              <p>{{model.amount | currency('', 2)}}</p>
              <p class="display-5">{{$t('f.adminRemarks')}}</p>
              <p v-if="model.remarks" class="code-snippet">{{model.remarks}}</p>
              <p v-else>-</p>
            </div>
            <div class="flex xs12 md6" v-if="model.paymentProfile">
              <p class="display-5">{{$t('schema.ec_paymentProfile')}}</p>
              <template v-if="model.gateway === 'paypal.me'">
                <div class="flex xs12 md6">
                  <p class="display-5">{{$t('f.paypalmeId')}}</p>
                  <p>
                    <a
                      :href="`https://www.paypal.me/${parsePaypalmeId(model.paymentProfile.paypalmeId)}`"
                      target="_blank">
                      paypal.me/{{parsePaypalmeId(model.paymentProfile.paypalmeId)}}
                    </a>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </va-card>
    </div>
    <EditDialog
      v-model="editDialog"
      :id="id"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </div>
</template>

<script>
  import { parsePaypalmeId } from 'services/utils';
  import EditDialog from './CashoutApplicationEditDialog';
  import crudMixin from 'src/services/crudMixin';
  import { get } from 'lodash';

  export default {
    name: 'CashoutApplication',
    mixins: [crudMixin],
    components: {
      EditDialog,
    },
    props: {
      id: { type: String },
    },
    data () {
      return {
        crudURL: 'll/cashoutApplication/{id}',
        model: null,
        editDialog: false,
      };
    },
    computed: {
      get: () => get,
    },
    mounted () {
      this.reload();
    },
    methods: {
      async reload () {
        this.model = (await this.$http.get(this.crudURL, {
          params: {
            id: this.id,
            populate: JSON.stringify(['created_by', 'paymentProfile']),
          },
        })).data;
      },
      doEdit () {
        this.editDialog = true;
      },
      async promptDelete () {
        if (await crudMixin.methods.promptDelete.call(this, ...arguments)) {
          this.$router.replace({ name: 'll_cashoutApplications' });
        }
      },
      async promptApprove () {
        if (!confirm(this.$t('p.promptApproveCashoutApplication'))) {
          return;
        }
        try {
          this.model = (await this.$http.patch(this.crudURL + '/approve', this.model,
            { params: { id: this.id } })).data;
          this.showToast(this.$t('p.updated'));
        } catch (err) {
          this.handleError(err);
        }
      },
      async promptReject () {
        if (!confirm(this.$t('p.promptRejectCashoutApplication'))) {
          return;
        }
        try {
          this.model = (await this.$http.patch(this.crudURL + '/reject', this.model,
            { params: { id: this.id } })).data;
          this.showToast(this.$t('p.updated'));
        } catch (err) {
          this.handleError(err);
        }
      },
      parsePaypalmeId,
    },
  };
</script>
