<template>
  <ValidationObserver ref="form" tag="form" class="login" @submit.prevent="doSubmit">
    <template v-if="!useSms">
      <ValidationProvider
        vid="usernameOrEmailOrPhone"
        v-if="!ambiguous"
        v-slot="{valid, errors}" :name="$t('auth.usernameOrEmailOrPhone')" mode="lazy"
        rules="required"
      >
        <va-input
          v-model="username"
          :label="$t('auth.usernameOrEmailOrPhone')"
          :error="valid === false"
          :error-messages="errors"
          autofocus
        />
      </ValidationProvider>
      <va-simple-select
        v-if="ambiguous"
        v-model="field"
        :label="$t('f.field')"
        :clearable="false"
        :options="['username', 'email', 'phone']"
        :formatter="x => $t('f.' + x)"
      />
      <ValidationProvider
        vid="field"
        v-if="ambiguous"
        v-slot="{valid, errors}" :name="$t('f.field')" mode="lazy"
        rules="required"
      >
        <va-input
          class="mb-2"
          v-model="username"
          :label="$t('f.' + field)"
          :error="valid === false"
          :error-messages="errors"
        />
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        v-slot="{valid, errors}" :name="$t('auth.password')" mode="lazy"
        rules="required"
      >
        <va-input
          v-model="pw"
          name="password"
          type="password"
          :label="$t('auth.password')"
          :error="valid === false"
          :error-messages="errors"
        />
      </ValidationProvider>
    </template>
    <template v-else>
      <ValidationProvider
        vid="field"
        v-slot="{valid, errors}" :name="$t('f.phone')" mode="lazy"
        rules="required|regex:^\s*\+?[0-9\-\s()]+$"
      >
        <va-input
          v-model="phone"
          :label="$t('f.phone')"
          :error="valid === false"
          :error-messages="errors"
        >
          <template slot="appendText">
            <va-button
              v-if="canSendSms"
              type="button"
              :disabled="!isPhoneValid"
              @click="promptRequestSMSCode()"
              small
            >
              {{$t('a.getVerifyCode')}}
            </va-button>
            <va-button v-else disabled small>
              {{$t('d.resendSms', {time: sms.waitUntil.diff(now, 'seconds')})}}
            </va-button>
          </template>
        </va-input>
      </ValidationProvider>
      <ValidationProvider
        vid="verifyCode"
        v-slot="{valid, errors}" :name="$t('f.verifyCode')" mode="lazy"
        rules="required"
      >
        <va-input
          v-model="sms.code"
          :label="$t('f.verifyCode')"
          :error="valid === false"
          :error-messages="errors"
        >
          <template slot="prependText" v-if="sms.nonce">
            <span style="padding: .25em 0 .1em .5em;">({{sms.nonce}})</span>
          </template>
        </va-input>
      </ValidationProvider>
    </template>
    <div class="d-flex align--center justify--space-between flex-wrap mb-4">
      <va-checkbox v-model="keepLoggedIn" class="mr-2" :label="$t('auth.keep_logged_in')"/>
      <div class="spacer"></div>
      <div class="d-flex flex-grow-1 justify--space-between my-1">
        <a v-if="useSms"
           href="javascript:"
           @click="useSms = false"
        >{{$t('auth.loginWithPassword')}}</a>
        <a v-else
           href="javascript:"
           @click="useSms = true"
        >{{$t('auth.loginWithSMS')}}</a>
        <a class="ml-2" :href="forgotPasswordHref"
           target="_blank">{{$t('auth.recover_password')}}</a>
      </div>
    </div>
    <div class="flex-center">
      <va-button type="submit" class="my-0">{{ $t('auth.login') }}</va-button>
    </div>
    <div class="flex-center mt-3" v-if="err">
      <div class="text-danger">{{err}}</div>
    </div>
  </ValidationObserver>
</template>

<script>
  import ApiError from 'services/ApiError';
  import VaSimpleSelect from 'vuestic-components/va-simple-select/VaSimpleSelect';
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import { get } from 'lodash';

  export default {
    name: 'login',
    components: { VaSimpleSelect },
    data () {
      return {
        err: null,
        now: moment(),
        tick: null,
        keepLoggedIn: false,
        field: 'username',
        username: '',
        pw: '',
        ambiguous: false,
        phone: '',
        useSms: false,
        sms: {
          code: '',
          nonce: null,
          token_template: null,
          waitUntil: null,
        },
      };
    },
    mounted () {
      this.tick = setInterval(() => {
        this.now = moment();
      }, 1000);
    },
    beforeDestroy () {
      clearInterval(this.tick);
    },
    computed: {
      ...mapGetters([
        'api',
        'user',
      ]),
      forgotPasswordHref () {
        return this.api.host + this.api.sso + '/forgotPassword';
      },
      canSendSms () {
        return !this.sms.waitUntil || this.now >= this.sms.waitUntil;
      },
      isPhoneValid () {
        return /^\s*\+?[0-9\-\s()]+$/.test(this.phone);
      },
    },
    watch: {
      useSms () {
        this.$refs.form.reset();
        this.err = null;
      },
    },
    methods: {
      ...mapActions([
        'checkLoginStatus',
      ]),
      async doSubmit () {
        try {
          this.err = null;
          if (!await this.$refs.form.validate()) {
            return;
          }
          const payload = {};
          if (this.useSms) {
            payload['access-token'] = this.sms.token_template.replace('{code}', this.sms.code);
          } else {
            if (this.ambiguous) {
              payload[this.field] = this.username;
            } else {
              payload.auto = this.username;
            }
            payload.pw = this.pw;
          }
          if (this.keepLoggedIn) {
            payload.remember = true;
          }
          await this.$http.post('sso/login', payload);
          const result = await this.checkLoginStatus();
          if (!result || !result.user) {
            throw new Error('Cannot retrieve user info');
          }
          const language = get(this.user, 'preference.language');
          if (language) {
            this.$i18n.set(language);
          }
          this.$router.push('/');
        } catch (err) {
          if (err === 'allowedRoles') {
            this.$redirectSwitchProfile(window.location.origin);
            return;
          }
          const ae = ApiError.wrap(err);
          if (ae.code === 'ambiguous_operation') {
            this.ambiguous = true;
          } else if (ae.code === 'invalid_credentials' && ae.pw_updated) {
            this.err = this.$t('d.warnPasswordChangedAt',
              { time: moment(ae.pw_updated).fromNow() });
            return;
          } else {
            console.debug(err);
          }
          this.err = ae.message;
        }
      },
      async promptRequestSMSCode () {
        try {
          this.err = null;
          if (this.phone[0] !== '+') {
            this.phone = '+852' + this.phone;
          }
          // refresh ui
          await new Promise(resolve => setTimeout(resolve));
          if (!confirm(this.$t('p.confirmSendSms', { phone: this.phone }))) {
            return;
          }
          this.sms.code = null;
          const res = (await this.$http.post('requestSmsCode', { phone: this.phone })).data;
          this.phone = res.phone;
          this.sms.nonce = res.nonce;
          this.sms.token_template = res.accessToken.token_template;
          this.sms.waitUntil = moment(res.waitUntil);
        } catch (err) {
          console.debug(err);
          const ae = ApiError.wrap(err);
          this.err = ae.message;
        }
      },
    },
  };
</script>

<style lang="scss">
  .login {

  }
</style>
