<template>
  <th v-if="isHeader">{{title || $t('f.actions')}}</th>
  <td v-else>
    <template v-if="actions">
      <va-button-group>
        <va-button
          v-for="a in actions"
          :key="a.key"
          v-bind="a"
          @click.stop="onAction(a)"
          :title="a.title"
          :disabled="a.disabled"
        >
          <template v-if="a.label">{{a.label}}</template>
        </va-button>
      </va-button-group>
    </template>
  </td>
</template>

<script>
  import VaButtonGroup from 'src/vuestic-theme/vuestic-components/va-button-group/VaButtonGroup';
  import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin';

  export default {
    name: 'ActionsColumn',
    components: { VaButtonGroup },
    mixins: [VuetableFieldMixin],
    computed: {
      actions () {
        if (typeof this.rowField.actions === 'function') {
          return this.rowField.actions.call(this,
            { data: this.rowData, index: this.rowIndex, field: this.rowField });
        } else if (Array.isArray(this.rowField.actions)) {
          return this.rowField.actions;
        }
      },
    },
    methods: {
      onAction (action) {
        this.$emit('vuetable:field-event', 'action',
          { data: this.rowData, index: this.rowIndex, field: this.rowField, action });
      },
    },
  };

  export const actions = {
    view () {
      return {
        key: 'view',
        icon: 'ion ion-ios-open-outline',
        color: 'info',
        title: this.$t('a.view'),
      };
    },
    edit () {
      return {
        key: 'edit',
        icon: 'ion ion-md-create',
        title: this.$t('a.edit'),
      };
    },
    delete () {
      return {
        key: 'delete',
        icon: 'ion ion-ios-trash-outline',
        color: 'danger',
        title: this.$t('a.delete'),
      };
    },
    enable () {
      return {
        key: 'enable',
        icon: 'ion ion-ios-checkmark-circle-outline',
        title: this.$t('a.enable'),
      };
    },
    disable () {
      return {
        key: 'disable',
        icon: 'ion ion-ios-close-circle-outline',
        color: 'danger',
        title: this.$t('a.disable'),
      };
    },
  };
</script>
